import React, { useState } from "react";
import { Label } from '@nokia-csf-uxr/ccfk'
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import _ from "lodash";




import CartAccountDetails from "./cart/cartsummary/CartAccountDetails";
import CartSummaryItemsTable from "./cart/cartsummary/CartSummaryItemsTable";
import OrderCreation from "./cart/cartsummary/OrderCreation";

const CartSummary = () => {


  return (
    <>
      <div style={{ padding: '0.5rem' }}>
        <CartAccountDetails />
      </div>

      <div style={{ padding: '0.5rem' }}>
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '14px', padding: '.5rem', borderBottom: '1px solid #D7D7D7' }}>Items</div>
        </div>

        <div style={{ marginTop: '10px' }}>
          <CartSummaryItemsTable />
        </div>

        <div style={{ marginTop: '10px' }}>
          <OrderCreation />
        </div>



      </div>
    </>

  )
}

export default CartSummary;