import React, { useRef, useState, useEffect } from 'react';
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import Button from '@nokia-csf-uxr/ccfk/Button';
import editIcon from '../../../Assets/Icons/pencil_icon.svg';
import trashIcon from '../../../Assets/Icons/trash_icon.svg';
import OOT from 'Constants/services/oot';
import { setUsers, setUsersLoading } from 'Store/contentState/userManagementSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "Store/mainStore";
import Chip, {
  ChipLabel,
} from '@nokia-csf-uxr/ccfk/Chip';
import Loader from 'Components/Elements/Loader';
import DeleteUser from './DeleteUser';
import Pagination from 'Pages/marketplace/common/Pagination';
import { setOffset, resetPaginationsProps } from 'Store/contentState/paginationSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import UserDialogModal from './UserDialogModal';
import { useUserActions } from './hooks/useUserActions';

const UsersList = () => {
  const gridApi = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const userManagementSelector = useSelector((state: RootState) => state.userManagement);
  const paginationSelector = useSelector((state: RootState) => state.pagination);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const { currentPage, limitPerpage } = paginationSelector.paginationProps;

  //const getAllUsersList = useUserActions();


  useEffect(() => {
    const offset = (currentPage - 1) * limitPerpage;
    dispatch(setOffset(offset));
    getUsersList(limitPerpage, offset);
    //getAllUsersList();

  }, [currentPage, limitPerpage, dispatch, location.pathname]);


  const getUsersList = async (limitPerpage, offset) => {
    dispatch(setUsersLoading(true));
    try {
      const response = await OOT.getAllUsers("users.active = 1", limitPerpage, offset);
      if (response.status) {
        const updatedUserList = response.data.map((user) => {
          return {
            id: user.id,
            name: user.name ? user.name : '',
            email: user.email ? user.email : '',
            status: user.active === 1 ? 'Active' : 'Inactive',
            userType: user.role.persona,
            viewAccess: user.role.view,
            userRoles: user.role.roles,
            dataElement: user.role['data-element'],
            oneStarNum: user.role.one_star,
            threeStarNum: user.role.three_star

          }
        })
        dispatch(setUsers(updatedUserList));
        dispatch(setUsersLoading(false));
      } else {
        dispatch(setUsersLoading(false));
        console.log("No users available")
      }
    } catch (error) {
      dispatch(setUsersLoading(false));
      console.error(error)
    }
  }

  if (userManagementSelector.isUsersLoading) {
    return <Loader />
  }

  const handleShowDeleteModal = (user) => {
    setShowDeleteUserDialog(true);
    setSelectedUser(user);
  }

  const handleEditUser = (user) => {
    setShowEditUserDialog(true);
    setSelectedUser(user);
  }

  return (
    <>
      {showDeleteUserDialog && (
        <DeleteUser onClose={() => setShowDeleteUserDialog(false)} userId={selectedUser['id']} />
      )}

      {showEditUserDialog && (
        <UserDialogModal onClose={() => setShowEditUserDialog(false)} selectedUserData={selectedUser} mode="edit"/>
      )}

      <div id='quotations' style={{ width: '100%', height: '100%', marginTop: '10px' }}>
        <DataGrid
          // variant={"compact"}
          suppressMovableColumns={false}
          suppressColumnMoveAnimation={false}
          suppressRowClickSelection={false}
          isRowDrag={false}
          wrapperProps={{
            style: {
              width: "100%"
              // width: 600,
            }
          }}
          sideBar
          disableAutoloadStyles
          suppressContextMenu
          onGridReady={(params) => {
            gridApi.current = params.api;
          }}
          rowData={userManagementSelector.users}
          columnDefs={[{ headerName: 'Name', field: 'name', cellStyle: { 'borderLeft': '3px solid', 'borderLeftColor': 'var(--g-color-app-banner-background)' } },

          { headerName: 'Email', field: 'email', minWidth: 240, flex: 2 },
          {
            headerName: 'Status',
            field: 'status',
            minWidth: 115,
            //flex: 1,

          },
          {
            headerName: 'User Type',
            field: 'userType',
            // minWidth: 115,
            //flex: 1,

          },
          {
            headerName: 'View',
            field: 'viewAccess',
            cellRenderer: (params) => {
              const { value } = params;
              if (Array.isArray(value)) {
                return (
                  <div>
                    {value.map((item, index) => (
                      <Chip key={index} size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}><ChipLabel label={item} /></Chip>
                    ))}
                  </div>
                );
              } else {
                return null; // Or any other fallback
              }
            }
          },

          {
            minWidth: 30,
            // flex: 0.3,
            hide: !true,
            cellRenderer: (params) =>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={editIcon} onClick={() => handleEditUser(params.data)} />
                <img src={trashIcon} onClick={() => handleShowDeleteModal(params.data)} />
              </div>,
          },
          ]}
          key={'users'}
          defaultColDef={{
            editable: false,
            sortable: true,
            flex: 0,
            minWidth: 30,
            filter: true,
            resizable: false,
          }}
          overlayNoRowsTemplate='No users are available'
          isInlineRowActions={false}
          domLayout='autoHeight'
          onFirstDataRendered={(params) => {
            params.columnApi.autoSizeAllColumns();
            // onFirstDataRendered(params);
          }}
        />
      </div>

      <Pagination disableProp={userManagementSelector.users} />

      {/* <div><QuotationResponse/></div> */}
    </>
  )
}

export default UsersList;