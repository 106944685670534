import React, { useRef, memo, useMemo, useCallback } from "react";
import { Button, DataGrid, Typography } from "@nokia-csf-uxr/ccfk";
import Dialog, {
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";

import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import { getDataGridRowStatusColour, getUniqueKeyId } from "../utility/utils";
import classes from "../Style/SubscriptionDataGrid.module.css";
import { subscriptionGridColums } from "../utility/Contants";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import ExcelExport from "Components/Elements/ExcelExport/ExcelExport";

const DIALOG_HEIGHT = "80%";
const DIALOG_WIDTH = "80%";

const CONFIRM_ALERT_STYLE = {
  top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
  height: DIALOG_HEIGHT,
  minHeight: DIALOG_HEIGHT,
  left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  width: DIALOG_WIDTH,
};

type ICancellationHistoryProp = {
  onClose: () => void;
};
const CancellationHistory = ({ onClose }: ICancellationHistoryProp) => {
  const subscriptionData = useSelector(
    (state: RootState) => state.subscription?.subscriptionData?.data
  );
  const networkStatus = useSelector(
    (state: RootState) => state.subscription?.subInfo?.subscriptionInfo?.status
  );
  const subscriptionFilterSelection = useSelector(
    (state: RootState) => state.subscription?.filters?.selection
  );

  const gridApi = useRef(null);

  const dataGridColumnDefs = useMemo(() => {
    const dgColumns = [...subscriptionGridColums];
    dgColumns.unshift({
      headerName: "Subscription Id",
      field: "productId",
      filterParams: {
        buttons: ["reset"],
      },
      resizable: true,
      pinned: "left",
      //@ts-ignore
      cellStyle: (params) => ({
        borderLeft: "3px solid",
        borderLeftColor: getDataGridRowStatusColour(
          params.data?.subscription_status
        ),
      }),
      minWidth: 140,
    });

    // Handle status field
    const contractIdIndex = dgColumns.findIndex(
      (el) => el?.colId === "contract_id"
    );
    dgColumns.splice(contractIdIndex + 1, 0, {
      headerName: "Status",
      field: "subscription_status",
      filterParams: {
        buttons: ["reset"],
      },
      //@ts-ignore
      width: 120,
      resizable: true,
      cellRenderer: (params) => {
        const fontColor = getDataGridRowStatusColour(
          params.data?.subscription_status
        );
        const statusStyle =
          fontColor !== ""
            ? {
                color: fontColor,
              }
            : {};

        return (
          <div
            className={`${classes.subscription_status}`}
            style={{ ...statusStyle }}
          >
            {params.data?.subscription_status}
          </div>
        );
      },
    });

    // Add  ancelled Qty after RenwQty
    const RenwQtyIndex = dgColumns.findIndex((el) => el?.colId === "RenwQty");
    dgColumns.splice(RenwQtyIndex + 1, 0, {
      headerName: "Cancelled Quantity",
      field: "cancelled_qty",
      filterParams: {
        buttons: ["reset"],
      },
      resizable: true,
      //@ts-ignore
      cellRenderer: (params) => {
        let cancelledQty = params.data?.cancelled_qty;
        if (
          params.data?.cancelled_qty === 0 &&
          networkStatus.toLowerCase() !== "active"
        ) {
          cancelledQty = "-";
        }
        return cancelledQty;
      },
      /* cellRendererFramework: (params) => {
        return (
          <Tooltip
            tooltip={
              <div className={classes.custom_tooltip}>
                <div>
                  <Typography>Cancel Reason:</Typography>
                  <ul>
                    {params.data?.cancel_reason
                      .split(",")
                      .map((reason, index) => (
                        <li key={`${reason}_${index}`}>
                          {reason}
                        </li>
                      ))}
                  </ul>
                </div>
                {params.data?.other_reason.trim().length > 0 && (
                  <div>
                    <Typography>Other Reason:</Typography>
                    <ul>
                      <li>
                        {params.data?.other_reason}
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Incidunt, deleniti obcaecati? Aut labore sint quidem. Hic, alias, temporibus nostrum perferendis reiciendis repellat illum earum provident dolor quasi odit ea quam fuga itaque culpa ab suscipit, vero similique. Quisquam magni, perferendis facere ex ipsum atque eveniet? Quibusdam temporibus sequi a excepturi id voluptates reprehenderit, cumque magni distinctio nihil ea illum facere quis quos placeat esse molestias deleniti aliquid inventore et totam quam ad. Laborum adipisci, praesentium tempore saepe, odit et ex dicta iste animi fugiat ad placeat corporis numquam non nemo ipsam illum mollitia ducimus consequatur dolorem culpa quidem, dolor voluptates
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            }
            placement="bottom"
          >
            <div style={{ cursor: "pointer", color: "var(--g-color-global-primary-hyperlink)" }}>
              {params.data?.cancelled_qty}              
            </div>
          </Tooltip>
        );
      }, */
    });

    return dgColumns;
  }, [networkStatus]);

  const onFirstDataRendered = useCallback((params) => {
    params.columnApi.autoSizeAllColumns();
  }, []);

  const getExpiredSubscription = (subscriptionData) => {
    const cancelledSubscription = subscriptionData.filter(
      (row) => row?.subscription_status?.toLowerCase().trim().startsWith("ended")
    );
    return cancelledSubscription;
  };

  const renderDataGrid = () => {
    const colDef = [...dataGridColumnDefs].map((row) => ({
      header: row.headerName,
      field: row.field,
    }));

    return subscriptionData.map((subscription) =>
      Object.keys(subscription).map((title) => (
        <div key={getUniqueKeyId("small")}>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <Typography
              style={{ height: "25px", margin: "5px 0", lineHeight: "25px" }}
            >
              {title}
            </Typography>
            {getExpiredSubscription(subscription[title]).length > 0 && (
              <ExcelExport
                label="Excel Export"
                fileName={`${subscriptionFilterSelection.partner}_${subscriptionFilterSelection.customer}_${subscriptionFilterSelection.network}_${title}`}
                columnDefs={colDef}
                exportData={getExpiredSubscription(subscription[title])}
                isConfigurable={true}
              />
            )}
          </div>

          {/* @ts-ignore */}
          <DataGrid
            suppressMovableColumns={true}
            domLayout="autoHeight"
            isRowDrag={false}
            key={`subscription-${title}`}
            wrapperProps={{
              style: {
                width: "100%",
              },
            }}
            sideBar
            rowData={getExpiredSubscription(subscription[title])}
            defaultColDef={{
              editable: false,
              sortable: true,
              flex: 0,
              minWidth: 100,
              filter: true,
              resizable: true,
            }}
            overlayNoRowsTemplate="History data is not available for the current selection"
            //@ts-ignore
            columnDefs={dataGridColumnDefs}
            onGridReady={(params) => {
              gridApi.current = params.api;
            }}
            onFirstDataRendered={(params) => {
              onFirstDataRendered(params);
            }}
          />
        </div>
      ))
    );
  };

  return (
    <>
      {/* Cancellation In Progress */}
      <Dialog
        isOpen={true}
        ariaHideApp={false}
        style={{ content: CONFIRM_ALERT_STYLE }}
      >
        <DialogTitle
          title={"Subscription History"}
          buttonContent={<CloseCloseIcon />}
          buttonProps={{
            onClick: onClose,
          }}
        />
        <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true}>
          {renderDataGrid()}
        </DialogContent>
        <DialogFooter>
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </Dialog>
      {/* Cancellation In Progress */}
    </>
  );
};

export default memo(CancellationHistory);
