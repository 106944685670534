import { createSlice } from "@reduxjs/toolkit";
import { subscriptionPlansOptions } from "Components/Content/configurationArea/Warranty/system";
import { annualCapacity,  mandatoryApp, segmentSolutions } from "Components/Logics/stateMachine/modules/PTDAC/system";
import { commericalModel } from 'Components/Content/configurationArea/Business/system'

/*
EXCEPTION: Currently the Configurator STATE are beign configured directly from API
-----------------------------------------------------------------------------------------
This FrameWork was created to co-op with backend, hence state machine  = partial fronted+ partial backend;

@refs: will contain references to rules/helpers in calculating actions & setting rules. can be used in Fronted for getting values.
@rules: will be **Business rules** name, which are set using @refs, These state  are also set from API'S | (partial fronted+ partial backend)
@selected: Contains the Real Time Calulations / Functions -  StateMachine of PTDAC/HOME etc.
*/
const stateMachineSlice = createSlice({
    name: 'stateMachine',
    initialState: {
        'HOME': {/* TODO: To be Migrated */ },
        'PTDAC': {
            activeTab:'business',
            refs: {
                "mandatory_app_set": mandatoryApp,
                "segment_solutions_set": segmentSolutions,
                "commerical_model_set": commericalModel,
                "annual_capacity_set": annualCapacity,
                subscriptionPlansOptions: subscriptionPlansOptions,
            },
            rules: {
                "business": {
                    "setCapacity": {
                        'S': {
                            range: { min: 0, max: 0.75 }
                        },
                        'M': {
                            range: { min: 0.75, max: 2.00 }
                        },
                        'L': {
                            range: { min: 2, max: 4 }
                        },
                        'XL': {
                            range: {
                                min: 4, max: 9999
                            }
                        }
                    },
                },
                "applications": {
                    "setMandatoryApp": {
                        'a1': {
                            'a11': {
                                "mandatory_app": ["NIDM-IOT"]
                            },
                            'a12': {
                                "mandatory_app": ["NIDM-EMM"]
                            },
                            'a13': {
                                "mandatory_app": ["NIDM-IOT"]
                            }
                        },
                        'a2': {
                            'a21': {
                                "mandatory_app": ["NIDM-IOT", "NDAC-GRE"]
                            },
                            'a22': {
                                "mandatory_app": ["NIDM-IOT"]
                            },
                        }
                    },
                    "setRecommendedApplication": {

                    },
                    "setRecommendedDevices": {

                    },
                    "catalog": {}
                },
                "connectivity": {
                    catalog: {

                    }
                },
                "edge": {},
                "services": {
                    "mpw_services": {},
                    "ndac_professional_services":{}
                },
                "devices": {},
                "warranty": {}
            },
            selected: {

            }
        }
    },
    reducers: {
        /* Defines reducers for the above state which will be expecuted async */
        setPTDACLogics(state, action) {
            state.PTDAC.selected = action.payload;
        },
        setPTDACTab(state, action) {
            state.PTDAC.activeTab = action.payload;
        },
        setApplicationsTabRules(state, action) {
            state.PTDAC.rules.applications[action.payload.key] = action.payload.value;;
        },
        setConnectivityTabRules(state, action) {
            state.PTDAC.rules.connectivity = { ...state.PTDAC.rules.connectivity, ...action.payload }
        },
        setEdgeTabRules(state, action) {
            state.PTDAC.rules.edge = { ...state.PTDAC.rules.edge, ...action.payload }
        },
        setDevicesTabRules(state, action) {
            state.PTDAC.rules.devices = { ...state.PTDAC.rules.devices, ...action.payload }
        },
        setServicesTabRules(state, action) {
            state.PTDAC.rules.services = { ...state.PTDAC.rules.services, ...action.payload }
        },
        setWarrantyTabRules(state, action) {
            state.PTDAC.rules.warranty = { ...state.PTDAC.rules.warranty, ...action.payload }
        }
    },
});

export default stateMachineSlice.reducer;
export const { setApplicationsTabRules, setPTDACLogics,setPTDACTab, setConnectivityTabRules, setEdgeTabRules, setDevicesTabRules, setServicesTabRules, setWarrantyTabRules } = stateMachineSlice.actions;


