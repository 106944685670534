import React from "react";

const ExpiredQuotations = () => {
    return (
      <>
      <div>Expired Quotations</div>
      
      </>
    )
}

export default ExpiredQuotations;