import { useMsal } from "@azure/msal-react";
// import { HorizontalNavigationLabel, Tab } from "@nokia-csf-uxr/ccfk/HorizontalNavigation";
import React from "react";
// import { Button, Tabs, Typography } from '@nokia-csf-uxr/ccfk';
import { Button } from '@nokia-csf-uxr/ccfk';
// import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        <>
            
            <div id="logout">
                   
            <Button   variant="call-to-action"  onClick={() => handleLogout("redirect")} >< ><span>Logout</span></></Button>
                   
            </div>
        </>
        // <DropdownButton variant="primary" className="ml-auto" drop="left" title="Sign Out">
        //     <Dropdown.Item as="button" onClick={() => handleLogout("popup")}>Sign out using Popup</Dropdown.Item>
        //     <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>Sign out using Redirect</Dropdown.Item>
        // </DropdownButton>
    )
}