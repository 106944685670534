/* eslint-disable */
import React, { useState } from 'react';
import Tabs, { Tab, TabsContent } from "@nokia-csf-uxr/ccfk/Tabs";// Import your UI library components
import Typography from '@nokia-csf-uxr/ccfk/Typography';

const ARIA_LABEL_TEXT_1 = 'To select Tab ';
const ARIA_LABEL_TEXT_2 = ' press spacebar';

const TabPanel = ({ index, selected, children, ...otherProps }) => (
    <div hidden={index !== selected} {...otherProps}>
        {children}
    </div>
);

const TabsSection = ({ tabData, tabContentComponents }) => {
    const [selected, setSelected] = useState(0);

    const clickHandler = (selected) => () => {
        setSelected(selected);
    };

    return (
        <>
            <Tabs alignment="left" fullWidth={false} variant="default">
                {tabData.map(({ tab }, i) => {
                    const ariaMainText = tab && tab.label;
                    return (
                        <Tab
                            id={`tab-${i}`}
                            aria-label={selected === i ? `${ariaMainText}` : `${ARIA_LABEL_TEXT_1} ${ariaMainText} ${ARIA_LABEL_TEXT_2} `}
                            aria-controls={`tab-content-${i}`}
                            aria-posinset={(tab && tab.label) ? i + 1 : undefined}
                            aria-setsize={(tab && tab.label) ? tabData.length : undefined}
                            role="option"
                            key={`tab-${i}`}
                            selected={selected === i}
                            onSelect={clickHandler(i)}
                            {...tab}
                            children={tab.children}
                        />
                    );
                })}
            </Tabs>
            <TabsContent>
                {tabData.map(({ tab }, i) => (
                    <TabPanel
                        id={`tab-content-${i}`}
                        key={i}
                        index={i}
                        selected={selected}
                        role="tabpanel"
                        aria-labelledby={`tab-${i}`}
                        aria-live="polite"
                        aria-relevant="all"
                    >
                        {tabContentComponents[i]}
                    </TabPanel>
                ))}
            </TabsContent>
        </>
    );
};

export default TabsSection;
