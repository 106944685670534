import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
// import { useAuthState } from "Components/Logics/ensoCommonFunctions";
import React, { memo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";

import { RootState } from "Store/mainStore";
import SubscriptionBody from "./SubscriptionBody";
// import AppFooter from "Components/AppFooter";

const Subscription = () => {
  const authSelector = useSelector((state: RootState) => state.authSlice);
  // useAuthState();
  return (
    <>
      <LoadingOverlay
        active={authSelector.system.isLoading}
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >
        <App>
          <Header />
          <Body crumbs={["Subscription"]} bodyContent={<SubscriptionBody />} />
        </App>
        {/* <AppFooter /> */}
      </LoadingOverlay>
    </>
  );
};
export default memo(Subscription);
