export const subscriptionGridColums = [
  {
    headerName: "Subscription Name",
    field: "subscription_name",
    filterParams: {
      buttons: ["reset"],
    },
    resizable: true,
    pinned: "left",
  },
  {
    colId:"ActQty",
    headerName: "Active Quantity",
    field: "active_qty",
    filterParams: {
      buttons: ["reset"],
    },
    resizable: true,
  },  
  {
    colId:"RenwQty",
    headerName: "Renewing Quantity",
    field: "renewal_qty",
    filterParams: {
      buttons: ["reset"],
    },
    resizable: true,
  },  
  {
    headerName: "Duration (in Month)",
    field: "drt",
    filterParams: {
      buttons: ["reset"],
    },
    resizable: true,
  },
  {
    colId:"contract_id",
    headerName: "Entitlement Group ID",
    field: "contract_id",
    filterParams: {
      buttons: ["reset"],
    },
    resizable: true,
  },
  /* {
    headerName: "Status",
    field: "status",
    filterParams: {
      buttons: ["reset"],
    },
    resizable: true,
    flex: 0.5,
  }, */
  {
    headerName: "Start Date",
    field: "start_date",
    filterParams: {
      buttons: ["reset"],
    },
    resizable: true,
  },
  {
    headerName: "Next Renewal",
    field: "next_renewal",
    filterParams: {
      buttons: ["reset"],
    },
    resizable: true,
  },
  {
    headerName: "Product Status",
    field: "product_status",
    filterParams: {
      buttons: ["reset"],
    },
    resizable: true,
  },
  {
    headerName: "Replace Product ID",
    field: "replacement_product_id",
    filterParams: {
      buttons: ["reset"],
    },
  },
];