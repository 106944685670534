//@ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import {
  ButtonsRow,
  InlineFeedbackMessage,
  TextArea,
  Tooltip,
  Typography,
} from "@nokia-csf-uxr/ccfk";
import Button, { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import Checkbox from "@nokia-csf-uxr/ccfk/Checkbox";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import {
  setCancelType,
  setSubscriptionCancellation,
  setStatusMessage,
} from "Store/contentState/subscriptionSlice";
import { getUniqueKeyId } from "../utility/utils";

import OOT from "Constants/services/oot";
import ConfirmDialog from "../Custom/ConfirmDialog";
import InProgressDialog from "../Custom/InProgressDialog";
import { useSubscriptionFilter } from "../hooks/useSubscriptionFilter";
import classes from "../Style/Subscription.module.css";

const CancellationReason = () => {
  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );
  const dispatch = useDispatch();
  const { fetchSubscriptionNetworkData, resetCancellationState } =
    useSubscriptionFilter();
  const [selectedReason, setSelectedReason] = useState([]);
  const [otherText, setOtherText] = useState("");
  const [showOtherText, setShowOtherText] = useState(false);
  const [showCancelConfirmDialog, setShowCancelConfirmDialog] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [isCancelInProgress, setIsCancelInProgress] = useState(false);

  const handleQuestionnarieChange = (checkBoxItem, productId = null) => {
    if (
      subscriptionSlice?.cancelType === "individual" &&
      subscriptionSlice?.cancellation?.selectedGridRow.length > 0
    ) {
      // Handle subscription cancellation question data
      let clonedReasons = _.cloneDeep(
        subscriptionSlice?.cancellation?.questionnaire
      );

      clonedReasons[productId] = clonedReasons[productId]
        ? clonedReasons[productId]
        : [];
      if (clonedReasons[productId].includes(checkBoxItem)) {
        // remove from list
        clonedReasons[productId] = clonedReasons[productId].filter(
          (selection) => selection !== checkBoxItem
        );
      } else {
        // Add to list
        clonedReasons[productId].push(checkBoxItem);
      }
      dispatch(
        setSubscriptionCancellation({
          key: "questionnaire",
          data: clonedReasons,
        })
      );
    } else {
      // Handle network cancellation question data
      let clonedReasons = [...selectedReason];
      // console.log(clonedReasons);
      if (clonedReasons.includes(checkBoxItem)) {
        // remove from list
        clonedReasons = selectedReason.filter(
          (selection) => selection !== checkBoxItem
        );
      } else {
        // Add to list
        clonedReasons.push(checkBoxItem);
      }
      setSelectedReason(() => clonedReasons);
      // Handle Selection of Other
      if (checkBoxItem.trim().toLowerCase() === "others") {
        setShowOtherText((prev) => clonedReasons.includes(checkBoxItem));
        if (!clonedReasons.includes(checkBoxItem)) {
          setOtherText("");
        }
      }
    }
  };

  const handleCancelSubmitClick = async () => {
    setShowCancelConfirmDialog(false);
    let formDataCollection = [];
    if (subscriptionSlice?.cancelType === "individual") {
      const subscriptions = subscriptionSlice?.cancellation?.selectedGridRow.map(
        (row) => ({            
          [row.productId]:{
            "contract-id": row.contract_id,
            quantity:
            subscriptionSlice?.cancellation?.quantity[
              `${row.contract_id}_${row.productId}`
            ] ?? 1,
            "question-data": subscriptionSlice?.cancellation?.questionnaire?.[
              `${row.contract_id}_${row.productId}`
            ]
              ? subscriptionSlice?.cancellation?.questionnaire?.[
                  `${row.contract_id}_${row.productId}`
                ].join(",")
              : "",
            others:
              subscriptionSlice?.cancellation?.reasonOther?.[
                `${row.contract_id}_${row.productId}`
              ] ?? "",
          }
        }));

        formDataCollection.push({
          type: "individual",
          "network-id": subscriptionSlice?.filters?.selection?.network,
          "subscriptions":subscriptions
        });
    }
    if (subscriptionSlice?.cancelType === "full") {
      formDataCollection.push({
        type: subscriptionSlice?.cancelType,
        "network-id": subscriptionSlice?.filters?.selection?.network,
        "question-data": selectedReason.join(","),
        others: otherText,
      });
    }
    if (formDataCollection.length > 0) {
      // Handle subscripton cancellation
      setIsCancelInProgress(true);
      const res = await Promise.all(
        formDataCollection.map((formData) =>
          OOT.cancellationSubscription(formData)
        )
      );
      // Prepare status message
      let failedReq = 0;
      let type = "success";
      let message = [];
      message = res
        .filter((rRow) => !rRow.status)
        .map((fRow) => {
          failedReq++;
          return fRow.errorMessage;
        });
      if (failedReq > 0) {
        // All are failed
        type = "error";
        message.unshift(
          `Error in ${
            subscriptionSlice?.cancelType === "individual"
              ? "subscription"
              : "network"
          } cancellation `
        );
      } else {
        type = "success";
        let successMessage =
          subscriptionSlice?.cancelType === "individual"
            ? `Subscription has been cancelled successfully`
            : `Network (${subscriptionSlice?.subInfo?.subscriptionInfo?.sliceId}) has been canncelled successfully`;
        message.push(successMessage);
        const partnerId = subscriptionSlice?.filters?.selection?.partner;
        fetchSubscriptionNetworkData("reload", partnerId);
        // Reset Subscription cancellation state
        resetCancellationState();
        // dispatch(setSubscriptionCancelMode("subscription"));
      }
      dispatch(setStatusMessage({ type, message }));
      setIsCancelInProgress(false);
    }
  };

  useEffect(() => {
    let buttonDisabled = false;
    if (subscriptionSlice?.cancelType === "full") {
      if (selectedReason.length === 0) {
        buttonDisabled = true;
      } else {
        buttonDisabled = selectedReason.includes("Others") && otherText === "";
      }
    }
    setIsSubmitButtonDisabled(buttonDisabled);
  }, [selectedReason, otherText,subscriptionSlice?.cancelType]);

  useEffect(() => {
    let buttonDisabled = false;
    if (subscriptionSlice?.cancelType === "full") {
      buttonDisabled = true;
    }
    if (subscriptionSlice?.cancelType === "individual") {
      const totalSubscriptionSelected =
        subscriptionSlice?.cancellation?.selectedGridRow?.length;
      const totalQuestionData = subscriptionSlice?.cancellation?.questionnaire
        ? Object.keys(subscriptionSlice?.cancellation?.questionnaire).length
        : 0;
      const emptyQuestionData = Object.keys(
        subscriptionSlice?.cancellation?.questionnaire
      ).some(
        (val) =>
          subscriptionSlice?.cancellation?.questionnaire[val].length === 0
      );
      // Check the other reason test
      let isOtherTextEntered = true;
      Object.keys(subscriptionSlice?.cancellation?.questionnaire).forEach(
        (key) => {
          if (
            subscriptionSlice?.cancellation?.questionnaire[key].includes(
              "Others"
            ) &&
            (!subscriptionSlice?.cancellation?.reasonOther[key] ||
              subscriptionSlice?.cancellation?.reasonOther[key] === "")
          ) {
            isOtherTextEntered = false;
            return;
          }
        }
      );
      if (
        totalSubscriptionSelected === 0 ||
        totalQuestionData === 0 ||
        emptyQuestionData ||
        totalSubscriptionSelected !== totalQuestionData ||
        !isOtherTextEntered
      ) {
        buttonDisabled = true;
      } else {
        buttonDisabled = false;
      }
    }
    setIsSubmitButtonDisabled(buttonDisabled);
  }, [
    subscriptionSlice?.cancelType,
    subscriptionSlice?.cancellation?.selectedGridRow,
    subscriptionSlice?.cancellation?.questionnaire,
    subscriptionSlice?.cancellation?.reasonOther,
  ]);

  const isEdgeSubscriptionSelected = () => {
    if(subscriptionSlice?.cancelType === "full" || subscriptionSlice?.cancellation?.selectedGridRow?.length === 0){
      return false;
    }
    // Check whether the selected subscriptions has any edge subscription
    const hasEdge = subscriptionSlice?.cancellation?.selectedGridRow.some((row) => row?.edge_subscription?.toUpperCase() === "YES")
    return hasEdge;
  };
  const showNetworkCancelButton = () => {
    return  (subscriptionSlice?.isNetworkCancellable && !subscriptionSlice?.cancelType && subscriptionSlice?.cancellation?.mode === "subscription")
  }

  return (
    <>
      {/* Confirmation */}
      <ConfirmDialog
        isOpen={showCancelConfirmDialog}
        title="Cancellation Confirmation"
        dialogStyle={
          {
            width: "45rem",
            height: isEdgeSubscriptionSelected() ? "350px" : "17rem",
            left: `calc((100vw - 45rem) / 2)`,
            right: `calc((100vw - 45rem) / 2)`,
          }
        }
        alertMessage={
          subscriptionSlice?.cancelType === "full" ? (
            "Selected network will be cancelled. Once the network subscription expires after cancellation, the network will be disconnected and will not be operable anymore."
          ) : (
            <>
              {isEdgeSubscriptionSelected() && (
                <InlineFeedbackMessage show={true} variant={"warning"}>
                  Note: Selected subscription / license includes Edge
                  subscription and this will cancel the Network.
                  Once the network subscription expires after cancellation, the
                  network will be disconnected and will not be operable anymore.
                </InlineFeedbackMessage>
              )}

              <p>
                Selected subscription / license will be cancelled. Once the
                subscription or license expires after cancellation, the add-on
                subscription / license will not be operable in the network
                anymore.
              </p>
            </>
          )
        }
        confirmMessage={"Do you want to continue?"}
        onCancel={() => setShowCancelConfirmDialog(false)}
        onConfirm={handleCancelSubmitClick}
      />

      {/* Cancellation In Progress */}
      <InProgressDialog
        isOpen={isCancelInProgress}
        title="Please wait ..."
        message="Subscription cancellation request is in progress ..."
      />

      <div className={`${classes.reason} ${classes.asBlocks}`}>
        {showNetworkCancelButton() && (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="destructive"
                onClick={(event) => {
                  event.persist();
                  // console.log("CLICKED", { event });
                  dispatch(
                    setCancelType({
                      cancelType: "full",
                    })
                  );
                }}
                disabled={
                  subscriptionSlice?.filters?.selection?.network.trim()
                    .length === 0
                }
              >
                Cancel Network
              </Button>
            </div>
          )}
        {subscriptionSlice?.cancelType === "full" && (
          <>
            <ul className={classes.cancelQuestionData}>
              <li>
                <TextInputLabelContent>
                  Reason for canceling
                </TextInputLabelContent>
              </li>
              {subscriptionSlice.questionnaire &&
                subscriptionSlice.questionnaire.map((q) => (
                  <li key={`${getUniqueKeyId("small")}_${q}`}>
                    <Checkbox
                      autoFocus={false}
                      disabled={false}
                      checked={selectedReason?.includes(q)}
                      //@ts-ignore
                      value={q}
                      onChange={(e) => handleQuestionnarieChange(q)}
                    />
                    <Typography style={{ display: "inline-block" }}>
                      {q}
                    </Typography>
                  </li>
                ))}
              {showOtherText && (
                <li>
                  <TextArea
                    cols={5}
                    rows={2}
                    variant="outlined"
                    resize={true}
                    error={otherText.trim().length === 0}
                    errorMessage={
                      otherText.trim().length === 0
                        ? "This field is mandatory"
                        : ""
                    }
                    value={otherText}
                    onChange={(e) => setOtherText(e.target.value)}
                  />
                </li>
              )}
            </ul>
          </>
        )}

        {subscriptionSlice?.cancelType === "individual" &&
          subscriptionSlice?.cancellation?.selectedGridRow.length > 0 && (
            <>
              <div style={{ margin: "5px", position: "relative" }}>
                <InlineFeedbackMessage show={true} variant={"warning"}>
                  Select Reason for canceling
                </InlineFeedbackMessage>
              </div>
              <ul className={classes.cancelQuestionData}>
                <li>
                  <TextInputLabelContent>
                    Reason for canceling
                  </TextInputLabelContent>
                </li>
                {subscriptionSlice?.cancellation?.selectedGridRow.map(
                  (node) => {
                    const subscriptionLabel = `${node.subscription_name} ${
                      subscriptionSlice?.cancellation?.quantity[
                        `${node?.contract_id}_${node?.productId}`
                      ] ?? 1
                    } / ${node.renewal_qty} ?`;
                    return (
                      <Fragment key={node?.contract_id + "_" + node?.productId}>
                        <li>
                          <Tooltip
                            tooltip={`${node?.contract_id} - ${subscriptionLabel}`}
                            placement="bottom"
                          >
                            <TextInputLabelContent className={classes.subLabel}>
                              {subscriptionLabel}
                            </TextInputLabelContent>
                          </Tooltip>
                        </li>
                        {subscriptionSlice?.questionnaire.map((q) => {
                          // @ts-ignore
                          return (
                            <li
                              key={
                                node?.contract_id +
                                "_" +
                                node?.productId +
                                "_" +
                                q
                              }
                            >
                              <Checkbox
                                autoFocus={false}
                                disabled={false}
                                // checked={selectedReason?.includes(q)}
                                checked={
                                  subscriptionSlice?.cancellation
                                    ?.questionnaire[
                                    `${node?.contract_id}_${node?.productId}`
                                  ] &&
                                  subscriptionSlice?.cancellation?.questionnaire[
                                    `${node?.contract_id}_${node?.productId}`
                                  ].includes(q)
                                }
                                //@ts-ignore
                                value={q}
                                onChange={(e) =>
                                  handleQuestionnarieChange(
                                    q,
                                    `${node?.contract_id}_${node?.productId}`
                                  )
                                }
                              />
                              <Typography style={{ display: "inline-block" }}>
                                {q}
                              </Typography>
                            </li>
                          );
                        })}
                        {subscriptionSlice?.cancellation?.questionnaire[
                          `${node?.contract_id}_${node?.productId}`
                        ] &&
                          subscriptionSlice?.cancellation?.questionnaire[
                            `${node?.contract_id}_${node?.productId}`
                          ].includes("Others") && (
                            <li>
                              <TextArea
                                cols={5}
                                rows={2}
                                variant="outlined"
                                resize={true}
                                error={
                                  !subscriptionSlice?.cancellation?.reasonOther[
                                    `${node?.contract_id}_${node?.productId}`
                                  ] ||
                                  subscriptionSlice?.cancellation?.reasonOther[
                                    `${node?.contract_id}_${node?.productId}`
                                  ].trim().length === 0
                                }
                                errorMessage={
                                  !subscriptionSlice?.cancellation?.reasonOther[
                                    `${node?.contract_id}_${node?.productId}`
                                  ] ||
                                  subscriptionSlice?.cancellation?.reasonOther[
                                    `${node?.contract_id}_${node?.productId}`
                                  ].trim().length === 0
                                    ? "This field is mandatory"
                                    : ""
                                }
                                value={
                                  subscriptionSlice?.cancellation?.reasonOther[
                                    `${node?.contract_id}_${node?.productId}`
                                  ] ?? ""
                                }
                                onChange={(event) => {
                                  dispatch(
                                    setSubscriptionCancellation({
                                      key: "reasonOther",
                                      data: {
                                        [`${node?.contract_id}_${node?.productId}`]:
                                          event.target.value,
                                      },
                                    })
                                  );
                                }}
                              />
                            </li>
                          )}
                      </Fragment>
                    );
                  }
                )}
              </ul>
            </>
          )}
      </div>

      {["full", "individual"].includes(subscriptionSlice?.cancelType) && (
        <div className={`${classes.buttonRow} ${classes.asBlocks}`}>
          <ButtonsRow>
            <Button
              onClick={() => {
                setSelectedReason([]);
                setOtherText("");
                setShowOtherText(false);
                resetCancellationState();
                // resetSubscription();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitButtonDisabled}
              variant="call-to-action"
              aria-label="ok"
              onClick={() => {
                setShowCancelConfirmDialog(true);
              }}
            >
              <ButtonText>Submit</ButtonText>
            </Button>
          </ButtonsRow>
        </div>
      )}
    </>
  );
};

export default CancellationReason;
