import React, { useState } from "react";
import classes from '../marketplace/Style/ProductSpecification.module.css'
import Tabs, { Tab, TabsContent } from "@nokia-csf-uxr/ccfk/Tabs";
import { Typography } from "@nokia-csf-uxr/ccfk";

import ImageCarousel from "./ImageCarousel";
import TabsSection from "./common/TabsSection";

const customTabData = [
    { tab: { children: 'Flat View', label: 'Tab One description' } },
    // { tab: { children: '360° View', label: 'Tab Two description' } },
    // Add more custom tabs as needed
];

const tabContentComponents = [
    <ImageCarousel key={0} />,
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} key={1}>360&deg; View</div>,
];

const ProductCarousel = () => {
    return (
        <>
            <div className={classes.productImageCarousel}>
             <Typography style={{color:"#000", fontWeight:'bold'}}>Product images</Typography>
             <ImageCarousel key={0} />
            </div>

            {/* carousel section */}

            {/* <div className={classes.productImageCarousel}>
             <TabsSection tabData={customTabData} tabContentComponents={tabContentComponents} />
            </div> */}

        </>
    )
}

export default ProductCarousel;