import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from "Store/mainStore";
import { getDateCalculated } from "../../../Components/Logics/systemLogics";
import { formatDate, getAccountCurrency, shortenText } from '../Utils/commons';




const useOrderPropsData = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const authSelector = useSelector((state: RootState) => state.authSlice);
    const orderTypeDetails = marketplaceSelector.orderType;

    const determineRequestType = useCallback((orderMode) => {
        if (orderTypeDetails.orderNow || orderMode === 'quotationOrder') {
            return "order"; // need to be confirmed
        } else if (orderTypeDetails.generateQuote) {
            return "pendingapproval";
        } else {
            return "draft";
        }
    }, [orderTypeDetails]);

    const getCategory = (item) => {
        const type = item.product_type_id ? item.product_type_id : item.product_type;
        if (type === 'Hardware' || type === 'Software') {
          return 'product';
        } else {
          return 'service';
        }
      };

    const generateNDACItems = useCallback((cart) => {
        return cart.map(item => ({
            product_code: item.id ? item.id : item.product_id,
            quantity: item.product_quantity ? item.product_quantity : item.quantity,
            skip_subscription: 'yes',
            type: getCategory(item),
            ...(item.duration && { duration: item.duration })
        }));
    }, []);

    const generateDataProps = useCallback((cart, accountData, orderMode="", offer_id="") => {
        const ndacItems = generateNDACItems(cart);
        const reqType = determineRequestType(orderMode);
        const datesData = getDateCalculated(); // Assuming this function is defined elsewhere

        const baseProps = {
            offer: {
                id: marketplaceSelector.cartResponse.cartOfferId !== "" ? marketplaceSelector.cartResponse.cartOfferId : offer_id,
                name: marketplaceSelector.quotationRefernce ? marketplaceSelector.quotationRefernce : `MKT:${accountData[0]['1*_number']}:${shortenText(accountData[0]['name'])}`,
                shipping_country: marketplaceSelector.filters.selection.shippingCountry,
                sales_channel: "DIR",
                end_customer: (accountData.length !== 0 || accountData[0]['end_customer_nid'] !== null) ? accountData[0]['end_customer_nid'] : "",
                market: "Global Frame",
                summary: "",
                owner: authSelector.login.mail,
                network_location: marketplaceSelector.filters.selection.shippingCountry,
                co_owner: "",
                scrm_id: "19.XY.123456",
                public_funding_required: "no",
                reason_for_funding: "NA",
                case_type: "new",
                case_sub_type: "help",
                budgetary: "Binding",
                lead_product: "NDAC",
                commercial_model: "opex",
                contract_duration: 1,
                contracting_party_id: (accountData.length !== 0 || accountData[0]['nid'] !== null) ? accountData[0]['nid'] : "",
                currency: accountData.length !== 0 ? getAccountCurrency(accountData[0]['currency'], accountData[0]['currencyisocode']) : "",
                network_id: "",
                account_id: accountData.length !== 0 ? accountData[0]['id'] : "",
                estimated_order_date: datesData.final_est_order_date,
                requested_deli_date: datesData.final_req_delivery_date,
                nature_of_order: "Sales order",
                network_details: "Offer",
                requested_state: reqType,
                request_source: "marketplace"
            },
            ndac_items: ndacItems,
            account_details: {
                one_star_number: (accountData.length !== 0 || accountData[0]['1*_number'] !== null) ? accountData[0]['1*_number']: "",
                three_star_number: (accountData.length !== 0 || accountData[0]['3*_number'] !== null) ? accountData[0]['3*_number'] : "",
                account_text: (accountData.length !== 0 || accountData[0]['name'] !== null)? accountData[0]['name'] : "",
                customer_one_star: (accountData.length !== 0 || accountData[0]['customer_one_star_num'] !== null)? accountData[0]['customer_one_star_num'] : "",
                customer_three_star: (accountData.length !== 0 || accountData[0]['customer_three_star_num'] !== null) ? accountData[0]['customer_three_star_num']: "",
                "nokia_terms": marketplaceSelector.nokiaTermsConditions
            }
        };

        if (reqType === "order" || reqType === "pendingapproval") {
            baseProps.offer['billing_address_id'] = marketplaceSelector.uploadPODetails ? marketplaceSelector.uploadPODetails.billingAddress['id'] : "";
            baseProps.offer['shipping_address_id'] = marketplaceSelector.uploadPODetails ? marketplaceSelector.uploadPODetails.shippingAddress['id'] : "";
            baseProps.offer['billing_address'] = [];
            baseProps.offer['shipping_address'] = [];
        
            if (reqType === "order") {
                baseProps['poReferenceID'] = marketplaceSelector.uploadPODetails ? marketplaceSelector.uploadPODetails.poReferenceNumber : "";
                baseProps.offer['invoicing_frequency'] = "Upfront full";
                baseProps.offer['customer_po_file_date'] = formatDate(new Date(), 'yyyy-mm-dd');
            }
        }

        return baseProps;
    }, [generateNDACItems, determineRequestType, marketplaceSelector, authSelector]);

    return generateDataProps;
};

export default useOrderPropsData;

