//@ts-nocheck


import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { RootState } from 'Store/mainStore';

const ProtectedRoute = ({ requiredAccess }) => {
  const userAccessInfo = useSelector((state: RootState) => state.marketplace.userAccessInfo);

  return userAccessInfo.includes(requiredAccess) ? <Outlet /> : <Navigate to="/unauthorized" replace />;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  requiredAccess: PropTypes.string,
 
};

