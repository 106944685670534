import StateMachine from 'Components/Logics/stateMachine';
import _ from 'lodash';
// import { Dispatch, Middleware, MiddlewareAPI } from 'redux'
import { Dispatch, MiddlewareAPI } from 'redux'
import { store } from 'Store/mainStore';
// import { store } from './mainStore';
import { setPTDACLogics } from './contentState/stateMachine';
var debounce_fun = _.debounce(() => {
    const sm = new StateMachine(store.getState());
    const mandatoryApp = sm.navigateBusiness()?.navigateApplication()?.navigateConnectivity()?.navigateEdge()?.navigateDevices()?.navigateServices()?.navigateWarranty();
    store.dispatch(setPTDACLogics(mandatoryApp?.response))
    console.log('res', mandatoryApp?.response)
  }, 1000)
export const myLoggingMiddleware = (_: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
  // Here you have access to `action.type` and `action.payload`
  
  if (window.location.href.includes('/segment') && action.type !== 'stateMachine/setPTDACLogics') {
    //console.log('Logging action with type', action.type)
    /*
    This makes sure the RULE-ENGINE only  runs on the SEGMENT SOLUTIONS.
    */
    debounce_fun();
  }
  // You should always do this at the end of your middleware
  return next(action)
}