import { CircularProgressIndicatorIndeterminate } from '@nokia-csf-uxr/ccfk'
import { systemStyle } from 'Constants/StyleLayer'
import React from 'react'

function Loader() {
    return (
        <div style={systemStyle.flex({ justify: 'center' })}><CircularProgressIndicatorIndeterminate style={{ height: '32px' }} /></div>
    )
}

export default Loader