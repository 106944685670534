import { createSlice } from "@reduxjs/toolkit";
import { setDataByKey } from "Components/Logics/ensoCommonFunctions";
import { STATUS, caseCategory } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from "Store/Actions/ts";
import _ from "lodash";


const initialData = {
  caseName: "",
  caseCategory: caseCategory.BUDG,
  caseIndustry: "", // FIXME: Remove this as added in segmentSlice
  country: "", //n/w loc country
  currency: "",
  account: "",
  account_id: "",
  distributor: "",
  indirect_resellers: "",
  resellers: "",
  shipping_country: "",
  sales_channel: "",
  market: "",
  endCustomer: "",
  summary: "",
  co_owner: "",
  public_funding_required: false,
  additionalFeature: [], // FIXME: Remove this as added in segmentSlice
  contract_duration: 1, // default cannot be 0 as if(0) fails when checking isFormValid
  network_ids: ''
};
const caseDetailseSlice = createSlice({
  name: "caseDetails",
  initialState: {
    data: initialData,
    status: STATUS.HIDE,
  },
  reducers: {
    ...setDataByKey,
    setOBJ(state, action) {
      _.set(state.data, action.payload.path, action.payload.value);
    },
    setData(state, action) {
      state.data = {
        ...state.data,
        [action.payload.key]: action.payload.value,
      };
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    // TODO: Remove this as added in segmentSlice
    setFeature(state, action) {
      // state.data.additionalFeature = [];
      if (!state.data.additionalFeature.includes(action.payload)) {
        state.data.additionalFeature.push(action.payload);
      } else {
        var index = state.data.additionalFeature.indexOf(action.payload);
        if (index !== -1) {
          state.data.additionalFeature.splice(index, 1);
        }
      }
    },
    // TODO: Remove this as added in segmentSlice
    setSegment(state, action) {
      state.data.additionalFeature = [];
      state.data = {
        ...state.data,
        [action.payload.key]: action.payload.value,
      };
      //this.setData(action.payload);
    },
    resetCD(state,) {
      state.status = STATUS.HIDE;
      state.data = initialData;
    },
    setContractDuration(state, action) {
      state.data.contract_duration = action.payload;
    },
    // setTheme(state, action) {
    //   state.theme = { ...state.theme, [action.payload.key]: action.payload };
    // },
  },
  extraReducers(builder) {
    builder.addCase(setProp, (state, action: setPropT) => {
      if (action.payload.slice === caseDetailseSlice.name) {
        state.data = {
          ...state.data,
          [action.payload.key]: action.payload.value,
        };
      }
    });
  }
});

export default caseDetailseSlice.reducer;
export const {
  setData,
  setStatus,
  setOBJ,
  resetCD,
  setFeature,
  setSegment,
  setContractDuration,
} = caseDetailseSlice.actions;
