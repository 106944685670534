import React, {useState} from "react";
import saveIcon from '../../../Assets/Icons/marketplace_save_item.svg'
import cartDeleteIcon from '../../../Assets/Icons/cart_delete_icon.svg'
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import Button from '@nokia-csf-uxr/ccfk/Button';
import useCartActions from "../hooks/useCartActions";
import ConfirmDialog from "../UIElements/ConfirmDialog";




const CartItemActionButtons = (prop) => {
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const {handleAction} = useCartActions();

    const formattedConfirmMsg = <span>Are you sure you want to delete the item <strong>{prop.actionItem.product_description}</strong> from cart?</span>

    const handleRemoveProductFromCart = () => {
      handleAction('deleteItem', { itemId: prop.actionItem.id});
    }

    
  return (
    <>
    <div style={{ position:'absolute', bottom:'15px' }}>
    {/* <Button
            variant="secondary-small"
            onClick={() => dispatch(removeProductFromCart(prop.actionItem.id))}>
            <ButtonIcon><img src={saveIcon} alt='delete-icon' /></ButtonIcon>
            <ButtonText>Save for later</ButtonText>

        </Button> */}
        <Button
            variant="destructive-small"
            // onClick={() => dispatch(removeProductFromCart(prop.actionItem.id))}
            onClick={() => setShowDeleteConfirmDialog(true)}

        >
            <ButtonIcon><img src={cartDeleteIcon} alt='delete-icon' /></ButtonIcon>
            <ButtonText>Delete</ButtonText>

        </Button>
        </div>


        {showDeleteConfirmDialog && <ConfirmDialog isOpen={showDeleteConfirmDialog} title="Delete product?" confirmMessage={formattedConfirmMsg} onCancel={() => setShowDeleteConfirmDialog(false)}
        onConfirm={handleRemoveProductFromCart}/>}
    </>
  )
}

export default CartItemActionButtons;