import { createSlice } from "@reduxjs/toolkit";
import {
  extensionOfferTools,
  STATUS
} from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
const initialData = {
  caseType: "",
  sCRMID: "",
  extOffer: extensionOfferTools.MPW_GSM,
  subType: "",
};
const findCaseSlice = createSlice({
  name: "findCase",
  initialState: {
    data: initialData,
    status: STATUS.IDLE,
  },
  reducers: {
    setDataFC(state, action) {
      state.data = {
        ...state.data,
        [action.payload.key]: action.payload.value,
      };
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    resetFC(state,) {
      state.data = initialData;
      state.status = STATUS.IDLE;
    },
    // setTheme(state, action) {
    //   state.theme = { ...state.theme, [action.payload.key]: action.payload };
    // },
  },
  extraReducers(builder) {
    builder.addCase(setProp, (state, action: {
      payload: any;
      type: string;
      slice: string;
    }) => {
      if (action.payload.slice === findCaseSlice.name) {
        state.data = {
          ...state.data,
          [action.payload.key]: action.payload.value,
        };
      }
    });
  }
});

export default findCaseSlice.reducer;
export const { setDataFC, setStatus, resetFC } = findCaseSlice.actions;
