import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from 'Store/Actions/ts';
import setObj from "lodash/set";
const initialData = {
    PTDAC: {}
};
const reviewSlice = createSlice({
    initialState: {
        data: initialData,
        status: STATUS.IDLE,
    },
    name: 'review',
    reducers: {},
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === reviewSlice.name) {
                setObj(state.data, action.payload.key, action.payload.value);
            }
        })
    }
});

export default reviewSlice.reducer;
export const { } = reviewSlice.actions;