import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import { Typography } from "@nokia-csf-uxr/ccfk";
import CartListItem from "./cart/CartListItem";
import CartDiscountsActions from "./cart/CartDiscountsActions";
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from "./MyDocument";
import { formatDate } from "./Utils/commons";


const CartItemsList = () => {
  const dispatch = useDispatch()
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const cart = marketplaceSelector.cart

  
  const nokiaProducts = cart.filter(item => item.is_agent_model === 0);
  const thirdPartyProducts = cart.filter(item => item.is_agent_model === 1);
  return (
    <React.Fragment>
      <div style={{ padding: '0.75rem' }}>
        {nokiaProducts.length > 0 && (
          <div>
            <div style={{ padding: '.3rem', backgroundColor: "#E4F0FF", marginBottom: '.3rem' }}>
              <Typography style={{ color: '#333333', fontSize: '14px', fontWeight: 'bold' }}>Nokia items</Typography>
            </div>
            {nokiaProducts.map(item => (
              <CartListItem key={item.id} cartItem={item}/>
            ))}
          </div>
        )}

        {thirdPartyProducts.length > 0 && (
          <div>
            <div style={{ padding: '.3rem', backgroundColor: "#E4F0FF", marginBottom: '.3rem' }}>
              <Typography style={{ color: '#333333', fontSize: '14px', fontWeight: 'bold' }}>Third party items</Typography>
            </div>
            {thirdPartyProducts.map(item => (
              <CartListItem key={item.id} cartItem={item}/>
            ))}
          </div>
        )}

        <>
          <CartDiscountsActions />

          {/* <PDFViewer width="100%" height="600">
        <MyDocument data={marketplaceSelector.cart} accountDetails={marketplaceSelector.accountDetails[0]} offerId={marketplaceSelector.cartResponse.cartOfferId} cartResponse={marketplaceSelector.cartResponse} orderType={marketplaceSelector.orderType} createdDate={formatDate(new Date())}/>
      </PDFViewer> */}
        </>
      </div>

    </React.Fragment>
  )


}

export default CartItemsList;