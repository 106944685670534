import React from "react";
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import infoCircleIcon from '../../../Assets/Icons/info-circle-small-fill-white.svg';
import { Typography } from "@nokia-csf-uxr/ccfk";
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ReactComponent as DiscountIcon } from '../../../Assets/Icons/discount_icon.svg'
import marketplaceSlice from "Store/contentState/marketplaceSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import useCartActions from "../hooks/useCartActions";

const CartDiscountsActions = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const conditionalStyle = {
        backgroundColor: (marketplaceSelector.isCartChanged && !marketplaceSelector.isNokiaUser) ? "#13902F" : "#f3f3f3", // Replace 'defaultColor' with your default background color
        color: (marketplaceSelector.isCartChanged && !marketplaceSelector.isNokiaUser) ? "#FFFFFFBF" : "#999999" // Replace 'defaultTextColor' with your default text color
    };

    const { syncOfferCall, saveCartDetails } = useCartActions();


    const handleApplyDiscounts = () => {
        syncOfferCall();

    }

  

    return (
        <>
            <section style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center', maxWidth: '100%', gap: '.5rem', marginTop: '20px' }}>
                <div style={{ display: 'flex' }}>
                    <Tooltip
                        closeOnReferenceHidden={false}
                        placement="bottom"
                        // fallbackPlacements={['right', 'left']}
                        modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
                        trigger="hover"
                        tooltip={""}
                    >
                        <img src={infoCircleIcon} />
                    </Tooltip>
                    <Typography style={{ margin: '3px 0 0 3px' }}>Discounts will not be auto applied, click Proceed to avail discounted price</Typography>
                </div>
                <div>
                    <Button
                        variant="call-to-action"
                        // onClick={() => dispatch(removeProductFromCart(prop.actionItem.id))}
                        disabled={!marketplaceSelector.isCartChanged || marketplaceSelector.isNokiaUser}
                        style={conditionalStyle}
                    >
                        <ButtonIcon><DiscountIcon/></ButtonIcon>
                        <ButtonText style={{ fontSize: '12px' }} onClick={handleApplyDiscounts}>Proceed</ButtonText>

                    </Button>
                </div>
            </section>
        </>
    )

}

export default CartDiscountsActions;