import React, { useEffect, useState } from "react";
import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import ProductSpecificationBody from "./ProductSpecificationBody";
import { useParams } from 'react-router-dom';
import { setSelectedDeviceID, setLoader } from "Store/contentState/marketplaceSlice";
import Loader from "Components/Elements/Loader";

const SelectedProductPage = () => {
  const dispatch = useDispatch()
  //use redux here later
  const [isLoading, setIsLoading] = useState(false)
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const { id } = useParams();
  //console.log(id, 'id')



  useEffect(() => {
    dispatch(
      setLoader({
        key: "isSelectedProductLoading",
        value: true,
      })
    );
    if (id) {
      setIsLoading(true)
      //console.log(marketplaceSelector.selectedDeviceSpecification, 'specification')
      dispatch(setSelectedDeviceID(id))
      setTimeout(() => {
        dispatch(
          setLoader({
            key: "isSelectedProductLoading",
            value: false,
          })
        );
      }, 1000)
    }
  }, [id])

 
  return (
    <>
      <ProductSpecificationBody data={id} />
    </>
  )
}

export default SelectedProductPage;