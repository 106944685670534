import React from 'react';
import { InlineFeedbackMessage } from '@nokia-csf-uxr/ccfk';
import { RootState } from 'Store/mainStore';
import { useSelector, useDispatch } from "react-redux";
import {
  setStatusMessage
} from "Store/contentState/subscriptionSlice";

const StatusMessage = () => {
  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );
  const dispatch = useDispatch();
  const closeMessage = () => {
    console.log("Inside Close Message");
    dispatch(setStatusMessage({type:"",message:[]}))
  };
  return (<>
    {subscriptionSlice?.statusMessage?.type !== "" && subscriptionSlice?.statusMessage?.message.length > 0 && (
      <div style={{ margin: "5px", position: "relative" }}>
        <InlineFeedbackMessage show={true} variant={subscriptionSlice?.statusMessage?.type} closeButton={true} onClose={() => { closeMessage(); }}>
          {
            subscriptionSlice?.statusMessage?.message.map((msg,i)=>(
              <li key={`msg_${i}`}>{msg}</li>
            ))
          }
        </InlineFeedbackMessage>
      </div>
    )}
    </>
  )
}

export default StatusMessage