import React, {useEffect} from "react";

import classes from '../marketplace/Style/Marketplace.module.css'
import MarketplaceFilter from './Components/MarketplaceFilter';
import ProductsCatalogPage from './ProductsCatalogPage';
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import Loader from "Components/Elements/Loader";
import CommonFilter from "./common/CommonFilter";


const MarketplaceBody = () => {
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);

  if(marketplaceSelector.isPartnerLoading){
    return <Loader/>
  }
  return (
    <div className={classes.marketplaceWrapper}>
      {marketplaceSelector.isGetProducts === false && <CommonFilter/>}
      {
        marketplaceSelector.isGetProducts && <MarketplaceFilter/>
      }
      
      <div className={classes.marketplaceProductsCatalogContainer}>
      <ProductsCatalogPage/>
     
      </div>
      

    </div>
  )
}

export default MarketplaceBody