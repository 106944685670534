export const subscriptionDurationOptions = [
    { id: 1, value: "1 Year" },
    { id: 2, value: "2 Year" },
    { id: 3, value: "3 Year" }

];

export const subscriptionPlansOptions = {
    allIds: ["basic", "pro", "premium"],
    byPlan: {
        "basic": { plan: "Basic", duration: { hours: "8", days: "7" }, id: "basic", resolution: ["Best effort"], channels: ["dac_info", "dac_manger", "email"], service: "48h", cost: 1000 ,products:[]},
        "pro": { plan: "Professional", duration: { hours: "24", days: "7" }, id: "pro", service: "24h", resolution: ["Best effort"], cost: 1500, channels: ["dac_info", "dac_manger", "email", "chat", "escalation"],products:[] },
        "premium": { plan: "Premium", duration: { hours: "24", days: "7" }, id: "premium", service: "24h", resolution: ["P1 4h", "P2 8h", "P3 48h"], cost: 1700, channels: ["dac_info", "dac_manger", "email", "chat", "escalation"],products:[] }
    }
}