//@ts-nocheck
import ExpansionPanelsContainer, {
  ExpansionPanel,
  ExpansionPanelBody,
  ExpansionPanelButton,
  ExpansionPanelHeader,
  ExpansionPanelTitle,
} from "@nokia-csf-uxr/ccfk/ExpansionPanels";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";

import {
  setSubscriptionCancelMode,
  setStatusMessage,
} from "Store/contentState/subscriptionSlice";
import AccountOverview from "../Account/AccountOverview";
import SubscriptionFilter from "../SubscriptionFilter";
import SubscriptionCancellationGrid from "./SubscriptionCancellationGrid";
import CancellationReason from "./CancellationReason";
import StatusMessage from "../Custom/StatusMessage";
import { useSubscriptionFilter } from "../hooks/useSubscriptionFilter";
import WelcomePage from "../Components/WelcomePage";
import classes from "../Style/Subscription.module.css";

const AccountBody = () => {
  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );
  const { resetCancellationState } = useSubscriptionFilter();

  const [isCancelModeEnabled, setIsCancelModeEnabled] = useState(false);
  const headerRef = useRef(null);
  const dispatch = useDispatch();
  
  const handleExpansion = (id) => (e) => {
    const newId = subscriptionSlice?.cancellation?.mode === id ? undefined : id;

    if (e.type === "keydown") {
      if (
        e.target.getAttribute("data-test") === "header" && // check if keydown from header
        (e.key === " " || e.key === "Enter")
      ) {
        dispatch(setSubscriptionCancelMode(newId));
      }
    }
    if (e.type === "click") {
      dispatch(setSubscriptionCancelMode(newId));
    }
  };

  useEffect(() => {
    resetCancellationState();
    dispatch(setSubscriptionCancelMode("AcountOverview"));
    dispatch(setStatusMessage({ type: "", message: "" }));
  }, [dispatch]);

  return (
    <div className={classes.AppBodyContent}>
      <div className={`${classes.contentWrapper} ${classes.card}`}>
        <StatusMessage />
        <SubscriptionFilter />
        {subscriptionSlice?.filters?.selection?.network.trim().length === 0 ? (
          <WelcomePage />
        ) : (
          <ExpansionPanelsContainer>
            <ExpansionPanel
              selected={
                subscriptionSlice?.cancellation?.mode === "AcountOverview"
              }
              expanded={
                subscriptionSlice?.cancellation?.mode === "AcountOverview"
              }
              key={"AcountOverview"}
              id={"AcountOverview"}
            >
              <ExpansionPanelHeader
                data-test="header"
                ref={headerRef}
                role="button"
                style={{ justifyContent: "space-between" }}
                onKeyDown={handleExpansion("AcountOverview")}
                onClick={handleExpansion("AcountOverview")}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "18.75rem",
                  }}
                >
                  <ExpansionPanelTitle>Acount Overview</ExpansionPanelTitle>
                </div>
                <ExpansionPanelButton
                  onClick={handleExpansion("AcountOverview")}
                  iconButtonProps={{
                    "aria-hidden": true,
                  }}
                />
              </ExpansionPanelHeader>
              <ExpansionPanelBody>
                <AccountOverview
                  onCancellationEnable={setIsCancelModeEnabled}
                />
              </ExpansionPanelBody>
            </ExpansionPanel>
            {/* Data grid panel*/}
            {isCancelModeEnabled &&
              subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() ===
                "active" && (
                <ExpansionPanel
                  selected={
                    subscriptionSlice?.cancellation?.mode === "subscription"
                  }
                  expanded={
                    subscriptionSlice?.cancellation?.mode === "subscription"
                  }
                  key={"subscription"}
                  id={"subscription"}
                >
                  <ExpansionPanelHeader
                    data-test="header"
                    ref={headerRef}
                    role="button"
                    style={{ justifyContent: "space-between" }}
                    onKeyDown={handleExpansion("subscription")}
                    onClick={handleExpansion("subscription")}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "18.75rem",
                      }}
                    >
                      <ExpansionPanelTitle>
                        Manage Subscription and yearly licenses
                      </ExpansionPanelTitle>
                    </div>
                    <ExpansionPanelButton
                      onClick={handleExpansion("subscription")}
                      iconButtonProps={{
                        "aria-hidden": true,
                      }}
                    />
                  </ExpansionPanelHeader>
                  <ExpansionPanelBody>
                    <SubscriptionCancellationGrid />
                  </ExpansionPanelBody>
                </ExpansionPanel>
              )}
            {/* Data grid panel*/}
          </ExpansionPanelsContainer>
        )}
      </div>
      <aside className={`${classes.rightBoxWrapper} ${classes.card} ${classes.subAside}`}>
        {subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() ===
          "active" && <CancellationReason />}
      </aside>
    </div>
  );
};

export default AccountBody;
