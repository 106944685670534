import React, { useEffect } from "react";
import SelectBox from "../Custom/SelectBox";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import { useSubscriptionFilter } from "../hooks/useSubscriptionFilter";
import withSpinner,{withSpinnerHOCType} from "../HOC/withSpinner";



const SellToParty = (props: withSpinnerHOCType) => {
  const { setLoading, isLoading } = props;
  const { fetchSubscriptionNetworkData } = useSubscriptionFilter();
  const subscriptionSlice = useSelector(
    (state: RootState) => state?.subscription
  );
  const isPartnerLoading = useSelector(
    (state: RootState) => state?.subscription?.isPartnerLoading
  );

  const handlePartnerSelection = (__partner) => {
    fetchSubscriptionNetworkData("fetch", __partner);
  };

  useEffect(() => {
    setLoading(isPartnerLoading);
  }, [isPartnerLoading,setLoading]);

  return (
    <SelectBox
      labelText="Sell-to Party"
      placeholder="Select Sell-to Party"
      maxWidth={true}
      caseInsentiveMatching={true}
      selectedItem={subscriptionSlice?.filters?.selection?.partner}
      data={subscriptionSlice.partnerList}
      onUpdateSelection={handlePartnerSelection}
      isLoading={isLoading}
    />
  );
};

export default withSpinner(SellToParty);
