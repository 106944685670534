import React from 'react';
import classes from '../../Style/Cart.module.css';
import TermsConditions from 'Pages/marketplace/TermsConditions';
import quotationImage from '../../../../Assets/Icons/terms.svg';


const OrderCreation = () => {
    return (
        <>
            <div className={classes.cartSummaryOrderSection}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={quotationImage} />
                </div>
                <TermsConditions />
            </div>
        </>
    )
}

export default OrderCreation;