import React from "react";
import PDF_ICon from '../../../Assets/Icons/pdf_icon.svg';
import Button, {
  ButtonText,
  ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import _ from "lodash";


import { Typography, Label } from "@nokia-csf-uxr/ccfk";
import Loader from "Components/Elements/Loader";
import quotationErrorImg from '../../../Assets/Icons/quotation_error.svg';
import { useNavigate } from "react-router-dom";
import { clearCart } from "Store/contentState/marketplaceSlice";
import orderSuccessImg from '../../../Assets/Icons/Order-success.svg';
import { resetOrderResponseProps, resetCartResponse, clearUploadPO } from "Store/contentState/marketplaceSlice";
import MyDocument from "../MyDocument";
import { formatDate } from "../Utils/commons";
 

const DIALOG_HEIGHT = "55%";
const DIALOG_WIDTH = "30%";

const CONFIRM_ALERT_STYLE = {
  top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
  height: DIALOG_HEIGHT,
  minHeight: DIALOG_HEIGHT,
  left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  width: DIALOG_WIDTH,
};

type IQuoteResponseCloseProp = {
  onClose: () => void;
};

const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 48,
    paddingHorizontal: 48,
    boxSizing: "border-box",
  },
  heading: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "left",
    borderBottom: "1px solid #000"
  },
  detailsText: {
    fontSize: 15,
    marginBottom: 10,
    textAlign: "left",
  },
  detailsSection: {
    padding: '10px',
    backgroundColor: '#96AAC533'
  }
});





// eslint-disable-next-line react/prop-types


const OrderResponse = ({ onClose, orderMode, selectedOffer }: IQuoteResponseCloseProp & { orderMode: string } & { selectedOffer: any }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const cart = useSelector((state: RootState) => state.marketplace.cart);
  const ownerName = useSelector((state: RootState) => state.authSlice.login.displayName);
  let updatedProducts = [];
  let cartResponse = {};

  if(orderMode === 'quotationOrder'){
     cartResponse = {
      currency: selectedOffer['field_ofr_order_currency'],
      cartSummary:{
          deal_final_price : Number(selectedOffer['field_ofr_order_cart_total']).toFixed(2)
      },
      cartOfferId: selectedOffer['offer_id'],
    }
  
    if (selectedOffer && Array.isArray(selectedOffer['products'])) {
      updatedProducts = selectedOffer['products'].map((item) => {
       return {
         ...item,
         id: item.product_id,
         list_price: item.price,
         product_description: item.title,
         customs_and_logistics: item.custom_logistics_price,
         product_type_id: item.product_type
       };
     });
 // Debug log to check the final array
   } 
  }

  

  const handleSuccessClose = () => {
    dispatch(clearCart());
    dispatch(resetOrderResponseProps());
    dispatch(resetCartResponse());
    dispatch(clearUploadPO());
    // Wrap in setTimeout to ensure navigation occurs after modal closes
    setTimeout(() => {
      if (marketplaceSelector.orderType.orderNow || orderMode === "quotationOrder") {
        navigate('/orders');
      } else {
        navigate('/quotations');
      }
    }, 0); // Delay by 0ms to ensure it executes after modal onClose

    onClose(); // Close the modal
  };

  return (
    <>

      {marketplaceSelector.orderResponseProps.isOrderLoading &&
        <div style={{ marginTop: '5rem' }}>
          <Loader />
          <Typography style={{ textAlign: 'center', fontWeight: 700, fontSize: '10px' }}>Please wait, generating the {marketplaceSelector.orderType.generateQuote ? "quote": "order"}</Typography>
        </div>

      }
      {marketplaceSelector.orderResponseProps.isOrderCreated && <div style={{}}>

        {/* <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <img src={PDF_ICon} alt="pdf-icon" />
            </div> */}

        <div style={{ padding: '2rem' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{}}>
              <img src={orderSuccessImg} alt="order-success" />
            </div>

          </div>
          <div style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
            <Typography style={{ color: "#333333", fontSize: "14px", fontWeight: "700" }}>Your {marketplaceSelector.orderType.generateQuote ? "quotation" : "order"} <a href={`${process.env.REACT_APP_OOT}/offer/edit/${orderMode === 'quotationOrder' ? selectedOffer['offer_id'] : marketplaceSelector.cartResponse.cartOfferId}`} target="_blank" rel="noopener noreferrer"><span style={{ color: "#757575", fontSize: "16px" }}>#{orderMode === 'quotationOrder' ? selectedOffer['offer_id'] : marketplaceSelector.cartResponse.cartOfferId}</span></a> has been placed successfully. Please download the generated order</Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', gap: '.5rem' }}>
            <Button variant="secondary" fullWidth={false} onClick={handleSuccessClose}><ButtonText>Close</ButtonText></Button>
            {/* <Button variant="secondary" fullWidth={false}><ButtonText>Email</ButtonText></Button> */}
            <Button
              variant="call-to-action"
              style={{ marginRight: '1rem' }}
              fullWidth={false}
            >
              <PDFDownloadLink document={(orderMode === 'quotationOrder' ? cartResponse : marketplaceSelector.cartResponse) && <MyDocument data={orderMode === 'quotationOrder' ? updatedProducts : marketplaceSelector.cart} accountDetails={marketplaceSelector.accountDetails[0]} cartResponse={orderMode === 'quotationOrder' ? cartResponse : marketplaceSelector.cartResponse} owner={ownerName} orderType={marketplaceSelector.orderType} createdDate={formatDate(new Date(), 'dd-MMM-yyyy')} billingAddress={marketplaceSelector.uploadPODetails.billingAddress} shippingAddress={marketplaceSelector.uploadPODetails.shippingAddress} />} fileName={`${marketplaceSelector.orderType.generateQuote ? "Quotation" : "Order"}.pdf`} style={{ textDecoration: 'none', color:'#FFFFFF !important' }}>
                {({ loading }) => (loading ? 'Loading document...' : <ButtonText style={{ color:'#FFFFFF !important' }}>Download</ButtonText>)}
              </PDFDownloadLink>
              {/* 
                <MyDocument data={marketplaceSelector.cart} accountDetails={marketplaceSelector.accountDetails[0]} offerId={marketplaceSelector.cartOfferId} cartResponse={marketplaceSelector.cartResponse}/> */}

            </Button>
          </div>

          <div style={{ display: 'block', textAlign: 'center', marginTop: '1rem' }}>
            <Typography style={{ color: '#333333', fontSize: '12px' }}>You can also check your {marketplaceSelector.orderType.generateQuote ? "quotation" : "order"} details in {marketplaceSelector.orderType.generateQuote ? "Quotation" : "Order"} page and in case of any clarification or doubt please contact us  <span style={{ color: "#005AFF" }}>+33 6575 343</span> or <span style={{ color: "#005AFF" }}>email sales.support@nokia.com</span></Typography>
          </div>
        </div>

      </div>}

      {
        marketplaceSelector.orderResponseProps.isOrderErrorOccur &&
        <div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={quotationErrorImg} alt="error-icon" height={100} />
          </div>

          <div style={{ display: 'block', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
            {/* <h5 style={{ fontSize: '16px', fontWeight: 'bold', marginBlockStart: '0', marginBlockEnd: '0', marginBottom: '.5rem' }}>Something went wrong</h5> */}
            <Typography >We are unable to  create the {marketplaceSelector.orderType.generateQuote ? "quotation" : "order"} at the</Typography>
            <Typography style={{ marginBottom: '.5rem' }}>moment, please try again.</Typography>

          </div>
          {/* <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginTop: '3rem' }}>
                <Button variant="call-to-action" onClick={onClose}><ButtonText>Close</ButtonText></Button>
              </div> */}

        </div>
      }


    </>
  )

}

export default OrderResponse;

