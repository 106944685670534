import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import MoreVerticalIcon from "@nokia-csf-uxr/ccfk-assets/latest/MoreVerticalIcon";
import DownloadIcon from "@nokia-csf-uxr/ccfk-assets/latest/DownloadIcon";
import {
  Button,
  Checkbox,
  Dialog,
  IconButton,
  Label,
  Tooltip,
} from "@nokia-csf-uxr/ccfk";
import classes from "./ExcelExport.module.css";
import {
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { CheckboxLabelContent } from "@nokia-csf-uxr/ccfk/Checkbox";

interface IExcelExport {
  label: string;
  fileName: string;
  columnDefs: { header: string; field: string }[];
  exportData: [];
  isConfigurable: boolean;
}

const ExcelExport = (props: IExcelExport) => {
  const {
    label,
    fileName,
    columnDefs,
    exportData,
    isConfigurable = false,
  } = props;

  const [showConfigPopup, setShowConfigPopup] = useState(false);
  const [isAllFieldChecked, setIsAllFieldChecked] = useState(true);
  const [selectedFields, setSelectedFields] = useState([]);

  useEffect(() => {
    toggleCheckAll(true);
  }, []);
  const toggleCheckAll = (toggleState: boolean) => {
    if (toggleState) {
      setSelectedFields(columnDefs.map((row) => row.field));
      setIsAllFieldChecked(true);
    } else {
      setSelectedFields([]);
      setIsAllFieldChecked(false);
    }
  };

  const handleFieldSelection = (field) => {
    const clonedSelection = [...selectedFields];
    if (clonedSelection.includes(field)) {
      setSelectedFields(() => clonedSelection.filter((f) => f !== field));
      setIsAllFieldChecked(false);
    } else {
      clonedSelection.push(field);
      setSelectedFields(() => clonedSelection);
      setIsAllFieldChecked(
        columnDefs.map((row) => row.field).length === clonedSelection.length
      );
    }
  };

  const applyExportFieldConfiguration = () => {
    prepareExcelExportData();
    setShowConfigPopup(false);
  };
  const prepareExcelExportData = () => {
    let eeFileNmae = fileName ?? "Export";
    eeFileNmae += `_${Date.now()}`;
    // Make columnDefs to match the selected fields
    const filteredColDef = columnDefs.filter((row) =>
      selectedFields.includes(row.field)
    );
    console.log(filteredColDef);
    const xlsxExportData = exportData.map((row) => {
      const exportRow = {};
      filteredColDef.forEach(
        (selecteRow) => (exportRow[selecteRow.header] = row[selecteRow.field])
      );
      return exportRow;
    });
    const ws = XLSX.utils.json_to_sheet(xlsxExportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${eeFileNmae}.xlsx`);
  };
  return (
    <>
      <div className={classes.eeWrapper}>
        <div className={classes.eeBox}>
          <div
            style={{ display: "flex", gap: "5px" }}
            onClick={prepareExcelExportData}
          >
            <DownloadIcon size={18} className={classes.downloadIcon} />
            <div className={classes.eeLabel}>{label}</div>
          </div>
          {isConfigurable && (
            <IconButton
              rippleStyle={{ minWidth: "auto" }}
              onClick={(event) => {
                setShowConfigPopup(!showConfigPopup);
              }}
              className={classes.eeConfigIco}
            >
              <Tooltip placement="bottom" tooltip="Configure Fields">
                <MoreVerticalIcon />
              </Tooltip>
            </IconButton>
          )}
        </div>
      </div>
      {showConfigPopup && (
        <Dialog isOpen={true} ariaHideApp={false}>
          <DialogTitle title={"Configure Fields"} />
          <DialogContent
            isTopDividerVisible={true}
            isBottomDividerVisible={true}
          >
            <div className={classes.eeFldConfigList}>
              <div className={classes.eePopMenuHeader}>
                <Checkbox
                  variant="default"
                  checked={isAllFieldChecked}
                  onChange={(event) => {
                    event.persist();
                    toggleCheckAll(event.target.checked);
                  }}
                />
                <CheckboxLabelContent>Check All</CheckboxLabelContent>
              </div>
              <ul data-selector="ee-list-fld--ul">
                {columnDefs.map((row) => (
                  <li key={`fld-chk-${row.field}`}>
                    <Tooltip placement="bottom" tooltip={`${row.header}`} >
                      <div style={{display:'flex'}}>
                      <Checkbox
                        variant="default"
                        checked={selectedFields.includes(row.field)}
                        onChange={() => handleFieldSelection(row.field)}
                      />
                      <CheckboxLabelContent>{row.header}</CheckboxLabelContent>
                        </div>
                    </Tooltip>
                  </li>
                ))}
              </ul>
            </div>
          </DialogContent>
          <DialogFooter>
            <Button
              onClick={() => {
                toggleCheckAll(true);
                setShowConfigPopup(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="call-to-action"
              onClick={applyExportFieldConfiguration}
            >
              Apply
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};

export default ExcelExport;
