import _ from "lodash";


export const findChild = (array: [], query: string | number, searchKey: string, inEachKey: string) => {
    let result;
    array.some(
        (child: any) =>
            (child[searchKey]?.toLowerCase().includes(query) && (result = child)) ||
            (result = findChild(child[inEachKey] || [], query, searchKey, inEachKey))
    );
    return result;
};

export const iterate = (instance, f) => {
    console.log('instance', instance)
    if (instance.hasOwnProperty('extensionData') && Object.keys(instance.extensionData).length > 0) {
        for (let child of instance.extensionData) {
            f(child);
            iterate(child, f)
        }
    }
}

export const generateSyncOfferItems = (type, arr) => {
    if (arr.length === 0) {
        return ""
    }
    switch (type) {
        case 'ndac':
            //Array of Products schema
            return arr.map((e) => new Object({ "product_code": e.product_id, "quantity": e.quantity }));
        case 'mpw':
            return [];
        case 'services':
            // Array of Services (Non-products schema)
            return arr.map((e) => new Object({ "item_code": `${e.category_code}-${e.product_name}`, "cost": e.cost, "price": e.cost }));
    }
}

export const getEmptyOOTfields = (oot_response) => {
    const res = Object.keys({ ...(_.pickBy(oot_response, _.isEmpty)), ...(_.pickBy(oot_response, { "default": "" })) })
    return res;
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
export function getTokenExpiryTime(token) {
    try {
        const decodedToken = parseJwt(token); // Decoding the token
        const expiryTime = decodedToken.exp * 1000; // Multiplying by 1000 to convert from seconds to milliseconds
        const currentTime = Date.now(); // Getting the current timestamp in milliseconds

        const timeRemaining = expiryTime - currentTime;
        if (timeRemaining < 0) {
            return -1;
        } else {
            const seconds = Math.floor((timeRemaining / 1000) % 60);
            const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
            const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
            const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

            let timeString = "";
            if (days > 0) {
                timeString += days + "d ";
            }
            if (hours > 0) {
                timeString += hours + "h ";
            }
            if (minutes > 0) {
                timeString += minutes + "m ";
            }
            if (seconds > 0) {
                timeString += seconds + "s";
            }

            // if (minutes === onTimer.time) {
            //   onTimer.exec()
            // }

            return timeString.trim();
        }
    } catch (error) {
        console.error("Invalid token");
        return null;
    }
}

export function hiddenLogin(src) {
    const doLogin = new Promise((rs, rj) => {
        // Step 1: Create an iframe dynamically or include it in your HTML
        const iframe = document.createElement("iframe");

        // Step 2: Set the display style property to "none" to hide the iframe
        iframe.style.display = "none";

        // Step 3: Set the src attribute of the iframe
        iframe.src = src; // Replace with your desired URL

        // Step 4: Listen for the "load" event on the iframe
        iframe.addEventListener("load", function () {
            // Step 5: Retrieve the URL of the iframe
            const iframeURL = iframe.contentWindow.location.href;
            // console.log("URL of the hidden iframe:", iframeURL);
            const queryString = iframeURL;
            const urlParams = new URLSearchParams(queryString.split('#')[1]);
            if (urlParams.get('id_token')) {
                rs({
                    response_code: 1,
                    url: iframeURL,
                    id_token: urlParams.get('id_token'),
                    access_token: urlParams.get('access_token'),
                })
            } else {
                rs({
                    response_code: 0,
                    error: urlParams.get('error'),
                    error_description: urlParams.get('error_description')
                });
            }

        });

        // Append the iframe to the document body or any desired container
        document.body.appendChild(iframe);
    })
    return doLogin;

}
export const mustache = (string, data = {}) => Object.entries(data).reduce((res, [key, value]) => res.replace(new RegExp(`{{\\s*${key}\\s*}}`, "g"), value), string);
 
export function unmustache(text) {
    let regex = /[^\W_]+|{{(.*?)}}|\d+/g;
  
    let results = regex.exec(text);
  
    let array = [];
  
    while (results !== null) {
      if (results[1]) {
        array.push(results[1]);
      } else {
        array.push(results[0]);
      }
      results = regex.exec(text);
    }
  
    return array.filter((e)=>!Number.isInteger(parseInt(e)));
  }
export const roundCount = (number: string | number, range = 2) => Number(parseFloat(Number(number).toFixed(range)).toFixed(range));

export const occurrencesOf = (number, numbers: []): number => numbers.reduce((counter, currentNumber) => (number === currentNumber ? counter + 1 : counter), 0);

export const serializeFunction = (func) => func.toString();
export const deserializeFunction = (funcString) => (new Function(`return ${funcString}`)());

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).replace(/[^a-zA-Z ]/g, " ");


export const updateExtensionProduct = (updatedCartItems, product, method = "+") => {
   
    if (product.hasOwnProperty('extensionData')) {
        const extensionItems = product.extensionData;
       
        extensionItems.forEach(extensionItem => {
            const existingExtensionIndex = _.findIndex(updatedCartItems, {
                product_id: extensionItem.product_id,
                cui_type: "extension"
            });
            
            if (existingExtensionIndex !== -1) {
                if (method === "-") {
                    if (product.quantity === 0 || product.quantity < extensionItem.quantity) {
                        updatedCartItems.splice(existingExtensionIndex, 1);
                    } else {
                        const updatedExtensionItem = {
                            ...extensionItem,
                            quantity: updatedCartItems[existingExtensionIndex].quantity - extensionItem.quantity,
                            cost: updatedCartItems[existingExtensionIndex].quantity * roundCount(extensionItem.list_price),
                            cui_type: "extension"
                        };
                        updatedCartItems[existingExtensionIndex] = updatedExtensionItem;
                    }
                } else {
                    const updatedExtensionItem = {
                        ...extensionItem,
                        quantity: updatedCartItems[existingExtensionIndex].quantity + extensionItem.quantity,
                        cost: updatedCartItems[existingExtensionIndex].quantity * roundCount(extensionItem.list_price),
                        cui_type: "extension"
                    };
                    updatedCartItems[existingExtensionIndex] = updatedExtensionItem;
                }
            } else {
                if (method === "+" && product.quantity > 0) {
                    updatedCartItems.push({
                        ...extensionItem,
                        quantity: extensionItem.quantity,
                        cost: extensionItem.quantity * roundCount(extensionItem.list_price),
                        cui_type: "extension"
                    });
                }
                console.log('lpg-ext-1',updatedCartItems,method,product)
            }
        });

    }

};
export const updateExistingProduct = (cartItems, product, method = "+") => {
    const updatedCartItems = [...cartItems];
    const existingProductIndex = updatedCartItems.findIndex(
        prod => prod.product_id === product.product_id && prod.type === product.type
    );

    if (existingProductIndex !== -1) {
        const existingProduct = updatedCartItems[existingProductIndex];
        const typeGroupProducts = updatedCartItems.filter(
            prod => prod.product_id === existingProduct.product_id && prod.type !== existingProduct.type
        );

        const updatedTypeGroup = [existingProduct, ...typeGroupProducts].map(prod => ({
            ...prod,
            quantity: method === "-" ? prod.quantity - product.quantity : prod.quantity + product.quantity,
            cost: (method === "-" ? prod.quantity - product.quantity : prod.quantity + product.quantity) * roundCount(prod.list_price),
        }));

        if (method === "-" && updatedTypeGroup.some(prod => prod.quantity <= 0)) {
            updatedCartItems.splice(existingProductIndex, 1);
        } else {
            updatedTypeGroup.forEach(updatedProduct => {
                const index = updatedCartItems.findIndex(
                    prod => prod.product_id === updatedProduct.product_id && prod.type === updatedProduct.type
                );
                updatedCartItems[index] = updatedProduct;
            });
        }

        // Update extension products
        if (product.hasOwnProperty('extensionData')) {
            updateExtensionProduct(updatedCartItems, product, method);
        }

        return updatedCartItems.filter((e) => e.quantity !== 0);
    } else {
        const quantity = product.hasOwnProperty('quantity') ? product.quantity : 1;
        const newProduct = {
            ...product,
            quantity,
            cost: quantity * roundCount(product.list_price),
        };
        updatedCartItems.push(newProduct);

        // Update extension products
        if (product.hasOwnProperty('extensionData')) {
            updateExtensionProduct(updatedCartItems, newProduct, method);
        }
        console.log('lpg',updatedCartItems)
        return updatedCartItems.filter((e) => e.quantity !== 0);
    }
}


export const extractBand = (title: string) => {
    const productName = title;
    const bandsRegex = /[NB]\d+|\d+[ab]/gi;
    const bands = productName.match(bandsRegex);
    if (bands) {
        console.log("Bands found:", bands);
        return bands[0];

    } else {
        console.log("No bands found.");
        return false;
    }
}





