import React from "react";
import UsersList from "./user-management/UsersList";
import Card from '@nokia-csf-uxr/ccfk/Card';
import TabsSection from '../marketplace/common/TabsSection';
import AdminFilter from "./AdminFilter";


const customTabData = [
    { tab: { children: 'User Management', label: 'Tab One description' } },
  ];

  const tabContentComponents = [
    <UsersList key={0} />,
    // <ExpiredQuotations key={1}/>
  ];
const AdminBody = () => {
  return (
    <>
     <AdminFilter/>
     <Card style={{padding:'1rem'}}>
      <TabsSection tabData={customTabData} tabContentComponents={tabContentComponents} />
      </Card>
    
    </>
  )
}

export default AdminBody;