import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from 'Store/Actions/ts';
import _ from "lodash";
import setObj from "lodash/set";
const generalOptions = {
    subscription_duration: 0,
    subscription_plan: "",
    warranty: [12],
}
const initialData = {
    view: {},
    options: {
        PTDAC: {
            ...generalOptions
        }
    }
};
const warrantySlice = createSlice({
    initialState: {
        data: initialData,
        status: STATUS.IDLE,
    },
    name: 'warranty',
    reducers: {
        setWarranty(state, action) {
            let items = new Set(state.data.options.PTDAC.warranty);
            //@recommended_Aps : {"method":"",period:""}
            if (action.payload.method === "add") {
                items.add(action.payload.period);
            }
            if (action.payload.method === "remove") {
                items.delete(action.payload.period)

            }
            state.data.options.PTDAC.warranty = _.compact(Array.from(items));
        }
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === warrantySlice.name) {
                setObj(state.data, action.payload.key, action.payload.value);
            }
        })
    }
});

export default warrantySlice.reducer;
export const { setWarranty } = warrantySlice.actions;