// import { PTDAC } from "./modules/PTDAC";
import { PTDAC } from "Components/Logics/stateMachine/modules/PTDAC/index";
/*
State Machine consists of two steps,
1. get the redux state 
2. Check the state cross with the logic defines inside rules.
*/
class StateMachine extends PTDAC {
    constructor(store) {
        super(store);
    }

};

export default StateMachine;