import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubscriptionDataGrid from "./SubscriptionDataGrid";
import SubscriptionFilter from "./SubscriptionFilter";
import classes from "./Style/Subscription.module.css";
import SubscriptionInfo from "./SubscriptionInfo";
import {
  setAppToolbarTitle,
  setStatusMessage,
} from "Store/contentState/subscriptionSlice";

import StatusMessage from "./Custom/StatusMessage";

import { RootState } from "Store/mainStore";
import SubscriptionUpdation from "./SubscriptionUpdation";
import WelcomePage from "./Components/WelcomePage";

const SubscriptionBody = () => {
  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppToolbarTitle("Active Subscriptions and yearly licenses"));
    dispatch(setStatusMessage({ type: "", message: "" }));
  }, [dispatch]);

  return (
    <div className={classes.AppBodyContent}>
      <div
        className={`${classes.contentWrapper} ${classes.card}`}
      >
        <StatusMessage />
        <SubscriptionFilter />
        {subscriptionSlice?.filters?.selection?.network.trim().length === 0 && (
          <WelcomePage />
        )}
        {subscriptionSlice?.filters?.selection?.network.trim().length > 0 && (
          <>
            <SubscriptionInfo />
            <div className={classes.dgWrapper}>
              {subscriptionSlice?.subscriptionData?.data && (
                <SubscriptionDataGrid />
              )}
            </div>
          </>
        )}
      </div>
      <aside className={`${classes.rightBoxWrapper} ${classes.card} ${classes.subAside}`}>
        {subscriptionSlice?.filters?.selection?.network.trim().length > 0 && <SubscriptionUpdation />}
      </aside>
      {/* --- Aside ---*/}
    </div>
  );
};
export default SubscriptionBody;
