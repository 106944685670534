import { useMsal } from "@azure/msal-react";
import { Button } from "@nokia-csf-uxr/ccfk";
import React, { useEffect, useRef } from "react";
import { loginRequest } from "../authConfig";
// import { useIsAuthenticated } from "@azure/msal-react";
// import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";

// import { faEnvelope, } from '@fortawesome/free-regular-svg-icons'


/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    // const { instance, accounts } = useMsal();
    // const isAuthenticated = useIsAuthenticated();
    const loginButton = useRef(null);

    useEffect(() => {
        loginButton.current.click();
        return ()=>{}
      }, []);
    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
           
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).then((e) => {
             
            }).catch(e => {

                
            })
        }
    }
    return (
        <>
        <Button  className="text-white"
        ref={loginButton}
        variant={"call-to-action"} 
        onClick={() => handleLogin("redirect")} 
        fullWidth={true}>
            <i className="fa fa-windows"></i>  LOGIN WITH  AD ACCOUNT
        </Button> </>
     
    )
}