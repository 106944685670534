import React, { useState, useRef, useEffect } from 'react';
import _findIndex from 'lodash/findIndex';
// FF icon
import ChevronDownSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/ChevronDownSmallIcon';
import CloseSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseSmallIcon';

import Label from '@nokia-csf-uxr/ccfk/Label';
import Checkbox, { CheckboxLabelContent } from '@nokia-csf-uxr/ccfk/Checkbox';
import PropTypes from "prop-types";

import SelectItem, {
  SelectItemLabelContent,
  SelectListItem,
  SelectItemText,
  SelectItemButton,
  SelectItemClearButton,
} from '@nokia-csf-uxr/ccfk/SelectItem';

import Chip, {
  ChipLabel,
  ChipIconButton
} from '@nokia-csf-uxr/ccfk/Chip';

const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);
const DELETE = 'Delete';
const BACKSPACE = 'Backspace';
const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';


const PLACEHOLDER = 'Select';

const actionData = [
  'view',
];

/** Example of non-searchable SelectItem with mutiple selections allowed with a dropdown list with avatars. */
const DataElementList = ({ elementId, handleDataElementSelection, selectedValues, actions }) => {
  const selectItemRef = useRef(null);
  const data = (actions && actions['values'] && actions['values'].length > 0) ? actions['values'] : actionData;
  const selectItemButtonRef = useRef(null);
  const [values, setValues] = useState(selectedValues || []);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setValues(selectedValues || []);
  }, [selectedValues]);

  const ariaString = () => values.join(', ');
  const haveValues = () => values.length > 0;

  const handleEvent = (index) => (event) => {
    const { type } = event;
    const theValue = data[index];
    const isValueAlreadySelected = _findIndex(values, item => item === theValue) > -1;

    if (type === 'keydown' || type === 'click') {
      if (!isValueAlreadySelected) {
        const newValues = [...values, theValue];
        setValues(newValues);
        handleDataElementSelection(elementId, newValues);
      }
      setIsOpen(false);
    }
  };

  const handleChipClose = (value) => () => {
    const newValues = values.filter(item => item !== value);
    setValues(newValues);
    handleDataElementSelection(elementId, newValues);
    selectItemButtonRef.current && selectItemButtonRef.current.focus();
  };

  const renderClearButton = (props) => (
    <SelectItemClearButton
      aria-label="clear input"
      onClick={() => { setValues([]); handleDataElementSelection(elementId, []); selectItemButtonRef.current && selectItemButtonRef.current.focus(); }}
      {...props}
    >
      <CloseSmallIcon />
    </SelectItemClearButton>
  );

  const renderSelectItemBase = (props) => {
    const showClearButton = haveValues() && isOpen;
    return (
      <SelectItemButton
        ref={selectItemButtonRef}
        placeholder={PLACEHOLDER}
        dropdownIcon={<ChevronDownSmallIcon />}
        inputProps={{ value: haveValues() ? ariaString() : PLACEHOLDER }}
        renderClearButton={showClearButton ? renderClearButton : undefined}
        role="combobox"
        {...props}
      >
        {haveValues() && values.map((item, x) => (
          <Chip
            key={`chip-${item}-${x}`}
            tabIndex={0}
            role="comment"
            size="small"
            aria-label={item}
            style={{ margin: '0.1875rem 0.125rem 0.125rem 0.125rem' }}
            onClick={(event) => { event.stopPropagation(); }}
            onKeyDown={(event) => {
              if (event.key === 'Backspace' || event.key === 'Delete') {
                handleChipClose(item)();
              }
            }}
          >
            <ChipLabel label={item} />
            <ChipIconButton aria-label={`remove ${item}`} onClick={handleChipClose(item)}>
              <CloseSmallIcon />
            </ChipIconButton>
          </Chip>
        ))}
      </SelectItemButton>
    );
  };

  return (
    <SelectItem
      ref={selectItemRef}
      aria-labelledby="selectitem-component-label"
      aria-label={haveValues() ? ariaString() : PLACEHOLDER}
      multiSelect
      isOpen={isOpen}
      onOpen={() => { setIsOpen(true); }}
      onClose={() => { setIsOpen(false); }}
      truncateListText
      listProps={{ ulProps: { role: 'listbox' } }}
      renderSelectItemBase={renderSelectItemBase}
    >
      {data.map((item, x) => (
        <SelectListItem
          role="option"
          key={`${item}-${x}`}
          selected={_findIndex(values, value => value === item) > -1}
          onClick={handleEvent(x)}
          onKeyDown={handleEvent(x)}
          aria-label={item}
          style={{ height: '3rem', minHeight: '3rem', maxHeight: '3rem' }}
        >
          <SelectItemText style={{ paddingLeft: '1rem' }}>{item}</SelectItemText>
        </SelectListItem>
      ))}
    </SelectItem>
  );
};

DataElementList.propTypes = {
  elementId: PropTypes.string.isRequired,
  handleDataElementSelection: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.object
};

export default DataElementList;









