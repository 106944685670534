import React from "react";
import { Tooltip, Typography } from "@nokia-csf-uxr/ccfk";
import InfoCircleFillIcon from "@nokia-csf-uxr/ccfk-assets/latest/InfoCircleFillIcon";
import classes from "../Style/Subscription.module.css";
import SelectBox from "../Custom/SelectBox";

interface subscriptionRenewalType {
  renewalPeriod: string;
  isRenewalDisabled: boolean;
  onRenewalChange: (string) => void;
  invoicingFrequency: string;
  invoicingFrequencyList: string[];
  isInvoicingFrequencyDisabled: boolean;
  onInvoicingFrequencyChange: (string) => void;
}

// Prepare renewal duration
const renewalDuration = [];
for (let i = 12; i <= 36; i += 12) {
  renewalDuration.push(i);
}

const SubscriptionRenewal = ({
  renewalPeriod,
  isRenewalDisabled,
  onRenewalChange,
  invoicingFrequency,
  invoicingFrequencyList,
  isInvoicingFrequencyDisabled,
  onInvoicingFrequencyChange,
}: subscriptionRenewalType) => {
  return (
    <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0 5px",
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
        }}
      >
        <Tooltip
          tooltip={
            <div
              style={{
                width: "300px",
              }}
            >
              <Typography typography="TITLE_16">
                Renewal Subscription
              </Typography>
              <Typography>
                The Renewal Subscription will continue for the number of
                months selected below unless Nokia is notified in writing at
                least thirty (30) days prior the expiry date of the intention
                not to renew.
              </Typography>
            </div>
          }
          trigger={["hover", "focus"]}
          placement="top"
        >
          <InfoCircleFillIcon size={20} style={{ cursor: "pointer" }} />
        </Tooltip>

        <Typography
          style={{
            font: "var(--legacy-typography-label)",
            padding: "0 5px",
          }}
        >
          Renewal Subscription
        </Typography>
      </div>

      <div className={classes.selection}>
        <SelectBox
          labelText=""
          placeholder="Renewal Subscription"
          boxWidth={"90%"}
          caseInsentiveMatching={true}
          selectedItem={renewalPeriod}
          data={renewalDuration.map((mn) => ({
            id: String(mn),
            value: `${mn} Months`,
          }))}
          disabled={isRenewalDisabled}
          onUpdateSelection={onRenewalChange}
          error={!renewalPeriod}
          //errorMessage={"This field is required"}
          isClearable={false}
        />
      </div>
    </div>
    <div
      style={{
        display: "flex",        
        flexDirection: "column",
        justifyContent: 'center',
        gap: "0 5px",
        minHeight: '45px'
      }}
    >
      <Typography
        style={{
          font: "var(--legacy-typography-label)",
          marginBottom: "5px",
        }}
      >
        Frequency of invoicing subscription
      </Typography>

      <SelectBox
        placeholder="Frequency of invoicing subscription"
        //maxWidth={true}
        boxWidth={"90%"}
        caseInsentiveMatching={true}
        selectedItem={invoicingFrequency}
        data={invoicingFrequencyList}
        disabled={isInvoicingFrequencyDisabled}
        onUpdateSelection={onInvoicingFrequencyChange}
        error={!invoicingFrequency}
        // errorMessage={"This field is required"}
        isClearable={false}
        // isLoading={}
      />
    </div>
  </>
  );
};
export default SubscriptionRenewal;
