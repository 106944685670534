import React, { useState } from "react";
import cartIcon from '../../../Assets/Icons/marketplace_cart_icon.svg';
import saveIcon from '../../../Assets/Icons/marketplace_save_item.svg';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import SettingsIcon from '@nokia-csf-uxr/ccfk-assets/latest/SettingsIcon';
import refreshIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/refresh-alt.svg';
import ToggleButton from '@nokia-csf-uxr/ccfk/ToggleButton';
import { setProp } from "Store/Actions";
import { resetMarketplaceFilters , clearCart} from "Store/contentState/marketplaceSlice";
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@nokia-csf-uxr/ccfk";

const FilterButtons = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(false);
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const cartLength = marketplaceSelector.cart.length;         //this will be changed in new flow


    const resetFilters = () => {
        dispatch(setProp({ slice: 'marketplace', key: 'isGetProducts', value: false }));
        dispatch(clearCart());
        dispatch(resetMarketplaceFilters());
        dispatch(resetPaginationsProps())
    }
    
    return (
        <>
            {marketplaceSelector.isGetProducts && <React.Fragment>
                <div style={{position:'relative', marginRight:'10px'}}><span style={{
                    backgroundColor: '#ACB8F5', position: 'absolute', bottom: '23px', textAlign: 'center', color: '#000',
                    width: '22px', height: '20px', border: '1px solid #fff', borderRadius: '20px', fontSize: '12px', left:'7px'
                }}>{cartLength}</span>
                <ToggleButton
                    aria-label="settings"
                    onClick={() => navigate('/marketplace/cart')}
                    size="large"
                    active={isActive}
                >
                    <img src={cartIcon} />
                </ToggleButton>
                </div>
                <Button style={{ display: 'none' }}> <ButtonIcon>
                    <img src={saveIcon} />
                </ButtonIcon></Button>

                {/* <Button onClick={() => dispatch(resetEntireMarketplace())}> <ButtonIcon>
                    <img src={refreshIcon} />
                </ButtonIcon></Button> */}

               {!location.pathname.includes('product') && 
               <>
               <Tooltip tooltip="Reset" placement="bottom">
               <ToggleButton
                    aria-label="settings"
                    onClick={resetFilters}
                    size="large"
                    active={isActive}
                >
                    <img src={refreshIcon} />
                </ToggleButton>
                </Tooltip>
                </>
                }
            </React.Fragment>}
        </>
    )
}

export default FilterButtons;