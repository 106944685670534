import { useSelector, useDispatch } from "react-redux";
import {
  setLoader,
  setCancelType,
  setAccountDropdown,
  setSubscriptionData,
  setSubscriptionFilterSelection,
  setNetworkList,
  setCustomerList,
  setSubscriptionState,
  setSubInfo,
  // setSubscriptionCancellation,
  // setStatusMessage,
  resetSubscriptionCancellation,
  setSubscriptionCancelMode,
} from "Store/contentState/subscriptionSlice";
/* import {
  ,
  setAccountDropdown,
  setSubscriptionState,
  setPartnerList,
  setCustomerList,
  setSubInfo,
  setSubscriptionData,
} from "Store/contentState/subscriptionSlice"; */

import {
  getCustometList,
  getNetworktList,
  getSubscriptiontList,
} from "../utility/utils";
import { RootState } from "Store/mainStore";
import OOT from "Constants/services/oot";

export const useSubscriptionFilter = () => {
  const dispatch = useDispatch();
  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );

  const resetSubscription = () => {
    // Subscription
    const __subscriptionInfo = {
      delegates: "",
      subscriptionInfo: {
        sliceId: "",
        sla: "",
        status: "",
        customerSpecification: "",
        autoSubscriptionRenewalEnabled:true,
      },
      invoicing_frequency:"",
      renewal_duration: "",
      status: "",
    };
    dispatch(setSubscriptionState({ key: "isNetworkCancellable", data: false }));
    dispatch(
      setSubInfo({
        subInfo: __subscriptionInfo,
      })
    );
    // Cancellation
    dispatch(setCancelType({ cancelType: null }));
    dispatch(
      resetSubscriptionCancellation({
        key: "all",
        type: "object",
      })
    );
    dispatch(setSubscriptionCancelMode("AcountOverview"));
  };

  const resetCancellationState = () => {
    const currentMode =
      subscriptionSlice?.cancellation?.mode ?? "AcountOverview";
    dispatch(setCancelType({ cancelType: null }));
    dispatch(
      resetSubscriptionCancellation({
        key: "all",
        type: "object",
      })
    );
    dispatch(setSubscriptionCancelMode(currentMode));
  };

  const setPartnerState = (partner) => {
    const networkData = subscriptionSlice.accountDropdown;
    resetSubscription();
    let __customerList = [];
    dispatch(setSubscriptionData({ subscriptionData: {} }));

    dispatch(
      setSubscriptionFilterSelection({
        partner: partner,
        customer: "",
        network: "",
      })
    );
    dispatch(setCancelType({ cancelType: null }));
    dispatch(setNetworkList({ networkList: [] }));
    if (partner.trim().length > 0) {
      __customerList = getCustometList(partner, networkData);
    } else {
      dispatch(
        setNetworkList({
          networkList: [],
        })
      );
    }
    dispatch(
      setCustomerList({
        customerList: __customerList,
      })
    );
  };

  const setCustomerState = (customer,customerList=[]) => {
    dispatch(
      setSubscriptionFilterSelection({ customer: customer, network: "" })
    );
    resetCancellationState();
    if (customer.trim().length > 0) {
      let __customerList = (customerList.length===0) ? subscriptionSlice.customerList:customerList;
      // Handle the redux state dispatch delay here
      if(__customerList.length === 0){
        const __partner = subscriptionSlice?.filters?.selection?.partner;
        const __accountDropdown = subscriptionSlice.accountDropdown;
        __customerList = getCustometList(__partner, __accountDropdown);
      }
      
      const __networks = getNetworktList(
        customer,
        __customerList
      );

      dispatch(setSubscriptionState({ key: "networkList", data: __networks }));
      // Pre-select network for single network in the list
      if(__networks.length === 1){
        setNetworkState(__networks[0].id);
      }
    } else {
      dispatch(
        setNetworkList({
          networkList: [],
        })
      );
    }
  };

  const setNetworkState = (__network) => {
    resetCancellationState();
    dispatch(setSubscriptionFilterSelection({ network: __network }));
    if (__network.trim().length > 0) {
      // dispatchSelectedNetwork(__network);
      const __networksSubscription = getSubscriptiontList(
        __network,
        subscriptionSlice.networkList
      );

      const selectedNetwork = subscriptionSlice?.networkList?.filter(
        (nLr) => nLr.id === __network
      );

      const delegatesList =
        selectedNetwork[0]?.network_delegate &&
        selectedNetwork[0]?.network_delegate.trim().length > 0
          ? selectedNetwork[0]?.network_delegate.split(",")
          : [];
      const __subscriptionInfo = {
        delegates: delegatesList,
        subscriptionInfo: {
          sliceId: selectedNetwork[0]?.slice_id ?? "",
          sla: selectedNetwork[0]?.sla,
          status: selectedNetwork[0]?.network_status ?? "",
          customerSpecification: selectedNetwork[0]?.customer_network_specification ?? "",
          autoSubscriptionRenewalEnabled: selectedNetwork && selectedNetwork[0] && selectedNetwork[0].autoSubscriptionRenewalEnabled !== undefined && selectedNetwork[0].autoSubscriptionRenewalEnabled.toString().length !== 0 ? selectedNetwork[0].autoSubscriptionRenewalEnabled : true,
        },
        renewal_duration: selectedNetwork[0]?.renewal_duration ?? "",
        invoicing_frequency:selectedNetwork[0]?.invoicing_frequency ?? "",
        status: selectedNetwork[0]?.network_status ?? "",
      };
      dispatch(setSubscriptionState({ key: "isNetworkCancellable", data: selectedNetwork[0]?.cancel_whole_network ?? true }));
      dispatch(
        setSubInfo({
          subInfo: __subscriptionInfo,
        })
      );
      dispatch(
        setSubscriptionData({
          subscriptionData: {
            data: __networksSubscription,
            contractList: selectedNetwork[0]?.constract_list,
          },
        })
      );
    }
  };

  const fetchSubscriptionNetworkData = async (type, __partner) => {
    const partner =
      type === "fetch"
        ? __partner
        : subscriptionSlice?.filters?.selection?.partner;
    const customerList =
      type === "fetch" ? [] : subscriptionSlice?.customerList;
    setPartnerState(partner);
    dispatch(setCustomerList({ customerList: customerList }));
    if (partner.trim().length > 0) {
      dispatch(
        setLoader({
          key: "isCustomerLoading",
          value: true,
        })
      );
      const response = await OOT.getSubscriptionNetworkData(partner);
      const __accountDropdown = response.data.accountData
        ? response.data.accountData
        : [];
      dispatch(
        setLoader({
          key: "isCustomerLoading",
          value: false,
        })
      );
      updateSubscriptionState(type,partner, __accountDropdown);
    }
  };

  const updateSubscriptionState = (type,__partner, __accountDropdown) => {
    dispatch(
      setAccountDropdown({
        accountDropdown: __accountDropdown,
      })
    );

    // reset customer list
    const __customerList = getCustometList(__partner, __accountDropdown);
    dispatch(
      setCustomerList({
        customerList: __customerList,
      })
    );
    
    dispatch(setSubscriptionState({ key: "networkList", data: [] }));

    // Apply logic for selected partner , customer and network
    if (type==="reload"){
      const { partner, customer, network } = subscriptionSlice?.filters?.selection;
      if (partner !== "" && customer !== "" && network !== "") {
        preSelectOptions(customer, network,__customerList)
      }
    }
    // Handle single item in the customer list
    if(type==="fetch" && __customerList.length === 1){
      const __networkList = getNetworktList(__customerList[0].id, __customerList);
      dispatch(
        setSubscriptionState({ key: "networkList", data: __networkList })
      );
      preSelectOptions(__customerList[0].id, __networkList[0].id,__customerList)
    }
    
  };

  const preSelectOptions = (customer, network,__customerList) => {
    // Reset Network list
    const __networkList = getNetworktList(customer, __customerList);
    dispatch(
      setSubscriptionState({ key: "networkList", data: __networkList })
    );
    setCustomerState(customer,__customerList);
    setNetworkState(network);

    const __networksSubscription = getSubscriptiontList(
      network,
      __networkList
    );
    const selectedNetwork = __networkList.filter((nLr) => nLr.id === network);

    const delegatesList =
      selectedNetwork[0]?.network_delegate &&
      selectedNetwork[0]?.network_delegate.trim().length > 0
        ? selectedNetwork[0]?.network_delegate.split(",")
        : [];
    const __subscriptionInfo = {
      delegates: delegatesList,
      subscriptionInfo: {
        sliceId: selectedNetwork[0]?.slice_id,
        sla: selectedNetwork[0]?.sla,
        status: selectedNetwork[0]?.network_status,
        customerSpecification: selectedNetwork[0]?.customer_network_specification,
        autoSubscriptionRenewalEnabled:selectedNetwork[0].autoSubscriptionRenewalEnabled === false ? false : true,
      },
      renewal_duration: selectedNetwork[0]?.renewal_duration,
      invoicing_frequency: selectedNetwork[0]?.invoicing_frequency,
      status: selectedNetwork[0]?.network_status,
    };

    dispatch(setSubscriptionState({ key: "isNetworkCancellable", data: selectedNetwork[0]?.cancel_whole_network ?? true }));
    dispatch(
      setSubInfo({
        subInfo: __subscriptionInfo,
      })
    );

    dispatch(
      setSubscriptionData({
        subscriptionData: {
          data: __networksSubscription,
          contractList: selectedNetwork[0]?.constract_list,
        },
      })
    );
  }

  return {
    resetSubscription,
    setPartnerState,
    setCustomerState,
    setNetworkState,
    resetCancellationState,
    fetchSubscriptionNetworkData,
  };
};
