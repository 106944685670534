import React from "react";
import CartItemLeftContainer from "./CartItemLeftContainer";
import CartItemRightContainer from "./CartItemRightContainer";
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { useSelector, useDispatch } from "react-redux";
import { removeProductFromCart } from "Store/contentState/marketplaceSlice";
import { RootState } from "Store/mainStore";
import classes from '../../marketplace/Style/Cart.module.css';
import Card from '@nokia-csf-uxr/ccfk/Card';
import CartImageContainer from "./CartImageContainer";
import CartItemActionButtons from "./CartItemActionButtons";


const CartListItem = (prop) => {
  return (
    <>
     <div style={{height:'auto'}}>
     <div className={classes.cartItems}>
     <Card className={classes.cartListItem}>
     <div className={classes.productCard}>
       <CartImageContainer listItem={prop.cartItem}/>
       <div className={classes.verticalDivider}></div>
       <div className={classes.productDetails}>
        <CartItemLeftContainer listItem={prop.cartItem}/>
       </div>
       <div className={classes.productPriceQuantity}>
         <CartItemRightContainer listItem={prop.cartItem}/>
       </div>
      </div>
      </Card>
      </div>
     </div>
    </>
  )
}

export default CartListItem;