import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Link, Font } from '@react-pdf/renderer';
import pdfLogo from '../../Assets/Icons/logo.png';
import PropTypes from "prop-types";
import PriceDisplay from './UIElements/PriceDisplay';


// Define styles
Font.register({
    family: 'Roboto',
    fonts: [
        { src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxP.ttf', fontWeight: 'normal' },
        { src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', fontWeight: 'bold' },
        { src: 'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxIIzc.ttf', fontWeight: 900 },
    ]
});


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        padding: 30
        // fontFamily: "Nokia Pure Text"
    },
    header: {
        backgroundColor: '#005AFF',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: '#FFF',
        padding: 10,
    },
    headerText: {
        textAlign: 'justify',
        fontWeight: 'bold',
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: 'Roboto'
    },
    section: {
        margin: 10,
        padding: 10,
    },
    noBreak: {
        marginBottom: 10,
        // padding: 10,
        breakInside: 'avoid',
    },
    logo: {
        width: 100,
        height: 20,
    },
    table: {
        flexDirection: 'column',
        width: "100%",
        flexGrow: 1,
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderRightWidth: 0,
        // borderBottomWidth: 0
        border: "1px solid #E6E9EB",
        // padding: 10
        marginBottom: 10
    },

    tableHeader: {
        backgroundColor: '#E4F0FF',
        flexDirection: 'row',
        color: '#000',
        padding: 10,
        fontSize: 12,
        fontWeight: 'heavy',
        width: '100%'
    },
    tableRow: {
        flexDirection: 'row',
        // padding: 5
        // marginTop: 5,
    },
    tableColHeader: {
        // backgroundColor: '#E4F0FF',
        flexDirection: 'row',
        color: '#000',
        // padding: 5,
        width: '100%',
        // border: "1px solid #E6E9EB",
    },
    tableCellRight: {
        flex: 1,
        textAlign: 'right',
        fontSize: 8,
        fontWeight: 400,
        padding: 5,
        border: "1px solid #E6E9EB",
        verticalAlign: 'sub',
        color: '#000',

    },
    tableCellHeadingLeft: {
        flex: 1,
        textAlign: 'left',
        fontSize: 8,
        fontWeight: 700,
        border: "1px solid #E6E9EB",
        padding: 5,
        verticalAlign: 'sub',
        color: '#000',
    },
    tableCellHeadingRight: {
        flex: 1,
        textAlign: 'right',
        fontSize: 8,
        fontWeight: 700,
        border: "1px solid #E6E9EB",
        padding: 5,
        verticalAlign: 'sub',
        color: '#000',
    },
    tableCellLeft: {
        flex: 1,
        textAlign: 'left',
        fontSize: 8,
        fontWeight: 400,
        padding: 5,
        border: "1px solid #E6E9EB",
        verticalAlign: 'sub',
        color: '#000',

    },


    boldAccount: {
        fontWeight: 'bold',
        // padding: 10,
        marginTop: 15,
        marginBottom: 15
    },
    borderBottom: {
        // marginTop: 8,
        marginBottom: 8,
        borderBottom: '1px solid #C7CDD6'
    },
    content: {
        padding: 10,
        backgroundColor: '#E4F0FF',
        fontSize: 14,
        marginBottom: 10
    },
    btRow: {
        flexDirection: "row",
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 5,
        marginBottom: 5
    },
    colSm2: {
        flexDirection: "column",
        width: '20%',
        fontWeight: 'bold',
        fontSize: 13
    },
    colSm10: {
        flexDirection: "column",
        width: '80%',
        fontWeight: 400,
        color: '#333333',
        fontSize: 12
    },
    costQuantity: {
        flexDirection: "row",
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15
    },
    costQuantityText: {
        color: '#757575',
        fontSize: 14,
        fontWeight: 'bold'
    },
    grandTotal: {
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold',

    },

    // tableCellLeft: {
    //     flex: 1,
    //     textAlign: 'left',
    //     fontSize: 10,
    //     fontWeight: 400,
    //     padding: 5,

    // },

    // tableCellRight: {
    //     flex: 1,
    //     textAlign: 'right',
    //     fontSize: 10,
    //     fontWeight: 400,
    //     padding: 5

    // },
    // tableCellHeadingLeft: {
    //     flex: 1,
    //     textAlign: 'left',
    //     fontSize: 10,
    //     fontWeight: 700,
    //     border: "1px solid #E6E9EB",
    //     padding: 5
    // },
    // tableCellHeadingRight: {
    //     flex: 1,
    //     textAlign: 'right',
    //     fontSize: 10,
    //     fontWeight: 700
    // },
    // tableRow: {
    //     flexDirection: "row",
    //     border: "1px solid #E6E9EB",
    //     fontSize: 8,
    // },
    // tableHeader: {
    //     backgroundColor: "#ECF4FF",
    //     fontWeight: 700,
    //     fontSize: 10,
    //     textAlign: "left",
    //     padding: 10
    // },
    // tableColHeader: {
    //     width: "100%",
    //     border: "1px solid #E6E9EB",
    //     backgroundColor: "#F9F9FA",
    //     textAlign: "left",
    //     fontWeight: 700,
    //     fontSize: 10
    // },
    // tableCol: {
    //     width: "100%",
    //     // borderStyle: "solid",
    //     // borderWidth: 1,
    //     // borderLeftWidth: 0,
    //     // borderTopWidth: 0,
    //     border: "1px solid #E6E9EB",
    //     padding: 5
    // },
    // tableCell: {
    //     margin: 5,
    //     fontSize: 8,
    //     padding: 5
    //     // fontWeight: 400
    // },
    subtotalRow: {
        flexDirection: "row",
        // borderTopWidth: 1,
        // borderTopColor: "#000",
        marginTop: 5,
        fontWeight: "bold",
        border: "1px solid #E6E9EB"
    },
    subtotalText: {
        textAlign: 'left',
        flex: 1,
        padding: 5,
        fontSize: 10,
        fontWeight: 700
        // borderStyle: 'solid',
        // borderWidth: 1,
        // borderLeftWidth: 0,
        // borderTopWidth: 0,
    },
    subtotalPriceText: {
        textAlign: 'right',
        flex: 1,
        padding: 5,
        fontSize: 10,
        fontWeight: 700
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        width: '100%',
        // padding: 20,
    },
    footerContent: {
        // flexDirection: 'row',
        // justifyContent: 'space-between',
        // position: 'absolute',
        // bottom: 0, // Place at the bottom
        // width: '100%', // Span the entire width
        // padding: 30,
        // fontSize:12,
        // color: 'grey',

        flexDirection: 'row',
        justifyContent: 'space-between', // Distribute content horizontally
        // Adjust padding as needed
        fontSize: 12,
        color: 'grey',
        // borderTop: '1px solid #C7CDD6',


    },

    termsConditionsText: {
        fontSize: 12,
        fontWeight: 400,
        color: '#333333'
    },
    termsConditionsLabel: {
        fontSize: 14,
        fontWeight: 700,
        color: '#333333'
    },
    termsConditionsSection: {
        marginTop: 10,
        marginBottom: 10
    }
});



const MyDocument = ({ data, accountDetails, cartResponse, owner, orderType, createdDate, billingAddress, shippingAddress }) => {
    let overallTotalQuantity = 0;
    const calculateTotalQuantity = (data) => {
        return data.reduce((total, item) => total + parseFloat(item.quantity), 0);
    };
    overallTotalQuantity = calculateTotalQuantity(data);
    const processData = (data) => {
        const categorizedData = {};


        data.forEach((item) => {
            const isAgentModel = item.is_agent_model;
            const productType = item.product_type_id;
            const category = isAgentModel === 0 ? 'Nokia' : 'Third party';

            if (!categorizedData[category]) {
                categorizedData[category] = {};
            }

            if (!categorizedData[category][productType]) {
                categorizedData[category][productType] = [];
            }

            categorizedData[category][productType].push(item);

            // const itemQuantity = parseFloat(item.quantity);

            // if (!categorizedData[category]) {
            //   categorizedData[category] = {};
            // }

            // if (!categorizedData[category][productType]) {
            //   categorizedData[category][productType] = {
            //     items: [],
            //     totalQuantity: 0,
            //   };
            // }

            // categorizedData[category][productType].items.push(item);
            // categorizedData[category][productType].totalQuantity += itemQuantity;
            // overallTotalQuantity += itemQuantity;
        });


        return categorizedData;
    };


    const processedData = processData(data);

    return (
        <Document>
            <Page style={styles.page} wrap>
                <View style={styles.header} fixed>
                    <Image
                        style={styles.logo}
                        src={pdfLogo}
                    />
                    <Text>{(orderType.generateQuote || orderType === 'Quotation_view') ? "Quotation" : "Order"} - DAC Business Hub</Text>
                </View>
                {/* <Text style={styles.boldAccount}>{accountDetails && accountDetails.name}</Text> */}
                <View style={styles.borderBottom}></View>

                <View style={styles.content}>
                    <View style={styles.btRow}>
                        <Text style={styles.colSm2}>{(orderType.generateQuote || orderType === 'Quotation_view') ? "Quotation" : "Order"} ID</Text>
                        <Text style={styles.colSm10}>{cartResponse && cartResponse.cartOfferId}</Text>
                    </View>
                    <View style={styles.btRow}>
                        <Text style={styles.colSm2}>Account</Text>
                        <Text style={styles.colSm10}>{accountDetails && accountDetails.name}</Text>
                    </View>
                    <View style={styles.btRow}>
                        <Text style={styles.colSm2}>End customer</Text>
                        <Text style={styles.colSm10}>{accountDetails && accountDetails['end_customer_name']}</Text>
                    </View>
                    <View style={styles.btRow}>
                        <Text style={styles.colSm2}>Owner</Text>
                        <Text style={styles.colSm10}>{owner && owner}</Text>
                    </View>
                    <View style={styles.btRow}>
                        <Text style={styles.colSm2}>Created date</Text>
                        <Text style={styles.colSm10}>{createdDate && createdDate}</Text>
                    </View>
                    {/* {orderType && orderType.generateQuote && <View style={styles.btRow}>
                        <Text style={styles.colSm2}>Expiry Date</Text>
                        <Text style={styles.colSm10}>{createdDate && createdDate}</Text>
                    </View>} */}
                </View>
                <View style={styles.content}>
                    <View style={styles.btRow}>
                        <Text style={styles.colSm2}>Billing address</Text>
                        <Text style={styles.colSm10}>{billingAddress && billingAddress.address.organization} {billingAddress && billingAddress.address.address_line1} {billingAddress && billingAddress.address.address_line2} {billingAddress && billingAddress.address.locality} {billingAddress && billingAddress.address.country}
                            {billingAddress && billingAddress.address.given_name} {billingAddress && billingAddress.address.family_name} {billingAddress && billingAddress.email_address} {billingAddress && billingAddress.phone_number}</Text>
                    </View>
                    <View style={styles.btRow}>
                        <Text style={styles.colSm2}>Shipping address</Text>
                        <Text style={styles.colSm10}>{shippingAddress && shippingAddress.address.organization} {shippingAddress && shippingAddress.address.address_line1} {shippingAddress && shippingAddress.address.address_line2} {shippingAddress && shippingAddress.address.locality} {shippingAddress && shippingAddress.address.country}
                            {shippingAddress && shippingAddress.address.given_name} {shippingAddress && shippingAddress.address.family_name} {shippingAddress && shippingAddress.email_address} {shippingAddress && shippingAddress.phone_number}
                        </Text>
                    </View>
                </View>
                <View>
                    {Object.keys(processedData).map((category) => (

                        <View key={category}>
                            {Object.keys(processedData[category]).map((productType) => (
                                <View style={styles.noBreak} key={`${category}-${productType}`}>
                                    <Text style={styles.tableHeader}>{`${category} - ${productType}`}</Text>


                                    <View style={styles.table}>
                                        {/* Table Header */}
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.tableCellHeadingLeft}>ID</Text>
                                            <Text style={styles.tableCellHeadingLeft}>Description</Text>
                                            <Text style={styles.tableCellHeadingRight}>List price</Text>
                                            <Text style={styles.tableCellHeadingRight}>List price after discount</Text>
                                            <Text style={styles.tableCellHeadingRight}>Customs & logistics</Text>
                                            <Text style={styles.tableCellHeadingRight}>Total qty</Text>
                                            <Text style={styles.tableCellHeadingRight}>Total</Text>
                                        </View>
                                        {/* Table Data */}
                                        {processedData[category][productType].map((item) => (
                                            <View style={styles.tableRow} key={item.id}>
                                                <Text style={styles.tableCellLeft}>{item.id}</Text>
                                                <Text style={styles.tableCellLeft}>{item.product_description}</Text>
                                                <Text style={styles.tableCellRight}><PriceDisplay price={item.list_price} /></Text>
                                                <Text style={styles.tableCellRight}>{<PriceDisplay price={item.market_price} />}</Text>
                                                <Text style={styles.tableCellRight}>{item.customs_and_logistics}</Text>
                                                <Text style={styles.tableCellRight}>{item.quantity}</Text>
                                                <Text style={styles.tableCellRight}>{<PriceDisplay price={item.total} />}</Text>
                                            </View>
                                        ))}
                                        {/* Subtotal */}
                                        <View style={styles.tableRow}>
                                            <Text style={[styles.subtotalText, { flex: 4 }]}>{productType} sub total</Text>
                                            <Text style={styles.subtotalPriceText}>
                                                {/* {processedData[category][productType].reduce((total, item) => total + parseFloat(item.total), 0).toFixed(2)} */}

                                                <PriceDisplay
                                                    price={processedData[category][productType]
                                                        .reduce((total, item) => total + parseFloat(item.total), 0)}
                                                />
                                            </Text>
                                            {/* <Text style={styles.subtotalText}>-</Text> */}
                                        </View>
                                    </View>


                                </View>


                            ))}
                        </View>

                    ))}
                </View>
                <View style={styles.costQuantity}>
                    <Text style={styles.costQuantityText}>Total quantity  <Text style={styles.grandTotal}>{overallTotalQuantity}</Text></Text>
                    <Text style={styles.costQuantityText}>Grant total: <Text style={styles.grandTotal}>{cartResponse && cartResponse.currency} {cartResponse && <PriceDisplay price={cartResponse.cartSummary.deal_final_price}/>} </Text></Text>
                </View>

                {/* <View style={styles.borderBottom}></View> */}
                <View style={styles.footer} fixed>
                    <View style={styles.footerContent}>
                        <Text render={({ pageNumber, totalPages }) => `${pageNumber} | ${totalPages}`} />
                        <Text style={{ flexDirection: 'row', justifyContent: 'flex-end' }}><Text style={{ color: "#005AFF" }}>Marketplace</Text> | Ω 2024   Nokia</Text>
                    </View>

                </View>
            </Page>

            {orderType && (orderType.orderNow || orderType === 'Order_view') && (<Page style={styles.page}>
                <View style={styles.header} fixed>
                    <Image
                        style={styles.logo}
                        src={pdfLogo}
                    />
                    <Text>Order - DAC Business Hub</Text>
                </View>

                <Text style={styles.boldAccount}>Terms and conditions</Text>
                <View style={styles.borderBottom}></View>

                <View style={styles.termsConditionsSection}>
                    <Text style={styles.termsConditionsText}>This Offer is subject to and conditional to Customer’s acceptance of Nokia’s standard terms and conditions and full execution of Nokia Digital Automation Cloud Agreement.</Text>
                </View>

                <View style={styles.termsConditionsSection}>
                    <Text style={styles.termsConditionsLabel}>Renewal subscription:</Text>
                    <Text style={styles.termsConditionsText}>12 months.</Text>
                </View>

                <View style={styles.termsConditionsSection}>
                    <Text style={styles.termsConditionsLabel}>Payment:</Text>
                    <Text style={styles.termsConditionsText}>100% within , after invoicing.</Text>
                </View>

                <View style={styles.termsConditionsSection}>
                    <Text style={styles.termsConditionsLabel}>Delivery time:</Text>
                    <Text style={styles.termsConditionsText}>The detailed delivery schedule shall be agreed between Customer and Nokia representatives with target delivery being 6-8 weeks after the order has been placed.</Text>
                </View>

                <View style={styles.termsConditionsSection}>
                    <Text style={styles.termsConditionsLabel}>Warranty:</Text>
                    <Text style={styles.termsConditionsText}>Hardware warranty is 12 months.</Text>
                </View>

                <View style={styles.termsConditionsSection}>
                    <Text style={styles.termsConditionsLabel}>Expiry:</Text>
                    <Text style={styles.termsConditionsText}>This Offer is valid until .</Text>
                </View>


                <View style={styles.termsConditionsSection}>
                    <Text style={styles.termsConditionsLabel}>Additional conditions:</Text>
                    <Text style={styles.termsConditionsText}>This Offer is preliminary and may require changes based on more detailed site planning. Terms and conditions for the Nokia DAC solution are agreed in the Nokia Digital Automation Cloud Agreement. Nokia may use subcontractors or partners delivering and deploying the Nokia DAC solution as well as for professional services. </Text>

                    <View style={{ marginTop: 10 }}>
                        <Text style={styles.termsConditionsText}>All pricing is based on standard configuration and may change based on the configuration. and Prices are quoted net w/o taxes and duties.</Text>
                    </View>

                    <Text style={styles.termsConditionsText}>This Offer is subject to and conditional upon Customer and Nokia signing and executing Nokia Digital Automation Cloud agreement as separately shared to Customer.</Text>

                    <View style={{ marginTop: 10 }}>
                        <Text style={styles.termsConditionsText}>In case of any questions regarding our offer, please do not hesitate to contact us.</Text>
                    </View>


                    <View style={{ marginTop: 20 }}>
                        <Text style={styles.termsConditionsText}>You can find the agreed terms and conditions of the <Link style={{ color: "#005AFF" }} src='https://www.dac.nokia.com/system/files/2024-05/DAC%20Marketplace%20Agreement%20-%20signed.pdf'>DAC Marketplace Agreement</Link> and the applicable <Link style={{ color: "#005AFF" }} src='https://www.dac.nokia.com/terms/marketplace/'>Merchant Terms</Link> and/or <Link style={{ color: "#005AFF" }} src='https://www.dac.nokia.com/terms/'>Nokia Terms</Link>.</Text>
                    </View>

                    <View style={{ marginTop: 20 }}>
                        <Text style={styles.termsConditionsLabel}>Terms agreed and accepted by:</Text>
                        <Text style={styles.termsConditionsText}>On behalf of {owner && owner}</Text>
                        <Text style={styles.termsConditionsText}>{accountDetails && accountDetails.name}</Text>
                        <Text style={styles.termsConditionsText}>{createdDate && createdDate}</Text>
                    </View>
                </View>

                <View style={styles.footer} fixed>
                    <View style={styles.footerContent}>
                        <Text render={({ pageNumber, totalPages }) => `${pageNumber} | ${totalPages}`} />
                        <Text style={{ flexDirection: 'row', justifyContent: 'flex-end' }}><Text style={{ color: "#005AFF" }}>Marketplace</Text> | Ω 2024   Nokia</Text>
                    </View>

                </View>
            </Page>)}
        </Document>
    );
};

export default MyDocument;

MyDocument.propTypes = {
    data: PropTypes.array,
    accountDetails: PropTypes.object,
    offerId: PropTypes.string,
    cartResponse: PropTypes.object,
    owner: PropTypes.string,
    orderType: PropTypes.any,
    createdDate: PropTypes.string,
    billingAddress: PropTypes.any,
    shippingAddress: PropTypes.any
    //accountData: PropTypes.any
    // separator:PropTypes.any
}
