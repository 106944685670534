import { useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { callMsGraph } from "graph";
import { loginRequest } from "authConfig";
import { useDispatch } from "react-redux";
import { setLive } from "Store/theme";
import OOT from "Constants/services/oot";

import { setError, setUser, showLoader } from "Store/auth";

export const useSubscriptionAuth = () => {
  const useAuthState = () => {
    const { instance, accounts } = useMsal();
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated();

    const [authState, setAuthState] = useState({
      isAuthenticated: false,
      email: "",
    });
    useEffect(() => {
      dispatch(showLoader(true));

      // let isCancelled = false;
      if (isAuthenticated) {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            const adToken = response;
            const accessToken = response.accessToken;
            return callMsGraph(response.accessToken)
              .then((response) => {
                console.log(response, 'user response')
                dispatch(showLoader(false));
                response["accessToken"] = accessToken;
                response["adToken"] = JSON.stringify(adToken);
                dispatch(setUser(response));
                OOT.init({
                  accessToken: accessToken,
                  email: response.mail,
                  idtoken: response.adToken.idToken,
                  userID: response.id,
                  redX: {
                    dispatch: dispatch,
                  },
                });
                setAuthState(() => ({
                  isAuthenticated,
                  email: response.mail,
                  idtoken: response.adToken.idToken,
                }));
              })
              .catch((error) => {});
          })
          .catch((e) => {
            dispatch(
              setError({
                key: "error",
                value: {
                  errorCode: null,
                  errorMsg: "Session Expired, re-Login to continue",
                },
              })
            );
            dispatch(setLive(false));
            setTimeout((e) => {
              instance.logoutRedirect({
                postLogoutRedirectUri: "/",
              });
            }, 3000);
          });
      } else {
        //alert("Not logged in");
      }
      return () => {
        //   console.log("start");
      };
    }, [instance, accounts, isAuthenticated, dispatch]);
    return authState;
  };

  const useIsAuthorized = (userEmail) => {
    let allowedDomainList = ["nokia.com"];
    if (
      process.env.REACT_APP_ALLOWED_EMAIL_DOMAIN &&
      process.env.REACT_APP_ALLOWED_EMAIL_DOMAIN !== ""
    ) {
      allowedDomainList = process.env.REACT_APP_ALLOWED_EMAIL_DOMAIN.split(",");
    }
    let isAuthorized = allowedDomainList.length > 0 ? false : true;
    allowedDomainList.forEach((domain) => {
      domain = !domain.startsWith("@") ? `@${domain}` : domain;
      if (userEmail.endsWith(domain)) {
        isAuthorized = true;
        return isAuthorized;
      }
    });
    return isAuthorized;
  };

  return {
    useAuthState,
    useIsAuthorized,
  };
};