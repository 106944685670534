//@ts-nocheck
import React,{memo} from "react";
import classes from "./QuantityModifier.module.css";
import {
  setSubscriptionCancellation
} from "Store/contentState/subscriptionSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";

interface IQuantityModifiersProps {
  minValue: number;
  maxValue: number;
  productId: String;
}

const QuantityModifier = (props: IQuantityModifiersProps) => {
  const { minValue, maxValue, productId } = props;
  const selectionOpt = Array.from(
    { length: maxValue - minValue + 1 },
    (_, index) => minValue + index
  );

  const subscriptionSlice = useSelector((state:RootState) => state.subscription);
  
  const dispatch = useDispatch();
  const handleQuantityChange = (e) => {
    dispatch(setSubscriptionCancellation({key:"quantity",data:{[productId]:e.target.value}}));
  }
  return (
    <div className={classes.QuantityModifier}>
      <select onChange={handleQuantityChange} value={subscriptionSlice?.cancellation?.quantity[productId]}>
        {selectionOpt.map((opt, _i) => (
          <option key={`${productId}_qs_${_i}`}>{opt}</option>
        ))}
      </select>
    </div>
  );
};

export default memo(QuantityModifier);
