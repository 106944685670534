import React from 'react'
import {
  Dialog,
} from "@nokia-csf-uxr/ccfk";
import { AlertBar } from "@nokia-csf-uxr/ccfk/Alert";
import {
  DialogContent,
  DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import CircularProgressIndicatorIndeterminate, {
  CircularProgressIndicatorIndeterminateOverlay,
} from "@nokia-csf-uxr/ccfk/CircularProgressIndicatorIndeterminate";


const DIALOG_HEIGHT = "12.5rem";

type IInProgressDialog = {
  isOpen:boolean;
  title:string;
  message:string;
}

const InProgressDialog = ({isOpen,title,message}:IInProgressDialog) => {
  return (
    <>
    {/* Cancellation In Progress */}
    <Dialog
        isOpen={isOpen}
        ariaHideApp={false}
        style={{
          content: {
            width: "30rem",
            height: DIALOG_HEIGHT,
            minHeight: DIALOG_HEIGHT,
          },
        }}
      >
        <AlertBar variant="CONFIRM" />
        <DialogTitle title={title} />
        <DialogContent
          isTopDividerVisible={true}
          isBottomDividerVisible={true}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ lineHeight: "40px" }}>
              {message}
            </div>
            <div>
              <CircularProgressIndicatorIndeterminateOverlay overlaySize={30}>
                <CircularProgressIndicatorIndeterminate strokeWidth={5} />
              </CircularProgressIndicatorIndeterminateOverlay>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Cancellation In Progress */}
    </>
  )
}

export default InProgressDialog