//@ts-nocheck
import React, { useRef, memo, useMemo, useCallback } from "react";
import { DataGrid, Typography } from "@nokia-csf-uxr/ccfk";
import { useSelector } from "react-redux";
import { getDataGridRowStatusColour, getUniqueKeyId } from "./utility/utils";
import { subscriptionGridColums } from "./utility/Contants";
import { RootState } from "Store/mainStore";
import ExcelExport from "Components/Elements/ExcelExport/ExcelExport";
import classes from "./Style/SubscriptionDataGrid.module.css";

const SubscriptionDataGrid = () => {
  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );
  const gridApi = useRef(null);

  const dataGridColumnDefs = useMemo(() => {
    const dgColumns = [...subscriptionGridColums];
    dgColumns.unshift({
      headerName: "Subscription Id",
      field: "productId",
      filterParams: {
        buttons: ["reset"],
      },
      resizable: true,
      pinned: "left",
      cellStyle: (params) => ({
        borderLeft: "3px solid",
        borderLeftColor: getDataGridRowStatusColour(
          params.data?.subscription_status
        ),
      }),
      minWidth: 140,
    });

    // Handle status field
    const contractIdIndex = dgColumns.findIndex(
      (el) => el?.colId === "contract_id"
    );
    dgColumns.splice(contractIdIndex + 1, 0, {
      headerName: "Status",
      field: "subscription_status",
      filterParams: {
        buttons: ["reset"],
      },
      width: 120,
      resizable: true,
      cellRenderer: (params) => {
        const fontColor = getDataGridRowStatusColour(
          params.data?.subscription_status
        );
        const statusStyle =
          fontColor !== ""
            ? {
                color: fontColor,
              }
            : {};

        return (
          <div
            className={`${classes.subscription_status}`}
            style={{ ...statusStyle }}
          >
            {params.data?.subscription_status}
          </div>
        );
      },
    });

    return dgColumns;
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    params.columnApi.autoSizeAllColumns();
  }, []);

  const getActiveSubscription = (subscriptionData) => {
    const activeSubscription = subscriptionData.filter(
      // (row) => row?.subscription_cancelled?.toLowerCase() === "no"
      (row) => row?.subscription_status?.toLowerCase().startsWith("active")
    );
    return activeSubscription;
  };

  const renderDataGrid = () => {
    return subscriptionSlice.subscriptionData.data.map((subscription) =>
      Object.keys(subscription).map((title) => (
        <div key={getUniqueKeyId("small")}>
          <div style={{ display: "flex", padding: "5px 0" }}>
            <Typography style={{ height: '25px', margin: '5px 0',lineHeight: '25px'}}>{title}</Typography>
            {getActiveSubscription(subscription[title]).length > 0 && (
              <ExcelExport
                label="Excel Export"
                fileName={`${subscriptionSlice.filters.selection.partner}_${subscriptionSlice.filters.selection.customer}_${subscriptionSlice.filters.selection.network}_${title}`}
                columnDefs={[...dataGridColumnDefs].map((row) => ({
                  header: row.headerName,
                  field: row.field,
                }))}
                exportData={getActiveSubscription(subscription[title])}
                isConfigurable={true}
              />
            )}
          </div>
          {/* @ts-ignore */}
          <DataGrid
            suppressMovableColumns={true}
            // isInlineRowActions={false}
            domLayout="autoHeight"
            isRowDrag={false}
            key={`subscription-${title}`}
            wrapperProps={{
              style: {
                width: "100%",
              },
            }}
            sideBar
            rowData={getActiveSubscription(subscription[title])}
            defaultColDef={{
              editable: false,
              sortable: true,
              flex: 0,
              minWidth: 100,
              filter: true,
              resizable: true,
            }}
            overlayNoRowsTemplate="No active subscriptions and yearly licenses for the current selection"
            columnDefs={dataGridColumnDefs}
            onGridReady={(params) => {
              gridApi.current = params.api;
              // setRowData(rowData);
            }}
            onFirstDataRendered={(params) => {
              onFirstDataRendered(params);
            }}
          />
        </div>
      ))
    );
  };

  return <div className={classes.dgWrapper}>{renderDataGrid()}</div>;
};
export default memo(SubscriptionDataGrid);
