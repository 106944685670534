/* eslint-disable */
import React, { memo } from "react";
import classes from "./Style/SubscriptionFilter.module.css";
import SellToParty from "./Components/SellToParty";
import EndCustomer from "./Components/EndCustomer";
import Networks from "./Components/Networks";
const SubscriptionFilter = () => {
  return (
    <div className={classes["sub-filter"]}>
      <div className={classes.selection}>
        <SellToParty />
      </div>
      <div className={classes.selection}>
        <EndCustomer />
      </div>
      <div className={classes.selection}>
        <Networks />
      </div>
    </div>
  );
};

export default memo(SubscriptionFilter);
