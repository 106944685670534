import React from "react";
import classes from '../../marketplace/Style/Cart.module.css';
import { Typography } from "@nokia-csf-uxr/ccfk";
import { useSelector, useDispatch } from "react-redux";
import { removeProductFromCart } from "Store/contentState/marketplaceSlice";
import { RootState } from "Store/mainStore";
import Chip, {
    ChipLabel,
} from '@nokia-csf-uxr/ccfk/Chip';
import infoIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/info-small.svg'
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

import CartItemActionButtons from "./CartItemActionButtons";
import CartDiscounts from "./CartDiscounts";
import CartDiscountBreakup from "./CartDiscountBreakup";
import PriceDisplay from "../UIElements/PriceDisplay";

const CartItemRightContainer = (prop) => {
    const isListPriceEmpty = !prop.listItem.list_price
    return (
        <>
               {isListPriceEmpty && <div>
               <div>
                    <Typography style={{ fontSize: '22px', fontWeight: '700', color: '#4F4F4F', textAlign: 'right' }}>{<PriceDisplay price={prop.listItem.product_total_cost}/>} <span style={{ fontSize: '16px', color: '#828282', fontWeight: '400' }}>{prop.listItem.priceamount_currencycode}</span></Typography>
                </div>
                </div>}

                
                {!isListPriceEmpty &&<div>
                <CartDiscounts discount={prop.listItem}/>
                <div>
                    <Typography style={{ fontSize: '22px', fontWeight: '700', color: '#4F4F4F', textAlign: 'right' }}>{prop.listItem.total ? <PriceDisplay price={prop.listItem.total}/> : <PriceDisplay price={prop.listItem.product_total_cost}/>} <span style={{ fontSize: '16px', color: '#828282', fontWeight: '400' }}>{prop.listItem.priceamount_currencycode}</span></Typography>
                </div>
               {prop.listItem.list_price !== "" && <div>
                    <Typography style={{ fontSize: '16px', fontWeight: '400', color: '#4F4F4F', textAlign: 'right' }}>Listed price  <span style={{ fontSize: '16px', color: '#828282', fontWeight: '400', textDecoration: 'line-through' }}>{<PriceDisplay price={prop.listItem.product_total_cost}/>}</span></Typography>
                </div>} 
                {isListPriceEmpty && <div style={{display:'flex', justifyContent:'end', marginTop:'2px'}}>
                    
                    <div style={{display:'flex', justifyContent:'center', gap:'4px'}}>
                    <Tooltip
                        closeOnReferenceHidden={false}
                        placement="top"
                        // fallbackPlacements={['right', 'left']}
                        modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
                        trigger="hover"
                        tooltip={
                            <>
                        <CartDiscountBreakup discountBreakup={prop.listItem}/>
                        </>
                    }
                    >
                       <img src={infoIcon}/>
                    </Tooltip>
                    <Typography style={{ fontSize: '14px', fontWeight: '400', color: '#333333', textAlign: 'right', marginTop:'2px' }}>Discount breakup</Typography>
                    </div>
                </div>}
                </div>}

                <div style={{display:'flex', justifyContent:'flex-end'}}>
                    <CartItemActionButtons actionItem={prop.listItem}/>
                </div>

            
        </>
    )

}

export default CartItemRightContainer;