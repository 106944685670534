//@ts-nocheck
import { Typography } from "@nokia-csf-uxr/ccfk";
import { EmptyState } from "Components/Content/EmptyState";
import firstUse from "Assets/Icons/first_use.svg";
import React from "react";

const WelcomePage = (props) => {
  return (
    <div style={{ margin: "10px" }}>
      <div
        style={{
          borderBottom: "solid 1px #ddd",
          padding: "10px",
        }}
      >
        <Typography
          typography="TITLE_24"
          style={{
            marginBottom: "10px",
            fontSize: "20px",
          }}
        >
          Nokia Digital Automation for Campus Business Hub
        </Typography>
        <Typography
          style={{
            color: "var(--legacy-color-neutral-grey-300)",
            fontSize: "14px",
          }}
        >
          Explore your subscription, account information and manage your
          subscription. Subscriptions and yearly licenses will appear here after
          making the account and then the network selection.
        </Typography>
      </div>
      <div style={{ marginTop: "25px" }}>
        <EmptyState msg="" src={firstUse} />
      </div>
    </div>
  );
};

export default WelcomePage;
