import React from "react";
import { Typography } from "@nokia-csf-uxr/ccfk";
import githubIcon from '../Assets/Icons/mdi_github.svg';
import instaIcon from '../Assets/Icons/ph_instagram-logo.svg';
import youtubeIcon from '../Assets/Icons/mdi_youtube.svg';
import twitterIcon from '../Assets/Icons/mdi_twitter.svg';
import facebookIcon from '../Assets/Icons/ri_facebook-fill.svg';
import linkedinIcon from '../Assets/Icons/ri_linkedin-fill.svg';

const Footer = () => {
    return (
        <div style={{ marginTop: '2rem' }}>
            <div style={{ padding: '1rem', borderTop: "1px solid #0000001F", position: 'static' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', gap:'1rem' }}>
                        <Typography style={{  flex: 'none', color: '#333333', fontSize: '12px' }}>© 2024 Nokia All rights reserved</Typography>
                        <Typography style={{  flex: 'none', color: '#333333', fontSize: '12px' }}>
                            <a href="https://www.nokia.com/privacy/general-notice/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#333333' }}>Privacy</a>
                        </Typography>
                        <Typography style={{  flex: 'none', color: '#333333', fontSize: '12px' }}>
                            <a href="https://www.nokia.com/notices/terms/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#333333' }}>Terms and Conditions</a></Typography>
                        <Typography style={{ flex: 'none', color: '#333333', fontSize: '12px' }}>
                            <a href="https://www.nokia.com/support/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#333333' }}>Support Hub</a></Typography>
                    </div>
                    <div style={{ display: 'flex', gap:'1rem',  alignItems: 'center' }}>
                        {/* <span style={{ marginRight: '15px' }}>
                            <a href="https://www.dac.nokia.com/terms/marketplace/" target="_blank" rel="noopener noreferrer"> <img src={githubIcon} /></a>
                        </span> */}
                        <span>
                            <a href="https://www.instagram.com/nokia/?hl=en" target="_blank" rel="noopener noreferrer"> <img src={instaIcon} /></a>
                        </span>
                        <span>
                            <a href="https://www.youtube.com/user/nokia" target="_blank" rel="noopener noreferrer"> <img src={youtubeIcon} /></a>
                        </span>
                        <span>
                            <a href="https://twitter.com/nokia" target="_blank" rel="noopener noreferrer"> <img src={twitterIcon} /></a>
                        </span>
                        <span >
                            <a href="https://www.facebook.com/nokia/" target="_blank" rel="noopener noreferrer"> <img src={facebookIcon} /></a>
                        </span>
                        <span>
                            <a href="https://www.linkedin.com/company/nokia" target="_blank" rel="noopener noreferrer"> <img src={linkedinIcon} /></a>
                        </span>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer;