import React, { memo } from "react";
import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import MarketplaceBody from "./MarketplaceBody";
import { Outlet, useLocation } from 'react-router-dom';


const crumItems = [{ title: 'Marketplace', path: '/marketplace' }]

const Marketplace = () => {
  const authSelector = useSelector((state: RootState) => state.authSlice);

  const location = useLocation();

  let crumbs = ["Marketplace"];
  let items = [{ title: 'Marketplace', path: '/marketplace' }];

  if (location.pathname.includes("/marketplace/cart")) {
    crumbs = ["Marketplace / Cart"];
    items = [{ title: 'Marketplace', path: '/marketplace' },
    { title: 'Cart', path: '' }]
  } else if (location.pathname.includes("/marketplace/product")) {
    crumbs = ["Marketplace / Product"];
    items = [
      { title: 'Marketplace', path: '/marketplace' },
      { title: 'Product', path: '' }
    ];
  }

  return (
    <>
      <LoadingOverlay
        active={authSelector.system.isLoading}
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >
        <App>
          <Header />
          <Body
            crumbs={crumbs}
            bodyContent={<Outlet />}
            items={items}
          />
        </App>
        {/* <AppFooter /> */}
      </LoadingOverlay>
    </>
  )
}

export default memo(Marketplace);