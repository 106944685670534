import { App, Typography } from "@nokia-csf-uxr/ccfk";
import SignIn, {
  SignInBackground,
  SignInBody,
  SignInContent,
  SignInFooter,
  SignInHeader,
  SignInLogo,
} from "@nokia-csf-uxr/ccfk/SignIn";

import { SignInButton } from "Components/SignInButton";
import { systemStyle } from "Constants/StyleLayer";
import pkg from "Utils/package.json";
import React from "react";
export const Login = () => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>

      <App>
        <>
          <SignIn>
            <SignInContent>
              <SignInHeader>
                <SignInLogo ria-label="Nokia logo" />
              </SignInHeader>
              <SignInBody>
                <div style={systemStyle.genrateGridRows(2)}>
                  <div id="title" className="mb">
                    <Typography typography={"TITLE_24"}>
                    Digital Automation for Campus | DAC Business Hub
                    </Typography>
                    <Typography typography={"MICRO"}>
                      Version {pkg.version}
                    </Typography>
                  </div>
                  <SignInButton />
                </div>
              </SignInBody>
              <SignInFooter>
                <Typography typography="CAPTION">
                  <span style={{ marginRight: "0.125rem" }}>
                    © {new Date().getFullYear()} Nokia
                  </span>
                </Typography>
              </SignInFooter>
            </SignInContent>
            <SignInBackground />
          </SignIn>
        </>
      </App>
    </>
  );
};
