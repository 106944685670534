import React from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import { title } from "process";
import AlertMessage from "@nokia-csf-uxr/ccfk/Alert";


const DIALOG_HEIGHT = "40%";
const DIALOG_WIDTH = "40%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};


type IConfirmDialogCloseProp = {
    isOpen: boolean;
    title: string;
    confirmMessage: any
    onCancel: () => void;
    onConfirm: () => void;
};

const ConfirmDialog = ({isOpen, title, confirmMessage, onCancel, onConfirm}: IConfirmDialogCloseProp) => {
   return (
     <>
     <Dialog
                isOpen={true}
                ariaHideApp={false}
                style={{ content: CONFIRM_ALERT_STYLE }}
            >
                <DialogTitle
                    title={title}
                    buttonContent={<CloseCloseIcon />}
                    buttonProps={{
                        onClick: onCancel,
                    }}
                />
                <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true}>
                
                <AlertMessage
            iconProps={{ style: { display: 'none' }}}
            message={confirmMessage}
            variant="CONFIRM"
          />
                
                

                </DialogContent>
                <DialogFooter>
                    {/* <Button onClick={onClose}>Close</Button> */}
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button variant="destructive" onClick={onConfirm}>Delete</Button>
                </DialogFooter>
            </Dialog>
     </>
   )

}

export default ConfirmDialog;