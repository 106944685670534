import { createSlice } from "@reduxjs/toolkit";
import { setProp } from "Store/Actions";


const initialData = {
  products: [],
  productTypes: [],
  productSubtypes: [],
  partnerList: [],
  customerList: [],
  networkList: [],
  countryList: [],
  accountDropdown: [],
  isPartnerLoading: false,
  isCountryLoading: false,
  isAccountLoading: false,
  isCustomerLoading: false,
  isProductTypeLoading: false,
  isProductSubTypeLoading: false,
  isGetProducts: false,
  orderResponseProps: {
    isOrderLoading: false,
    isOrderCreated: false,
    isOrderErrorOccur: false,
  },
  isTermsConditionsChecked: false,
  isSelectedProductLoading: false,
  isProductsLoading: false,
  showFilter: false,
  filters: {
    selection: {
      productType: "",
      productSubType: "",
      partner: "",
      customer: "",
      network: "",
      shippingCountry: "",
      billingCountry: "",
      showOnlyNokiaProducts: false,
      shipTo:"",
      searchText:""
    },
  },
  cart: [],
  selectedDeviceID: "",
  selectedDeviceSpecification: [],
  accountDetails: [],
  quotationResponse: {},
  quotations: [],
  quotationRefernce: '',
  userAccessInfo: [],
  uploadPODetails: {
    poReferenceNumber: "",
    uploadedFile: [],
    billingAddress: {},
    shippingAddress: {}
  },
  activeOrders: [],
  nokiaTermsConditions: "",
  showMerchantProducts: false,
  showOnlyNokiaProducts: false,
  baseQuery:"",
  productQueryFilter: "",
  shippingCountryText: "",
  isCartChanged: false,
  isCartLoading: false,
  showShipTo:false,
  cartResponse: {
    currency: "",
    cartSummary: {},
    cartOfferId: "",
  },
  orderType: {
    // generatePOOrder: false,
    // uploadPOOrder: false,
    orderNow:false,
    generateQuote: false
  },
  savedCartInfo: {},
  isNokiaUser: false

};

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState: initialData,
  reducers: {

    addProductToCart(state, action) {
      const { productToAdd, quantity, fromSpecification } = action.payload;

      // Find the index of the product if it exists in the cart
      const existingProductIndex = state.cart.findIndex(
        (item) => item.id === productToAdd.id
      );

      if (existingProductIndex !== -1) {
        if (fromSpecification) {
          // Set the quantity from the specification page
          state.cart[existingProductIndex].product_quantity = quantity;
        } else {
          // Increment by one if added from catalogue
          state.cart[existingProductIndex].product_quantity += 1;
        }
        // Recalculate the total cost based on the new quantity
        const newTotalCost = state.cart[existingProductIndex].product_quantity * parseInt(state.cart[existingProductIndex].price_amount);
        state.cart[existingProductIndex].product_total_cost = newTotalCost;
      } else {
        // If the product does not exist, add it to the cart with the specified quantity
        state.cart.push({
          ...productToAdd,
          product_quantity: quantity || 1 // Default to 1 if no quantity specified
        });
      }
    },
    removeProductFromCart(state, action) {
      const productIdToRemove = action.payload;
      state.cart = state.cart.filter(item => item.id !== productIdToRemove);
      // Check if the cart is empty and reset cartResponse if true
      if (state.cart.length === 0) {
        state.cartResponse = initialData.cartResponse
      }
    },
    clearCart(state,) {
      state.cart = []
    },
    setProducts(state, action) {
      const data = action.payload
      state.products = data
    },
    setProductTypes(state, action) {
      const data = action.payload.productTypes;
      state.productTypes = data;
    },
    setProductSubTypes(state, action) {
      const data = action.payload.productSubTypes;
      state.productSubtypes = data;
    },
    setCountryList(state, action) {
      const data = action.payload;
      state.countryList = data;
    },
    // setProductSubTypes(state, action){
    //   const data  = action.payload;
    //   state.productSubtypes = data;
    // },
    setPartnerList(state, action) {
      state.partnerList = action.payload.partnerList
    },
    setCustomerList(state, action) {
      const { customerList } = action.payload;
      state.customerList = customerList;
    },
    setNetworkList(state, action) {
      const { networkList } = action.payload;
      state.networkList = networkList;
    },
    setAccountDropdown(state, action) {
      const { accountDropdown } = action.payload;
      state.accountDropdown = accountDropdown;
    },
    setLoader(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setFilters(state, action) {
      const { filterName, value } = action.payload;
      state.filters.selection[filterName] = value;
    },
    setShowFilter(state, action) {
      state.showFilter = action.payload
    },
    setSelectedDeviceID(state, action) {
      state.selectedDeviceID = action.payload
    },
    setSelectedDeviceSpecification(state, action) {
      state.selectedDeviceSpecification = action.payload.selectedProduct
    },
    setQuotationRefernce(state, action) {
      state.quotationRefernce = action.payload
    },
    setQuotations(state, action) {
      state.quotations = action.payload
    },
    updateCartProduct: (state, action) => {
      const { itemId, updatedSelectedData } = action.payload;
      const updatedItems = state.cart.map(item =>
        item.id === itemId ? { ...item, ...updatedSelectedData } : item
      );

      state.cart = updatedItems;
    },
    setUserAccessInfo(state, action) {
      const userData = action.payload;
      state.userAccessInfo = userData
    },
    setAccountDetails(state, action) {
      const accountData = action.payload;
      state.accountDetails = accountData
    },
    setQuotationResponse(state, action) {
      const quotationResponse = action.payload;
      state.quotationResponse = quotationResponse;
    },
    setOrderResponseProps(state, action) {
      state.orderResponseProps = action.payload;
    },
    updatePOReferenceNumber: (state, action) => {
      state.uploadPODetails.poReferenceNumber = action.payload;
    },
    updateUploadedFile: (state, action) => {
      state.uploadPODetails.uploadedFile = action.payload;
    },
    updateBillingAddress: (state, action) => {
      state.uploadPODetails.billingAddress = action.payload;
    },
    updateShippingAddress: (state, action) => {
      state.uploadPODetails.shippingAddress = action.payload;
    },
    clearUploadPO: (state) => {
      state.uploadPODetails = initialData.uploadPODetails;
      state.orderType = initialData.orderType;
      state.quotationRefernce = initialData.quotationRefernce;
    },
    setActiveOrders(state, action) {
      state.activeOrders = action.payload
    },
    setProductQueryFilter(state, action) {
      state.productQueryFilter = action.payload
    },
    updateEntireCart(state, action) {
      state.cart = action.payload
    },
    resetOrderResponseProps(state) {
      state.orderResponseProps = initialData.orderResponseProps
      state.orderType = initialData.orderType
    },
    resetMarketplaceFilters(state) {
      // const currentPartner = state.filters.selection.partner;
      // const currentBillingCountry = state.filters.selection.billingCountry
      // state.filters = {
      //   ...initialData.filters,
      //   selection: {
      //     ...initialData.filters.selection,
      //     partner: currentPartner,
      //     billingCountry:currentBillingCountry
      //   },
      // };
      state.filters = initialData.filters
      state.accountDetails = initialData.accountDetails
      state.products = initialData.products
      state.productSubtypes = initialData.productSubtypes
      state.productTypes = initialData.productTypes
      state.showMerchantProducts = initialData.showMerchantProducts
      state.showOnlyNokiaProducts = initialData.showOnlyNokiaProducts
      state.productQueryFilter = initialData.productQueryFilter
      state.cartResponse = initialData.cartResponse
      state.savedCartInfo = initialData.savedCartInfo
      state.selectedDeviceID = initialData.selectedDeviceID
      state.selectedDeviceSpecification = initialData.selectedDeviceSpecification
      state.productQueryFilter = initialData.productQueryFilter
      state.showShipTo = initialData.showShipTo

    },
    resetCartResponse(state) {
      state.cartResponse = initialData.cartResponse
    }

  },
  extraReducers(builder) {
    builder.addCase(setProp, (state, action: {
      payload: any;
      type: string;
      slice: string;
    }) => {
      if (action.payload.slice === marketplaceSlice.name) {
        state[action.payload.key] = action.payload.value;
      }
    });
  }


})

export default marketplaceSlice.reducer;             //setProductSubTypes
export const { addProductToCart, removeProductFromCart, clearCart, setProductTypes,
  setPartnerList, setLoader, setProducts, setFilters, setUserAccessInfo, updateCartProduct, setShowFilter, setSelectedDeviceID, setSelectedDeviceSpecification, setQuotationRefernce,
  setQuotations, setAccountDetails, setQuotationResponse, setProductQueryFilter, updatePOReferenceNumber,
  updateUploadedFile,
  updateBillingAddress,
  updateShippingAddress, clearUploadPO, setCustomerList, setOrderResponseProps, setNetworkList, setAccountDropdown, setActiveOrders,
  updateEntireCart, setCountryList, setProductSubTypes, resetOrderResponseProps, resetMarketplaceFilters, resetCartResponse } = marketplaceSlice.actions;