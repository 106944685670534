import { createSlice } from "@reduxjs/toolkit";
import { private_wireless_users, radio_frequency_band } from "Components/Content/configurationArea/PTDAC/Connectivity/system";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from 'Store/Actions/ts';
import setObj from "lodash/set";

const generalView = {
   
}
const generalOptions = {
    connectivity_solution: "",
    radio_frequency_band: "",
    prepareCart:{catalog:[],suggest:[]},
    solution_type: "",
    bandwidth: "",
    private_wireless_users:"",
};
const initialData = {
    system: {
        radio_frequency_band: radio_frequency_band,
        private_wireless_users: private_wireless_users,
        product_catalog: { byIds: [], allIds: [] },
    },
    view: {
        PTDAC: {
            ...generalView
        }
    },
    options: {
        PTDAC: {
            ...generalOptions
        }
    }
};
const connectivitySlice = createSlice({
    initialState: {
        data: initialData,
        status: STATUS.IDLE,
    },
    name: 'connectivity',
    reducers: {
        /** All Recommendation contains Product ITEM*/

    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === connectivitySlice.name) {
                setObj(state.data, action.payload.key, action.payload.value);
            }
        })
    }
});

export default connectivitySlice.reducer;
export const {  } = connectivitySlice.actions;