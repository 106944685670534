import React, { useState } from "react";
import classes from '../marketplace/Style/MarketplaceFilter.module.css';
import TextInput, { TextInputButton, TextInputLabelContent } from '@nokia-csf-uxr/ccfk/TextInput';
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import Button, {
    ButtonText,
    ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import _ from "lodash";
import UserDialogModal from "./user-management/UserDialogModal";

const AdminFilter = () => {
    const [query, setQuery] = useState('')
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    //   const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const dispatch = useDispatch();


    const debouncedSearch = _.debounce(async (searchQuery) => {
        setQuery(searchQuery)
        // try {
        //   const response = await OOT.getSearchedResults(searchQuery, oneStarId);
        //   console.log(response.data, 'search results')
        //   // dispatch(setSearchResults(data));
        // } catch (error) {
        //   console.error('Error fetching search results:', error);
        // }
    }, 300);

    const handleSearchDebounced = (searchQuery) => {
        debouncedSearch(searchQuery);
    };
    return (
        <>
            {showAddUserDialog && (
                <UserDialogModal onClose={() => setShowAddUserDialog(false)} mode="add" selectedUserData=""/>
            )}
            <div  style={{marginBottom: '1rem', display: 'flex', alignItems:'center', justifyContent:'space-between', padding:'13px', backgroundColor:'#fff', borderRadius:'10px'}}>
                {/* <div className={classes.searchBox}>
                    <TextInput id="search_product"
                        style={{ width: "100%" }}
                        value={query}
                        variant={"outlined"} placeholder="search"
                        onChange={(e) => handleSearchDebounced(e.target.value)}

                    />
                </div> */}
                <div className={classes.filterSection}>

                </div>
                <div className={classes.filterSection}>
                    {/* <SearchBox data={marketplaceSelector.productTypes}/> */}
                </div>
                <div className={classes.filterTabs}>
                    {/* <Button variant="secondary"><ButtonText>Export</ButtonText></Button> */}
                    <Button variant="call-to-action" onClick={() => setShowAddUserDialog(true)}><ButtonText>Add User</ButtonText></Button>
                </div>
            </div>

        </>

    )
}

export default AdminFilter;