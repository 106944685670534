import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { extensionOfferTools } from "Components/Misc/CaseDetails_System";
const toolSelectionSlice = createSlice({
  name: "toolSelection",
  initialState: {
    data: {
      extensionTool: extensionOfferTools.NDAC,
    },
    status: STATUS.IDLE,
  },
  reducers: {
    setExtensionTool(state, action) {
      state.data.extensionTool = action.payload;
    },
  },
});

export default toolSelectionSlice.reducer;
export const { setExtensionTool } = toolSelectionSlice.actions;
