import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
// import { useAuthState } from "Components/Logics/ensoCommonFunctions";
import React, { memo,useEffect } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector,useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import {
  setAppToolbarTitle
} from "Store/contentState/subscriptionSlice";
import AccountBody from "./AccountBody";

const Account = () => {
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setAppToolbarTitle("Account Info"))
  },[dispatch]);

  // useAuthState();
  return (
    <>
      <LoadingOverlay
        active={authSelector.system.isLoading}
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >
        <App>
          <Header />
          <Body
            crumbs={[
              "Subscription / Account",
            ]}
            bodyContent={<AccountBody />}
          />
        </App>
      </LoadingOverlay>
    </>
  );
};
export default memo(Account);
