import { CircularProgressIndicatorIndeterminate } from '@nokia-csf-uxr/ccfk';
import React, { useState } from 'react';

export interface withSpinnerHOCType {
  setLoading: (boolean) => {};
  isLoading: boolean;
}

const withSpinner = (SelectBox) => {
  return function HOC(props){
    const [isLoading,setLoading] = useState();
    const setLoadingState = isSelectBoxLoading => {
      setLoading(isSelectBoxLoading);
    }
    return (
      <div style={{position:'relative'}}>
        <SelectBox {...props} setLoading={setLoadingState} isLoading={isLoading} />
        {isLoading && (
        <div className="" style={{
          width: '25px',
          height: '25px',
          position: 'absolute',
          top: '50%',
          right: '50%'
        }}>
          <CircularProgressIndicatorIndeterminate
            strokeWidth={4}
            role="alert"
          />
        </div>
      )}
      </div>
    )
  }  
}

export default withSpinner;