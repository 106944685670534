import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import authReducer from "Store/auth";
import cartBoqSlice from "Store/contentState/cartBoqSlice";
import caseDetailsReducer from "Store/contentState/caseDetailsSlice";
import applicationsSlice from "Store/contentState/configurationArea/PTDAC/applicationsSlice";
import connectivitySlice from "Store/contentState/configurationArea/PTDAC/connectivitySlice";
import edgeSlice from "Store/contentState/configurationArea/PTDAC/edgeSlice";
import businessSlice from "Store/contentState/configurationArea/businessSlice";
import devicesSlice from "Store/contentState/configurationArea/devicesSlice";
import reviewSlice from "Store/contentState/configurationArea/reviewSlice";
import servicesSlice from "Store/contentState/configurationArea/servicesSlice";
import warrantySlice from "Store/contentState/configurationArea/warrantySlice";
import findCaseSliceReducer from "Store/contentState/findCaseSlice";
import stateMachineSlice from "Store/contentState/stateMachine";
import toolSelectionReducer from "Store/contentState/toolSelection";
import themeReducer from "Store/theme";
import { myLoggingMiddleware } from "Store/middleware";
// import { myLoggingMiddleware } from "./middleware";
import subscriptionReducer from "./contentState/subscriptionSlice";
import marketplaceReducer from './contentState/marketplaceSlice';
import userManagementReducer from './contentState/userManagementSlice';
import paginationSlice from "./contentState/paginationSlice";

export const store = configureStore({
  reducer: {
    //Home//
    authSlice: authReducer,
    findCase: findCaseSliceReducer,
    caseDetails: caseDetailsReducer,
    toolSelection: toolSelectionReducer,
    //Configuration Area//
    business: businessSlice,
    devices: devicesSlice,
    review: reviewSlice,
    services: servicesSlice,
    warranty: warrantySlice,
    //Configuration Area - PTDAC//
    applications: applicationsSlice,
    connectivity: connectivitySlice,
    edge: edgeSlice,
    //CART//
    cartBoq: cartBoqSlice,
    //THEME - GLOBAL//
    theme: themeReducer,
    //State-Machine - Global//
    stateMachine: stateMachineSlice,
    subscription: subscriptionReducer,
    marketplace: marketplaceReducer,
    userManagement: userManagementReducer,
    pagination: paginationSlice

    //RTK-Query -apis
    // [CUIAPIS.reducerPath]: CUIAPIS.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      myLoggingMiddleware
    ),
});
//@ts-ignore
window.store = store;
setupListeners(store.dispatch);
export type AppDispatch = typeof store.dispatch; // Here we export the store's dispatch type
export type RootState = ReturnType<typeof store.getState>; // H
