import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@nokia-csf-uxr/ccfk";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import { RootState } from "Store/mainStore";
import OOT from "Constants/services/oot";
import ConfirmDialog from "./Custom/ConfirmDialog";
import InProgressDialog from "./Custom/InProgressDialog";
import { setStatusMessage } from "Store/contentState/subscriptionSlice";
import { useSubscriptionFilter } from "./hooks/useSubscriptionFilter";
import SubscriptionRenewal from "./Components/SubscriptionRenewal";
import SubscriptionExpiry from "./Components/SubscriptionExpiry";
import SubscriptionSupport from "./Components/SubscriptionSupport";
import classes from "./Style/Subscription.module.css";

const SubscriptionUpdation = () => {
  const dispatch = useDispatch();
  const { fetchSubscriptionNetworkData } = useSubscriptionFilter();
  const authSlice = useSelector((state: RootState) => state.authSlice);
  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );

  const [renewalPeriod, setRenewalPeriod] = useState(
    subscriptionSlice?.subInfo?.renewal_duration ?? ""
  );
  const [invoicingFrequency, setInvoicingFrequency] = useState(
    subscriptionSlice?.subInfo?.invoicing_frequency ?? ""
  );
  const [delegateError, setIsDelegateError] = useState(null);
  const [delegateList, setDelegateList] = useState([]);
  const [delegateValue, setDelegateValue] = useState("");
  // const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [showUpdateConfirmDialog, setShowUpdateConfirmDialog] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  // useEffect(() => {
  //   setRenewalPeriod(subscriptionSlice?.subInfo?.renewal_duration);
  // }, [subscriptionSlice?.subInfo?.renewal_duration]);

  useEffect(() => {
    setDelegateList(subscriptionSlice?.subInfo?.delegates);
  }, [subscriptionSlice?.subInfo?.delegates]);

  // useEffect(() => {
  //   if (
  //     subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() !==
  //     "active"
  //   ) {
  //     setShowUpdateButton(false);
  //   } else {
  //     if (delegateList === subscriptionSlice?.subInfo?.delegates) {
  //       setShowUpdateButton(false);
  //     } else {
  //       setShowUpdateButton(true);
  //     }
  //     setShowUpdateButton(true);
  //   }
  // }, [
  //   delegateList,
  //   subscriptionSlice?.subInfo?.delegates,
  //   subscriptionSlice?.subInfo?.subscriptionInfo?.status,
  // ]);

  // const handletRenewalPeriod = (e) => {
  //   let a = e?.target?.value;
  //   a = a ? parseInt(a) : null;
  //   setRenewalPeriod(a);
  // };

  const handletRenewalPeriod = (rP) => setRenewalPeriod(String(rP));
  const handleInvoicingFrequencyChange = (iF) =>
    setInvoicingFrequency(String(iF));

  const handleDelegateChange = (e) => {
    const delegateEmail = e.target.value;
    setIsDelegateError(null);
    if (delegateEmail.trim().length > 0) {
      const emailRegwx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!delegateEmail.toLowerCase().match(emailRegwx)) {
        setIsDelegateError("Invalid email address");
      }
      if (delegateList.includes(delegateEmail.toLowerCase())) {
        setIsDelegateError("Email address already exists");
      }
    }
    setDelegateValue(delegateEmail.toLowerCase());
  };

  /*
  // --- Should be removed - disabled by default for first release
  const handleAddDelegate = () => {
    setIsDelegateError(() => null);
    if (delegateList && delegateList.includes(delegateValue)) {
      setIsDelegateError(() => "Email address already exists");
    } else if (delegateValue.trim().length > 0) {
      setDelegateList((prevDelegates) => [
        ...prevDelegates,
        delegateValue.toLowerCase(),
      ]);
      setDelegateValue(() => "");
      setShowUpdateButton(true);
    }
  };
  const handleRemoveDelegate = (delegate) => {
    setDelegateList((prevDelegates) =>
      prevDelegates.filter((d) => d !== delegate)
    );
  };
  */

  const handleSubscriptionUpdate = async () => {
    setShowUpdateConfirmDialog(false);
    const networkId = subscriptionSlice?.filters?.selection?.network;
    const networkDelegates = delegateList.join(",");
    const mail = authSlice?.login?.mail;

    if (networkId && mail) {
      setIsUpdateInProgress(true);
      const updateFormData = {
        "network-id": networkId,
        "loggedin-user": mail,
        // "product-id": productId, //--- Not required ?
      };
      if (networkDelegates.trim().length > 0) {
        updateFormData["network-delegate"] = networkDelegates;
      }
      if (renewalPeriod) {
        updateFormData["renewal-duration"] = renewalPeriod;
      }
      if (invoicingFrequency) {
        updateFormData["invoicing-frequency"] = invoicingFrequency;
      }

      const response = await OOT.updateSubscription(updateFormData);
      setIsUpdateInProgress(false);
      // console.log(response)
      if (response?.stacktrace?.trim().length === 0 && response?.status) {
        // @ts-ignore
        const partnerId = subscriptionSlice?.filters?.selection?.partner;
        fetchSubscriptionNetworkData("reload", partnerId);
        dispatch(
          setStatusMessage({
            type: "success",
            message: ["Network has been updated successfully !"],
          })
        );
      } else {
        const messages = [];
        if (response.errorMessage?.trim().length > 0) {
          messages.push(response.errorMessage?.trim());
        }
        if (response.message?.trim().length > 0) {
          messages.push(response.message?.trim());
        }
        if (response?.stacktrace?.trim().length > 0) {
          messages.push(response?.stacktrace?.trim());
        }
        dispatch(
          setStatusMessage({
            type: "error",
            message: messages,
          })
        );
      }
    }
  };

  const isUpdateButtonDisabled = (): boolean => {
    // If network status !== 'active'
    if (
      subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() !==
      "active"
    ) {
      return true;
    }

    let disableUpdateButton = false;
    // Check renewalPeriod (current state) and subscriptionSlice?.subInfo?.renewal_duration (slice state)
    // are same or not
    disableUpdateButton =(!renewalPeriod) || (String(renewalPeriod) === String(subscriptionSlice?.subInfo?.renewal_duration));

    // Check invoicingFrequency (current state) and subscriptionSlice?.subInfo?.invoicing_frequency (slice state)
    // are same or not
    disableUpdateButton =
      disableUpdateButton && ((!invoicingFrequency) || (String(invoicingFrequency) ===
        String(subscriptionSlice?.subInfo?.invoicing_frequency)));

    // ---- Should enabled on second release
    /*if (delegateList === subscriptionSlice?.subInfo?.delegates) {
      //       setShowUpdateButton(false);
      //     } else {
      //       setShowUpdateButton(true);
      //     }
      //     setShowUpdateButton(true);
      //   }
      */
    return disableUpdateButton;
  };

  return (
    <>
      {/* Confirmation */}
      <ConfirmDialog
        isOpen={showUpdateConfirmDialog}
        title="Confirm"
        alertMessage={`Selected network subscription details will be updated.`}
        confirmMessage={"Do you want to continue? "}
        onCancel={() => setShowUpdateConfirmDialog(false)}
        onConfirm={handleSubscriptionUpdate}
      />

      {/* Cancellation In Progress */}
      <InProgressDialog
        isOpen={isUpdateInProgress}
        title="Please wait ..."
        message="Subscription updation request is in progress ..."
      />
      <div className={`${classes.renewal} ${classes.asBlocks}`}>
        <SubscriptionRenewal
          renewalPeriod={renewalPeriod}
          isRenewalDisabled={
            subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() !==
            "active"
          }
          onRenewalChange={handletRenewalPeriod}
          invoicingFrequency={invoicingFrequency}
          invoicingFrequencyList={
            subscriptionSlice.invoicingSubscriptionFrequency
          }
          isInvoicingFrequencyDisabled={
            subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() !==
            "active"
          }
          onInvoicingFrequencyChange={handleInvoicingFrequencyChange}
        />
      </div>
      <div className={`${classes.expiry} ${classes.asBlocks}`}>
        <SubscriptionExpiry
          contractList={subscriptionSlice?.subscriptionData?.contractList}
        />
      </div>
      <div className={`${classes.support} ${classes.asBlocks}`}>
        <SubscriptionSupport
          delegateError={delegateError}
          delegateValue={delegateValue}
          delegateList={delegateList}
          onDelegateChange={handleDelegateChange}
        />
      </div>
      <div className={classes.buttonRow}>
        <Button
          variant="call-to-action"
          aria-label="ok"
          // onClick={handleSubscriptionUpdate}
          onClick={() => setShowUpdateConfirmDialog(true)}
          disabled={isUpdateButtonDisabled()}
        >
          <ButtonText>Update</ButtonText>
        </Button>
      </div>
    </>
  );
};

export default SubscriptionUpdation;
