//@ts-nocheck
/* eslint-disable react/prop-types */
import React, { useRef, memo, useCallback } from "react";
import { Button, DataGrid, Typography } from "@nokia-csf-uxr/ccfk";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import { useSelector, useDispatch } from "react-redux";
// import dgClasses from "../SubscriptionDataGrid.module.css";
import { getDataGridRowStatusColour, getUniqueKeyId } from "../utility/utils";
import { RootState } from "Store/mainStore";
import {
  setSubscriptionCancellation,
  setCancelType,
  resetSubscriptionCancellation,
  setStatusMessage,
} from "Store/contentState/subscriptionSlice";
import { subscriptionGridColums } from "../utility/Contants";
import QuantityModifier from "./QuantityModifier";
import ExcelExport from "Components/Elements/ExcelExport/ExcelExport";
import classes from "../Style/Subscription.module.css";

const SubscriptionCancellationGrid = () => {
  let isCheckboxEnabled = false;
  const dispatch = useDispatch();
  const networkStatus = useSelector(
    (state: RootState) => state.subscription?.subInfo?.subscriptionInfo?.status
  );
  /* const selectedGridRow = useSelector(
    (state: RootState) => state.subscription?.cancellation?.selectedGridRow
  ); */
  const subscriptionData = useSelector(
    (state: RootState) => state.subscription?.subscriptionData?.data
  );
  const subscriptionFilterSelection = useSelector(
    (state: RootState) => state.subscription?.filters?.selection
  );
  const gridApi = useRef(null);

  const isSubscriptionCancellable = useCallback((gridData) => {
    let isCancellable = false;
    if (gridData.length > 0) {
      isCancellable = gridData.some(
        (girRow) => girRow.show_cancel_button?.toString() === "true"
      );
    }
    return isCancellable;
  }, []);

  useCallback((params) => {
    gridApi.current = params.api;
  }, []);

  const getDataGridColumnDefs = useCallback((isCheckboxEnabled) => {
    const dgColumns = [...subscriptionGridColums];
    dgColumns.unshift({
      headerName: "Subscription Id",
      field: "productId",
      filterParams: {
        buttons: ["reset"],
      },
      resizable: true,
      headerCheckboxSelection: isCheckboxEnabled,
      checkboxSelection: isCheckboxEnabled,
      showDisabledCheckboxes: isCheckboxEnabled,
      pinned: "left",
      cellStyle: (params) => ({
        borderLeft: "3px solid",
        borderLeftColor: getDataGridRowStatusColour(
          params.data?.subscription_status
        ),
      }),
      minWidth: 140,
    });
    const actQtyIndex = dgColumns.findIndex((el) => el?.id === "RenwQty");
    dgColumns.splice(actQtyIndex + 1, 0, {
      colId: "cancel_qty",
      headerName: "Cancel Quantity",
      hide: !isCheckboxEnabled,
      filterParams: {
        buttons: ["reset"],
      },
      resizable: true,
      cellRenderer: (params) => (
        <div style={{ width: "80px" }}>
          {params.data?.show_cancel_button?.toString() === "true" ? (
            <QuantityModifier
              productId={`${params.data.contract_id}_${params.data.productId}`}
              minValue={1}
              maxValue={Math.round(params.data.renewal_qty)}
            />
          ) : (
            <div> - </div>
          )}
        </div>
      ),
    });

    // Handle status field
    const contractIdIndex = dgColumns.findIndex(
      (el) => el?.colId === "contract_id"
    );
    dgColumns.splice(contractIdIndex + 1, 0, {
      headerName: "Status",
      field: "subscription_status",
      filterParams: {
        buttons: ["reset"],
      },
      width: 120,
      resizable: true,
      cellRenderer: (params) => {
        const fontColor = getDataGridRowStatusColour(
          params.data?.subscription_status
        );
        const statusStyle =
          fontColor !== ""
            ? {
                color: fontColor,
              }
            : {};

        return (
          <div
            className={`${classes.subscription_status}`}
            style={{ ...statusStyle }}
          >
            {params.data?.subscription_status}
          </div>
        );
      },
    });

    return dgColumns;
  }, []);

  /* const onFirstDataRendered = (params) => {
    params.columnApi.autoSizeAllColumns();
    const selectedProductsIds = [];
    selectedGridRow.forEach((node) => {
      selectedProductsIds.push(`${node.contract_id}_${node.productId}`);
    });
    params.api.forEachNode((node) => {
      if (
        node?.data?.productId &&
        selectedProductsIds.includes(
          `${node.data.contract_id}_${node.data.productId}`
        )
      ) {
        node.setSelected(true);
      }
    });
  }; */

  const getActiveSubscription = (subscriptionData) => {
    const activeSubscription = subscriptionData.filter((row) =>
      row?.subscription_status?.toLowerCase().startsWith("active")
    );
    return activeSubscription;
  };

  const renderDataGrid = () => {
    const subscriptionCancelledRow = classes["subscription-cancelled-row"];
    // return subscriptionSlice.subscriptionData.data.map((subscription) =>
    return subscriptionData.map((subscription) =>
      Object.keys(subscription).map((title) => (
        <div key={getUniqueKeyId("small")}>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
            className={classes["grid-title"]}
          >
            {
              (isCheckboxEnabled = isSubscriptionCancellable(
                subscription[title]
              ))
            }
            <Typography style={{ height: '25px', margin: '5px 0',lineHeight: '25px'}}>{title}</Typography>
            {isCheckboxEnabled && (
              <>
                <Button
                  variant="destructive"
                  disabled={false}
                  onClick={(event) => {
                    if (networkStatus.toLowerCase() !== "active") {
                      dispatch(
                        setStatusMessage({
                          type: "warning",
                          message: [
                            "Something went wrong !",
                            "Unable to cancel subscription from cancelled network",
                          ],
                        })
                      );
                      return;
                    }
                    event.persist();
                    const selectedRows = gridApi.current.getSelectedRows();
                    const cancelType =
                      selectedRows.length === 0 ? undefined : "individual";
                    dispatch(setCancelType({ cancelType }));
                    /* if (selectedRows.length > 0) {
                      dispatch(
                        setCancelType({
                          cancelType: "individual",
                        })
                      );
                      
                       dispatch(
                        setSubscriptionCancellation({
                          key: "selectedGridRow",
                          data: selectedRows,
                        })
                      );
                    } */

                    // Reset Question data
                    dispatch(
                      resetSubscriptionCancellation({
                        key: "questionnaire",
                        type: "object",
                      })
                    );
                    // Reset Question data
                    dispatch(
                      resetSubscriptionCancellation({
                        key: "reasonOther",
                        type: "object",
                      })
                    );
                  }}
                >
                  <ButtonText>Cancel Subscription</ButtonText>
                </Button>
              </>
            )}

            {getActiveSubscription(subscription[title]).length > 0 && (
              <ExcelExport
                label="Excel Export"
                fileName={`${subscriptionFilterSelection.partner}_${subscriptionFilterSelection.customer}_${subscriptionFilterSelection.network}_${title}`}
                columnDefs={[...getDataGridColumnDefs(isCheckboxEnabled)].map((row) => ({
                  header: row.headerName,
                  field: row.field,
                })).filter(row=>row.field)}
                exportData={getActiveSubscription(subscription[title])}
                isConfigurable={true}
              />
            )}
          </div>
          <DataGrid
            suppressMovableColumns={true}
            suppressRowClickSelection={true}
            domLayout="autoHeight"
            isRowDrag={false}
            key={`subscription-cancel-${title}`}
            wrapperProps={{
              style: {
                width: "100%",
              },
            }}
            sideBar
            rowData={getActiveSubscription(subscription[title])}
            defaultColDef={{
              editable: false,
              sortable: true,
              flex: 0,
              minWidth: 100,
              filter: true,
              resizable: true,
            }}
            overlayNoRowsTemplate="No active subscriptions and yearly licenses for the current selection"
            // columnDefs={dataGridColumnDefs}
            columnDefs={getDataGridColumnDefs(isCheckboxEnabled)}
            onSelectionChanged={(params) => {
              gridApi.current = params.api;
              const selectedRows = params.api.getSelectedRows();
              if (selectedRows.length === 0) {
                dispatch(setCancelType({ cancelType: undefined }));
              }
              dispatch(
                setSubscriptionCancellation({
                  key: "selectedGridRow",
                  data: selectedRows,
                })
              );
            }}
            onFirstDataRendered={(params) => {
              params.columnApi.autoSizeAllColumns();
              // onFirstDataRendered(params);
            }}
            isRowSelectable={(params) =>
              params.data?.show_cancel_button?.toString() === "true"
            }
            rowClassRules={{
              [subscriptionCancelledRow]: (params) =>
                params.data?.show_cancel_button?.toString() === "false",
            }}
            onGridReady={(params) => {
              gridApi.current = params.api;
            }}
          />
        </div>
      ))
    );
  };

  return <div className={classes.dgWrapper}>{renderDataGrid()}</div>;
};
export default memo(SubscriptionCancellationGrid);
