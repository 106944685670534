import { Tooltip, Typography } from '@nokia-csf-uxr/ccfk'
import React from 'react';

type contractListType={
  contract_id:string,
  next_renewal:string
}

interface subscriptionExpiryType{
  contractList:[contractListType]
}
const SubscriptionExpiry = ({
  contractList
}:subscriptionExpiryType) => {
  return (
    <ul>
          <li
            style={{
              display: "flex",
              margin: "5px 0",
            }}
          >
            <Typography style={{ flexBasis: "70%" }}>
              Entitlement Group ID
            </Typography>
            <Typography style={{ flexBasis: "50%" }}>End Date</Typography>
          </li>
          {contractList?.map((cL) => (
            <li
              key={cL.contract_id}
              style={{ display: "flex", margin: "5px 0" }}
            >
              <Typography style={{ cursor: "pointer", flexBasis: "70%" }}>
                <Tooltip tooltip={cL.contract_id}>{cL.contract_id}</Tooltip>
              </Typography>
              <Typography style={{ flexBasis: "50%" }}>
                {cL.next_renewal}
              </Typography>
            </li>
          ))}
        </ul>
  )
}

export default SubscriptionExpiry