import classes from '../../marketplace/Style/Cart.module.css'; import React from "react";
import Spinner from '@nokia-csf-uxr/ccfk/Spinner';
import { useSelector, useDispatch } from "react-redux";
import { removeProductFromCart } from "Store/contentState/marketplaceSlice";
import { RootState } from "Store/mainStore";
import { updateCartProduct } from "Store/contentState/marketplaceSlice";
import { setProp } from 'Store/Actions';
import useCartActions from '../hooks/useCartActions';

const CartItemQuantityModifier = (prop) => {
    const {handleAction} = useCartActions();
    const handleQuantityChange = (event, item) => {
        handleAction('updateQuantity', { item: item, newQuantity: event.value });
    };

    return (
        <>
            <div style={{ marginTop: '.35rem', marginBottom: '.35rem' }}>
                <Spinner
                    inputProps={{
                        inputProps: {
                            'aria-label': `Sp  inner ${prop.item.product_quantity}`,
                        },
                        id: "spinner-1"
                    }}
                    variant="outlined"

                    min={1}
                    max={100}
                    step={1}
                    incrementButtonProps={{ 'aria-label': `Value ${prop.item.product_quantity}. Increment` }}
                    decrementButtonProps={{ 'aria-label': `Value $item.item.product_quantity}. Decrement` }}
                    readOnly={false}
                    onChange={(e) => handleQuantityChange(e, prop.item)}
                    value={prop.item.product_quantity}
                    style={{ height: '27px', width: '82px' }}
                />
            </div>
        </>
    )
}

export default CartItemQuantityModifier;