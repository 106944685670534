import React from "react";
import { Button } from "@nokia-csf-uxr/ccfk";
import AppMenu, {
  AppMenuButton,
  AppMenuItem,
  AppMenuItemIcon,
  AppMenuItemTitle,
  AppMenuList,
  AppMenuPopup,
} from "@nokia-csf-uxr/ccfk/AppMenu";
import HelpCircleIcon from "@nokia-csf-uxr/ccfk-assets/latest/HelpCircleIcon";

const PortalHelpLinks = () => {
  const [showHelpMenuPopup, setShowHelpMenuPopup] = React.useState(false);
  return (
    <>
      <AppMenu
        id="TopHelpBtn"
        onEscFocusOut={() => {
          setShowHelpMenuPopup(false);
        }}
        onClickOutside={() => {
          setShowHelpMenuPopup(false);
        }}
      >
        <Button
          onClick={() => {
            setShowHelpMenuPopup(!showHelpMenuPopup);
          }}
        >
          <HelpCircleIcon
            size={16}
            style={{ marginRight: "5px" }}
          />
          Help
        </Button>

        <AppMenuPopup
          open={showHelpMenuPopup}
        >
          <AppMenuList>
            <AppMenuItem data-index={"001"} key={"001"}>
              <AppMenuItemTitle
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`${process.env.REACT_APP_SUPPORT_HUB_URI}`,"__blank");
                }}
              >
                <AppMenuItemTitle>Support Hub (nokia.com)</AppMenuItemTitle>
              </AppMenuItemTitle>
            </AppMenuItem>
            <AppMenuItem
              data-index={"002"}
              key={"002"}
              onClick={(e) => {
                e.preventDefault();
                window.open(`${process.env.REACT_APP_USER_GUIDE_URI}`,"__blank");
              }}
            >
              <AppMenuItemTitle>DAC Business Hub User Guide</AppMenuItemTitle>
            </AppMenuItem>
            <AppMenuItem
              data-index={"003"}
              key={"003"}
              onClick={(e) => {
                e.preventDefault();                
                window.open(`${process.env.REACT_APP_REQUEST_SUPPORT_URI}`,"__blank");
              }}
            >
              <AppMenuItemTitle>Request Support</AppMenuItemTitle>
            </AppMenuItem>
          </AppMenuList>
        </AppMenuPopup>
      </AppMenu>
    </>
  );
};

export default React.memo(PortalHelpLinks);
