import React from "react";
import {
  Button,
  Dialog,
  Typography,
} from "@nokia-csf-uxr/ccfk";
import {AlertMessage, AlertIcon } from "@nokia-csf-uxr/ccfk/Alert";
import InfoCircleFillIcon from '@nokia-csf-uxr/ccfk-assets/latest/InfoCircleFillIcon';

import {
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";

const DIALOG_HEIGHT = "16rem";
const DIALOG_WIDTH = "30rem";

const CONFIRM_ALERT_STYLE = {
  top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
  height: DIALOG_HEIGHT,
  minHeight: DIALOG_HEIGHT,
  left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  width: DIALOG_WIDTH,
};

type IconfirmDialogProp = {
  isOpen: boolean;
  title: string;
  dialogStyle?:object,
  alertMessage: string;
  confirmMessage?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmDialog = ({
  isOpen,
  title,
  dialogStyle,
  alertMessage,
  confirmMessage,
  onCancel,
  onConfirm,
}: IconfirmDialogProp) => {
  return(
    <>
      {/* Confirmation */}
      <Dialog
        isOpen={isOpen}
        ariaHideApp={false}
        style={{
          content: dialogStyle ? dialogStyle: CONFIRM_ALERT_STYLE
        }}
        onRequestClose={(event) => { console.log(event); }}
      >
        <DialogTitle title={title} icon={<AlertIcon  variant="CONFIRM"><InfoCircleFillIcon /></AlertIcon>} />
        <DialogContent
          isTopDividerVisible={false}
          isBottomDividerVisible={false}
          style={{ padding: '0.25rem 1.3125rem 0 1.5rem', overflow: 'hidden' }}
        >
          <AlertMessage
            iconProps={{ style: { display: 'none' }}}
            message={ alertMessage } 
            variant="CONFIRM"
          />
          {confirmMessage && (            
            <Typography
              style={{
                padding: "0 10px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "right",
              }}
            >
              {confirmMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button variant="call-to-action" aria-label="ok" onClick={onConfirm}>
          Continue
          </Button>
        </DialogFooter>
      </Dialog>
      {/* Confirmation */}
    </>
  );
};

export default ConfirmDialog;
