export const connectivity_solution = [
    { id: "nokia", value: "Nokia" },
    { id: "other", value: "3rd Party" }
];

export const radio_frequency_band = [
    { id: "B8", value: "B8" },
    { id: "B4", value: "B4" },
    { id: "B30", value: "B30" },
    { id: "B53", value: "B53" },
    { id: "B41", value: "B41" },
    { id: "B48", value: "B48" },
    { id: "n48", value: "n48" }
];

export const private_wireless_users = [
    { id: "0-200", value: "0-200" },
    { id: "201-2000", value: "201-2000" },
    { id: "2001-20000", value: "2001-20000" },
];

export const bandwidth = [
    { id: 5, value: "5" },
    { id: 10, value: "10" },
    { id: 15, value: "15" },
    { id: 20, value: "20" },
];

export const solutionTypesOptions = [
    { id: "MICRO", value: "Micro" },
    { id: "MINI_MICRO", value: "Mini Macro" },
    { id: "MICRO_RRH", value: "Micro RRH" },
    { id: "OTHERS", value: "Others" },
]