import React from "react";
import cartClearImg from '../../Assets/Icons/cart-empty-image.svg'
import { Label, Typography } from "@nokia-csf-uxr/ccfk";
import { Button } from "@nokia-csf-uxr/ccfk";
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import { useNavigate } from "react-router-dom";

const styles = `
  .empty-cart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    background-color: #fff;
    color: #333;
    border-radius: 10px
  }
  .empty-cart {
    padding: 2rem;
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  .empty-cart  {
    margin-bottom: 0.7rem;
    font-size: 1.5rem;
  }
  
  
  .empty-cart-image {
    height: 200px;
    margin-bottom: 1rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
`;

const CartEmptyPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <style>{styles}</style>
      <div className="empty-cart-container">
        <div className="empty-cart">
          <div className="empty-cart-image">
           <img src={cartClearImg} height={200} alt="cart-empty"/>
          </div>
          <div style={{display:'block', justifyContent:'center', alignItems:'center'}}>
          <h5 style={{fontSize:'16px', fontWeight:'bold', marginBlockStart:'0', marginBlockEnd:'0', marginBottom: '.5rem'}}>There are no items added in the cart</h5>
          <Typography style={{ marginBottom: '.5rem'}}>Browse our marketplace and fill it with the innovative solutions that fuel your vision.</Typography>
          <Button  variant="call-to-action" fullWidth={false} onClick={() => navigate('/marketplace')}><ButtonText>Shop now</ButtonText></Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartEmptyPage;