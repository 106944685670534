import React from 'react'
import SelectBox from '../Custom/SelectBox'
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { useSubscriptionFilter } from "../hooks/useSubscriptionFilter";
import { setSubscriptionCancelMode } from "Store/contentState/subscriptionSlice";

const Networks = () => {
  const dispatch = useDispatch();
  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );
  const { setNetworkState } =  useSubscriptionFilter();

  const handleNetworkSelection = (__network) => {
    setNetworkState(__network);
    dispatch(setSubscriptionCancelMode("AcountOverview"));
  };

  return (
    <SelectBox
          labelText="Networks"
          placeholder="Select Network"
          maxWidth={true}
          caseInsentiveMatching={true}
          selectedItem={subscriptionSlice?.filters?.selection?.network}
          data={subscriptionSlice.networkList}
          onUpdateSelection={handleNetworkSelection}
        /> 
  )
}

export default Networks