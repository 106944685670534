import React from "react";
import classes from "../../Style/Cart.module.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import { Label } from '@nokia-csf-uxr/ccfk'


const CartAccountDetails = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
     // Extracting values with safe fallbacks
     // Safe extraction with fallbacks
     const accountDetails = marketplaceSelector.accountDetails?.[0] ?? {};
     const savedCartInfo = marketplaceSelector.savedCartInfo ?? {};
     const cartSummary = savedCartInfo['cart_summary'] ?? {};
 
     // Extract data with fallbacks
     const accountName = accountDetails.name 
         ?? cartSummary.account 
         ?? '';
 
     const endCustomerName = accountDetails.end_customer_name 
         ?? '';
 
     const shippingCountry = marketplaceSelector.shippingCountryText 
         ?? cartSummary.country 
         ?? '';

        
    return (
        <>
            <div>
                <div className={classes.cartSummarySubHeading}>Account details</div>
            </div>
            <div style={{ padding: '.5rem' }}>
                <Label className={classes.cartSummaryAccountLabel} >Account name: <span>{accountName}</span></Label>
                <Label className={classes.cartSummaryAccountLabel} >End customer: <span>{endCustomerName}</span></Label>
                <Label className={classes.cartSummaryAccountLabel} >Shipping country: <span>{shippingCountry}</span></Label>
                {/* <Label>{}</Label> */}

              
            </div>
        </>
    )
}

export default CartAccountDetails;