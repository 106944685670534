import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from 'Store/Actions/ts';
import setObj from "lodash/set";
const generalOptions = {
    sim_card: 1,
};
const initialData = {
    view: {

    },
    options: {
        PTDAC: { ...generalOptions }
    }
};
const devicesSlice = createSlice({
    initialState: {
        data: initialData,
        status: STATUS.IDLE,
    },
    name: 'devices',
    reducers: {},
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === devicesSlice.name) {
                setObj(state.data, action.payload.key, action.payload.value);
            }
        })
    }
});

export default devicesSlice.reducer;
export const { } = devicesSlice.actions;