import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { Hyperlink, Typography } from "@nokia-csf-uxr/ccfk";
import { setSubscriptionCancelMode } from "Store/contentState/subscriptionSlice";
import CancellationHistory from "./CancellationHistory";
import classes from "../Style/Subscription.module.css";

const AccountOverview = ({
  onCancellationEnable,
}: {
  onCancellationEnable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showHistoryDialog, setShowHistoryDialog] = React.useState(false);
  const dispatch = useDispatch();
  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );
  const enableSubscriptionCancelMode = (type) => {
    onCancellationEnable(true);
    dispatch(setSubscriptionCancelMode("subscription"));
  };

  // Network status
  const nwStatus =
    subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() ===
    "active"
      ? "Active"
      : "Cancelled";

  const cancellationLink = (
    <Hyperlink
      style={{ padding: "0 3px" }}
      onClick={(e) => {
        e.preventDefault();
        enableSubscriptionCancelMode("individual");
      }}
      href="/#"
    >
      cancellation
    </Hyperlink>
  );

  return (
    <>
      {showHistoryDialog && (
        <CancellationHistory onClose={() => setShowHistoryDialog(false)} />
      )}

      {/* ------------ AccountOverview -----------*/}
      <div className={classes.cancellationWrapper}>
        <div
          style={{
            padding: "10px",
            width: "50%",
          }}
        >
          <TextInputLabelContent>Account overview</TextInputLabelContent>
          <div className={classes.AccountOverview}>
            <Typography id="para-ao-slice" className={classes.title}>
              Slice ID:
            </Typography>
            <Typography className={classes.value}>
              {subscriptionSlice?.subInfo?.subscriptionInfo?.sliceId}
            </Typography>
            <Typography id="para-ao-sla" className={classes.title}>
              SLA:
            </Typography>
            <Typography className={classes.value}>
              {subscriptionSlice?.subInfo?.subscriptionInfo?.sla}
            </Typography>
            <Typography id="para-ao-nws" className={classes.title}>
              Network Status:
            </Typography>
            <Typography
              className={`${classes.value} ${classes[nwStatus.toLowerCase()]}`}
            >
              {subscriptionSlice?.subInfo?.subscriptionInfo?.status}
            </Typography>
            <Typography id="para-ao-cs" className={classes.title}>
              Customer specifications:
            </Typography>
            <Typography className={classes.value}>
              {
                subscriptionSlice?.subInfo?.subscriptionInfo
                  ?.customerSpecification
              }
            </Typography>
            {/* <Typography id="para-ao-vcpu" className={classes.title}>
              vCPU capacity:
            </Typography>
            <Typography className={classes.value}> ??? </Typography> */}
            <Typography id="para-ao-history" className={classes.title}>
              Subscription history:
            </Typography>
            <Hyperlink
              onClick={(e) => {
                e.preventDefault();
                setShowHistoryDialog(true);
              }}
              href="/#"
            >
              View
            </Hyperlink>
          </div>
        </div>
        <div
          style={{
            padding: "10px",
            width: "50%",
          }}
        >
          <div style={{ marginBottom: "30px" }}>
            {subscriptionSlice?.subInfo?.subscriptionInfo
              ?.autoSubscriptionRenewalEnabled === false ? (
              <>
                <TextInputLabelContent>
                  Subscription renewal info
                </TextInputLabelContent>
                <Typography id="para-auto-renewal">
                  To renew the subscription, please reach out to your sales
                  representative. The renewal order has to be made through our
                  ordering tool by your sales representative. Once the
                  subscription expires the network will be disconnected, please
                  prepare the renewal at least 90 days in advance, as the
                  renewal is going through the approval process. As soon as the
                  auto-renewal process is rolled out for you, the cancellation
                  can be done from here and you will be notified of auto-renewal
                  in advance.
                </Typography>
              </>
            ) : (
              <>
                <TextInputLabelContent>Auto renewal info</TextInputLabelContent>
                <Typography id="para-auto-renewal">
                  The Subscriptions will automatically renew unless Nokia is
                  notified in writing at least thirty (30) days prior the expiry
                  date of the intention not to renew. The Renewal Subscriptions
                  will continue for the number of months specified in the Offer.
                  The Renewal Subscription term may be modified by customer
                  during the then-current Subscription in the DAC Customer
                  Portal (30) days before the next Renewal Subscription starts.
                </Typography>
              </>
            )}
          </div>
          {subscriptionSlice?.subInfo?.subscriptionInfo
            ?.autoSubscriptionRenewalEnabled !== false && (
            <div>
              <TextInputLabelContent>Cancellation info</TextInputLabelContent>
              <Typography id="para-cancellation-info">
                Subscription
                {nwStatus.toLowerCase() === "active"
                  ? cancellationLink
                  : " cancellation "}
                is possible until at least thirty (30) days prior the expiry
                date
              </Typography>
            </div>
          )}
        </div>
      </div>
      {/* ------------ AccountOverview -----------*/}
    </>
  );
};

export default AccountOverview;
