import React, { useEffect } from "react";
import SelectBox from "../Custom/SelectBox";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import withSpinner, { withSpinnerHOCType } from "../HOC/withSpinner";
import { useSubscriptionFilter } from "../hooks/useSubscriptionFilter";

const EndCustomer = (props: withSpinnerHOCType) => {
  const { setLoading, isLoading } = props;
  const subscriptionSlice = useSelector(
    (state: RootState) => state?.subscription
  );

  const isCustomerLoading = useSelector(
    (state: RootState) => state?.subscription?.isCustomerLoading
  );

  const { setCustomerState, setNetworkState } = useSubscriptionFilter();

  const handleCustomerSelection = (__customer) => {
    // const __customer = e.target.value;
    setCustomerState(__customer);
    setNetworkState("");
  };

  useEffect(() => {
    setLoading(isCustomerLoading);
  }, [isCustomerLoading, setLoading]);

  return (
    <SelectBox
      labelText="(Indirect) End customer"
      placeholder="Select (Indirect) End customer"
      maxWidth={true}
      caseInsentiveMatching={true}
      selectedItem={subscriptionSlice?.filters?.selection?.customer}
      data={subscriptionSlice.customerList}
      onUpdateSelection={handleCustomerSelection}
      isLoading={isLoading}
    />
  );
};

export default withSpinner(EndCustomer);
