import { createSlice } from "@reduxjs/toolkit";
// import type { PayloadAction } from "@reduxjs/toolkit";

type TSubInfo = {
  delegates: string[];
  subscriptionInfo: {
    sliceId: string;
    sla: string;
    status: string;
    customerSpecification: string;    
  };
  renewal_duration: string;
  invoicing_frequency: string;
  status: string;
};

type TContractList = {
  contract_id: string;
  nid: string;
  nw_name: string;
  start_date: string;
  next_renewal: string;
  contract_status: string;
};
type SubscriptionDataType = {
  data: string[];
  // networkId: string;
  contractIid: string;
  expiry: string;
  contract_status: string;
  contractList?: TContractList[];
};
type TSubscriptionFilter = {
  selection: {
    partner: string;
    customer: string;
    network: string;
  };
};

type TSubscriptionCancellation = {
  mode: string; //null|"AcountOverview"|"subscription"
  quantity: {};
  selectedGridRow: string[];
  questionnaire: {};
  reasonOther: {};
};

type TPartnerList = {
  id: string;
  value: string;
};
type TCustomerList = {
  id: string;
  value: string;
  network_list: string[];
};
type TNetworkList = {
  id: string;
  value: string;
  slice_id: string | null;
  sla: string | null;
  network_status: string;
  network_delegate: string;
  renewal_duration: string;
  customer_network_specification:string;
  invoicing_frequency:string;
  cancel_whole_network:boolean;
  constract_list: string[];
  subscriptions: {};
};

interface SubscriptionState {
  AppToolbarTitle: string;
  isLoading: boolean;
  isNetworkCancellable:boolean;
  statusMessage: {
    type: "" | "error" | "info" | "warning" | "success";
    message: string[];
  };
  cancelType: string | null;
  questionnaire: string[];
  invoicingSubscriptionFrequency:[],
  accountDropdown: string[];
  isPartnerLoading:boolean;
  partnerList: TPartnerList[];
  isCustomerLoading:boolean;
  customerList: TCustomerList[];
  networkList: TNetworkList[];
  // networkId: string;
  /* subscription:{
    gridData:string[];
  } */
  subInfo: TSubInfo;
  subscriptionData: SubscriptionDataType;
  // subscriptionCancelMode: boolean;
  filters: TSubscriptionFilter;
  cancellation: TSubscriptionCancellation;
}

const getInitialState = () => {
  if(localStorage.getItem("dac-portal-state")){
    const savedState = JSON.parse(localStorage.getItem("dac-portal-state"));
    localStorage.removeItem("dac-portal-state");
    return savedState.subscription;
  }
  return {
    AppToolbarTitle: "Subscriptions and yearly licenses",
    isLoading: false,
    isNetworkCancellable:false,
    statusMessage: {
      type: "",
      message: [],
    },
    cancelType: null,
    questionnaire: [],
    invoicingSubscriptionFrequency:[],
    accountDropdown: [],
    partnerList: [],
    isPartnerLoading:false,
    customerList: [],
    isCustomerLoading:false,
    networkList: [],
    /* subscription:{
      gridData:[]
    }, */
    // networkId: "",
    subInfo: {},
    subscriptionData: {},
    // subscriptionCancelMode: false,
    filters: {
      selection: {
        partner: "",
        customer: "",
        network: "",
      },
    },
    cancellation: {
      mode: null,
      quantity: {},
      selectedGridRow: [],
      questionnaire: {},
      reasonOther: {},
    },
  } as SubscriptionState;
}

const initialState = getInitialState();



const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    resetSate(state) {
      return initialState;
    },
    setSubscriptionFilterSelection(state, action) {
      state.filters.selection = {
        ...state.filters.selection,
        ...action.payload,
      };

      // ------Need to explore more-----
      /* console.log("setSubscriptionProp",action.payload);
      if(typeof action.payload === "object" && !Array.isArray(action.payload)){
        console.log("Object.keys(action.payload)",Object.keys(action.payload))
        const validProps = [];
        console.log("-----",);

        Object.keys(action.payload).forEach(prop => {
          if(current(state).hasOwnProperty(prop)){
            validProps.push({[prop]:action.payload[prop]})
          }            
        })

        state = {...state,...validProps}
        console.log("validProps",validProps)
      }
      else{
        console.error(`"action.payload is expected an object" Error in setSubscriptionProp`);
      } */
    },
    setSubscriptionState(state, action) {
      const { key, data } = action.payload;
      state[key] = data;
    },
    setAppToolbarTitle(state, action) {
      state.AppToolbarTitle = action.payload;
    },
    setLoader(state, action) {
      const { key,value } = action.payload;
      state[key] = value;
    },
    setStatusMessage(state, action) {
      const { type, message } = action.payload;
      state.statusMessage = { type, message };
    },
    setQuestionnaire(state, action) {
      const { questionnaire } = action.payload;
      state.questionnaire = questionnaire;
    },
    setInvoicingSubscriptionFrequency(state, action) {
      const { invoicingSubscriptionFrequency } = action.payload;
      state.invoicingSubscriptionFrequency = invoicingSubscriptionFrequency;
    },
    
    setAccountDropdown(state, action) {
      const { accountDropdown } = action.payload;
      state.accountDropdown = accountDropdown;
    },
    setPartnerList(state, action) {
      const { partnerList } = action.payload;
      state.partnerList = partnerList;
    },
    setCustomerList(state, action) {
      const { customerList } = action.payload;
      state.customerList = customerList;
    },
    setNetworkList(state, action) {
      const { networkList } = action.payload;
      state.networkList = networkList;
    },
    /* setNetworkId(state, action) {
      const { networkId } = action.payload;
      state.networkId = networkId;
    }, */

    setSubInfo(state, action) {
      const { subInfo } = action.payload;
      state.subInfo = subInfo;
    },
    setSubscriptionData(state, action) {
      const { subscriptionData } = action.payload;
      state.subscriptionData = subscriptionData;
    },

    setCancelType(state, action) {
      const { cancelType } = action.payload;
      state.cancelType = cancelType;
    },
    setSubscriptionCancelMode(state, action) {
      state.cancellation.mode = action.payload;
    },
    setSubscriptionCancellation(state, action) {
      const { key, data } = action.payload;
      if (key === "selectedGridRow") {
        state.cancellation[key] = data;
      } else {
        state.cancellation[key] = { ...state.cancellation[key], ...data };
      }
    },
    resetSubscriptionCancellation(state, action) {
      const { key, type } = action.payload;
      if (key === "all") {
        state.cancellation = {
          mode: "",
          quantity: {},
          selectedGridRow: [],
          questionnaire: {},
          reasonOther: {},
        };
      } else {
        if (type === "array") {
        }
        if (type === "object") {
          state.cancellation[key] = {};
        }
      }
    },
  },
});

export const {
  resetSate,
  setSubscriptionState,
  setSubscriptionFilterSelection,
  setAppToolbarTitle,
  setLoader,
  setStatusMessage,
  setCancelType,
  setQuestionnaire,
  setInvoicingSubscriptionFrequency,
  setAccountDropdown,
  setPartnerList,
  setCustomerList,
  setNetworkList,
  // setNetworkId,
  setSubInfo,
  setSubscriptionData,
  setSubscriptionCancelMode,
  setSubscriptionCancellation,
  resetSubscriptionCancellation,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
