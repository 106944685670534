import React, { memo } from "react";
import classes from "./Style/SubscriptionInfo.module.css";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import TextInputLabelContent from "@nokia-csf-uxr/ccfk/TextInput/TextInputLabelContent";
import { Typography } from "@nokia-csf-uxr/ccfk";

const SubscriptionInfo = () => {
  const subscriptionInfo = useSelector(
    (state: RootState) => state.subscription?.subInfo?.subscriptionInfo
  );
  let nwStatus = subscriptionInfo?.status.trim().length > 0 && subscriptionInfo?.status.toLowerCase() === 'active' ? 'Active':'Cancelled';
  return (
    <div className={classes.subsctiptionInfo}>
      <div className={classes.box}>
        <TextInputLabelContent>Slice Id</TextInputLabelContent>
        <Typography className={classes.higlight}>
          {subscriptionInfo?.sliceId}
        </Typography>
      </div>
      <div className={classes.box}>
        <TextInputLabelContent>SLA</TextInputLabelContent>
        <Typography className={classes.higlight}>
        {subscriptionInfo?.sla}
        </Typography>
      </div>
      <div className={classes.box}>
        <TextInputLabelContent>Network Status</TextInputLabelContent>
        <Typography className={`${classes.higlight} ${classes[nwStatus.toLowerCase()] }`}>
        {subscriptionInfo?.status}
        </Typography>
      </div>
      <div className={classes.box}>
        <TextInputLabelContent>Customer specifications</TextInputLabelContent>
        <Typography className={classes.higlight}>
        {subscriptionInfo?.customerSpecification}
        </Typography>
      </div>
    </div>
  );
};
export default memo(SubscriptionInfo);
