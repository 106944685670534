import React from "react";
import TextInput, {
  TextInputLabelContent,
} from "@nokia-csf-uxr/ccfk/TextInput";
import classes from "../Style/Subscription.module.css";
import { Typography } from "@nokia-csf-uxr/ccfk";
// import AddCircleIcon from "@nokia-csf-uxr/ccfk-assets/latest/AddCircleIcon";
// import DeleteIcon from "@nokia-csf-uxr/ccfk-assets/latest/DeleteIcon";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";

interface subscriptionSupportType{
  delegateError:string,
  delegateValue:string,
  delegateList:string[],
  onDelegateChange:(e)=>void,
}

const SubscriptionSupport = ({
  delegateError,
  delegateValue,
  delegateList,
  onDelegateChange,
}:subscriptionSupportType) => {
  const subscriptionNetworkSelected = useSelector(
    (state: RootState) => state.subscription?.filters?.selection?.network
  );

  return (
    <>
      <TextInputLabelContent>Support Contact </TextInputLabelContent>
      <div className={classes.supportFormBox}>
        <TextInput
          placeholder="Delegate email"
          disabled={
            true
            // --- Should be removed - disabled by default for first release
            /* !subscriptionSlice.filters?.selection?.network ||
              subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() !==
                "active" */
          }
          errorMessage={delegateError ? delegateError : undefined}
          onChange={(e) => {
            onDelegateChange(e);
          }}
          value={delegateValue}
          title="Functionality has been disabled temporarily"
        />
        {/*
            // --- Should be enabled after first release
            subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() ===
              "active" && (
              <span
                style={{ cursor: "pointer", marginLeft: "5px" }}
                onClick={handleAddDelegate}
                title="Add Network Delegate"
              >
                <AddCircleIcon color={"var(--g-color-global-primary-base)"} />
              </span>
            )
              */}
      </div>

      <TextInputLabelContent>Network Delegate</TextInputLabelContent>
      <ul className={classes.DelegateListBox}>
        {delegateList &&
          delegateList.map((delgate) => {
            return (
              <li key={subscriptionNetworkSelected + "_" + delgate}>
                <Typography>{delgate}</Typography>
                {/*
                    // ---- Should be enabled in future
                    subscriptionSlice?.subInfo?.subscriptionInfo?.status.toLowerCase() ===
                      "active" && (
                      <span
                        className={classes.closeIcon}
                        onClick={() => handleRemoveDelegate(delgate)}
                      >
                        <DeleteIcon
                          color="red"
                          size={20}
                          title="Remove Network Delegate"
                        />
                      </span>
                    )
                      */}
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default SubscriptionSupport;
