import React, { useRef, useState, useEffect } from "react";
import FilterField, { FilterFieldIcon } from "@nokia-csf-uxr/ccfk/FilterField";
import { useDispatch, useSelector  } from "react-redux";
import { setFilters } from "Store/contentState/marketplaceSlice";
import { RootState } from "Store/mainStore";
import { setCurrentPage } from "Store/contentState/paginationSlice";


const SearchBox = () => {
    const dispatch = useDispatch();
    const searchTextFromStore = useSelector((state : RootState) => state.marketplace.filters.selection.searchText); // Fetch the search text from Redux
    const [searchValue, setSearchValue] = useState(searchTextFromStore || ''); // Initialize with the Redux value
    const inputRef = useRef(null);

    useEffect(() => {
        // If the searchTextFromStore changes (e.g. on component remount), sync the local state
        if (searchTextFromStore !== searchValue) {
            setSearchValue(searchTextFromStore);
        }
    }, [searchTextFromStore]);

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            dispatch(setCurrentPage(1));
            dispatch(setFilters({ filterName: 'searchText', value: searchValue }));
        }
    };
    
    const handleChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
    
        if (value === '') {
            dispatch(setFilters({ filterName: 'searchText', value: '' }));
        }
    };
    
    const handleClear = () => {
        setSearchValue('');
        inputRef.current && inputRef.current.focus();
        dispatch(setFilters({ filterName: 'searchText', value: '' }));
    };

    return (
        <div>
            <FilterField
                value={searchValue}
                onChange={handleChange}
                variant="outlined"
                textInputProps={{
                    placeholder: 'Search an item',
                    showClearButton: true,
                    clearButtonProps: {
                        onClick: handleClear,
                    },
                    inputProps: {
                        ref: inputRef,
                        'aria-label': 'Search',
                        onKeyDown: handleSearch,
                    },
                }}
                renderIcon={<FilterFieldIcon title="click enter to search"/>}
            />
        </div>
    );
};



export default SearchBox;

