import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from 'Store/Actions/ts';
import setObj from "lodash/set";
const generalOptions = {
    georedundancy: "",
    power: "",
    connector_type: "",
    connector_switch: "",
    switch_variant: "",
    mxie_server:"SMS"
}
const initialData = {
    view: {},
    options: {
        PTDAC: {
            ...generalOptions
        }
    }
};
const edgeSlice = createSlice({
    initialState: {
        data: initialData,
        status: STATUS.IDLE,
    },
    name: 'edge',
    reducers: {},
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === edgeSlice.name) {
                setObj(state.data, action.payload.key, action.payload.value);
            }
        })
    }
});

export default edgeSlice.reducer;
export const { } = edgeSlice.actions;