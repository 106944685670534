import { App, Typography } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import { useAuthState } from "Components/Logics/ensoCommonFunctions";
import React, { memo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";

import { RootState } from "Store/mainStore";

const Unauthorized = () => {
  const authSelector = useSelector((state: RootState) => state.authSlice);
  /* const themeSelector = useSelector((state: RootState) => state.theme); */
  // const businessSelector = useSelector((state: RootState) => state.business);
  useAuthState();
  return (
    <>
      <LoadingOverlay
        active={authSelector.system.isLoading}
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >
        <App>
          <Header />
          <div className="access-denied-wrapper">
            <h1 className="bg">403</h1>
            <div className="access-denied-content">
              <Typography
                typography="TITLE_24"
                style={{ padding: "10px 0", textAlign: "center" }}
              >
                Access to this feature has been temporarily restricted.
              </Typography>
              <Typography>
                We are implementing updates to ensure a better experience. We
                will be back soon!
              </Typography>
            </div>
          </div>
        </App>
        {/* <AppFooter /> */}
      </LoadingOverlay>
    </>
  );
};
export default Unauthorized;
